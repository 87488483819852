<template>
  <table class="table table-vcenter">
    <thead v-if="showHeader">
      <slot name="header"></slot>
    </thead>
    <tbody>
      <slot></slot>
    </tbody>
    <tfoot v-if="showFooter">
      <slot name="footer"></slot>
    </tfoot>
  </table>
</template>

<script>
export default {
  name: 'Table',
  computed: {
    showFooter () {
      return this.$slots.footer
    },
    showHeader () {
      return this.$slots.header
    }
  }
}
</script>
