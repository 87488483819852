import { ActionHandle, ActionHandleFields } from '../contracts/models'

const smsNotifyMapper = (handleFields: any): ActionHandleFields => {
  return {
    phone_numbers: handleFields && handleFields.phone_numbers ? handleFields.phone_numbers : [],
    message: handleFields && handleFields.message ? handleFields.message : ''
  }
}

export const handleFieldsMapper = (action: any): any => {
  switch (action.handle) {
    case ActionHandle.SMS_NOTIFY: return smsNotifyMapper(action.handle_fields)
  }
}

export default handleFieldsMapper
