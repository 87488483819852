
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'UiStateButton'
})
export class UiStateButton extends Vue {
  @Prop({ required: false, default: false, type: Boolean })
  public disabled!: boolean

  @Prop({ required: true, type: String })
  public icon!: string

  @Prop({ required: true, type: Boolean })
  public loading!: boolean

  processClick (): void {
    this.$emit('click')
  }
}
export default UiStateButton
