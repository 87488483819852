import { SidebarItem } from '@contract/sidebar'

export const sidebarItems: SidebarItem[] = [
  {
    icon: 'fa fa-fw fa-calendar-alt',
    label: 'Wydarzenia',
    route: '/app/events',
    permission: ['events']
  },
  {
    icon: 'fa fa-fw fa-keyboard',
    label: 'Pola',
    route: '/app/fields',
    permission: ['events']
  },
  {
    icon: 'fa fa-fw fa-address-card',
    label: 'Zgłoszenia',
    route: '/app/participations',
    permission: ['participations']
  },
  {
    icon: 'fa fa-fw fa-users',
    label: 'Użytkownicy',
    route: '/app/users',
    permission: ['users'],
    children: [
      {
        'label': 'Lista użytkowników',
        'route': '/app/users',
        'permission': ['users']
      },
      {
        'label': 'Uprawnienia',
        'route': '/app/roles',
        'permission': ['users']
      }
    ]
  },
  {
    icon: 'fa fa-fw fa-receipt',
    label: 'Import zaproszeń',
    route: '/app/invitations/import',
    permission: ['events']
  },
  {
    icon: 'fa fa-fw fa-link',
    label: 'Linki do zaproszeń',
    route: '/app/invitations-links',
    permission: ['events']
  },
  {
    icon: 'fa fa-fw fa-cog',
    label: 'Akcje',
    route: '/app/actions',
    permission: ['events']
  }
]
