import { IFile } from '@contract/components'
import { ResourceResponseMeta } from '@contract/resources'
import { Field } from '@module/fields/contracts/models'
import { Invitation } from '@module/invitations/contracts/models'
import { Participation } from '@module/participations/contracts/models'

export interface Event {
  [key: string]: any
  id: number
  title: {
    en: string,
    pl: string,
  }
  slug: string
  startsAt: string
  endsAt: string
  registrationEndsAt: string
  enableSharing: boolean
  requiresTicket: boolean
  address: {
    en: string,
    pl: string,
  },
  content: {
    en: string,
    pl: string,
  }
  contact: EventContact
  links: EventLinks
  status: EventStatus
  forCompanies: boolean
  isPhoneRequired: boolean
  isVirtual: boolean
  private: boolean
  cover: {
    [key: string]: number,
    en: number,
    pl: number,
  },
  emailCover: {
    [key: string]: number,
    en: number,
    pl: number,
  }
  files: IFile[]
  priority: number
  fields?: Field[]
  maxParticipants: number
  participantsCount: number
  partners: EventPartners[]
  additionalInfo: string[]
  acceptParticipations: boolean
  createdAt: string
  updatedAt: string
}

export interface EventContact {
  [key: string]: string
  phone: string
  email: string
}

export interface EventLinks {
  youtube: {
    label: {
      en: string,
      pl: string,
    },
    link: {
      en: string,
      pl: string,
    },
  },
  facebook: {
    label: {
      en: string,
      pl: string,
    },
    link: {
      en: string,
      pl: string,
    },
  }
}

export enum EventMediaPurpose {
  Cover = 'cover',
  Partner = 'partner',
  EmailCover = 'email-cover'
}

export interface EventPartnerItem {
  logoId: number
  url: string
}

export interface EventPartners {
  labels: {
    pl: string,
    en: string
  }
  items: EventPartnerItem[]
}

export interface EventFilesPayload {
  index: number
  items: number[]
}

export enum EventStatus {
  'Draft' = 'draft',
  'Public' = 'public',
  'Archive' = 'archive'
}

export { ResourceResponseMeta as Meta } from '@contract/resources'

export interface State {
  events: Event[],
  eventFields: Field[],
  eventMeta: ResourceResponseMeta
  eventParticipations: Participation[]
  eventInvitations: Invitation[]
  eventSingle: Event,
  eventFiles: IFile[],
  meta: ResourceResponseMeta
}
