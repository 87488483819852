import { UserRole } from '@module/users/contracts/models'

export const userRoleLabel = (type: UserRole): string => {
  switch (type) {
    case UserRole.Administrator: return 'Administrator'
    case UserRole.Manager: return 'Menedżer'
    case UserRole.Coordinator: return 'Koordynator'
  }
}

export default userRoleLabel
