import { ParticipationStatus } from '@module/participations/contracts/models'

export const participationStatusLabel = (status: ParticipationStatus): {label: string, class: string} => {
  switch (status) {
    case ParticipationStatus.Confirmed: return {
      label: 'Potwierdzony',
      class: 'text-success'
    }
    case ParticipationStatus.Pending: return {
      label: 'Oczekujący',
      class: 'text-warning'
    }
    case ParticipationStatus.Visited: return {
      label: 'Odwiedzony',
      class: 'text-muted'
    }
  }
}

export default participationStatusLabel
