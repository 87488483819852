
import { Component, Vue } from 'vue-property-decorator'

import Block from '@component/block.vue'
import { Breadcrumbs } from '@component/breadcrumbs.vue'
import { IResponse } from '@contract/http'
import { ViewContent } from '@view/partials/content.vue'

import { AccessableComponent } from '@module/auth/shared/access-helpers'

import { userPayloadMapper } from '../../adapters/user'
import { User } from '../../contracts/models'
import { UserForm } from '@module/users/components/user-form.vue'

@Component({
  name: 'CreateUser',
  components: { Block, Breadcrumbs, UserForm, ViewContent }
})
export class CreateUser extends AccessableComponent {
  protected scope: string = 'users'
  /**
   * Is saving in progress.
   */
  public saving: boolean = false;

  /**
   * Form save button text..
   */
  public saveBtnText: string = 'Dodaj użytkownika';

  /**
   * Form name.
   */
  public formName: string = 'createUsers';

  /**
   * Breadcrumbs data getter.
   *
   * @return {object[]}
   */
  get breadcrumbs (): object[] {
    return [
      { label: 'Dodaj nowego użytkownika' }
    ]
  }

  /**
   * User model getter.
   *
   * @return {User}
   */
  get model (): User {
    return this.$store.getters['users/getUserSingle']
  }

  /**
   * Create form action.
   *
   * @return {Promise<IResponse>}
   */
  public async createUser (): Promise<void> {
    const response = await this.$store.dispatch('users/createUserSingle', {
      params: {}, data: userPayloadMapper(this.model)
    })

    if (!response.hasErrors()) {
      this.$router.push({ name: 'users.show', params: { id: response.data.data.id } })
    }
  }

  /**
   * Destroyed Vue hook.
   */
  public destroyed (): void {
    this.$store.dispatch('users/resetUserSingleState')
  }

  /**
   * Submit form handler.
   */
  public onSubmitHandler (): void {
    this.saving = true

    this.createUser().finally(() => {
      this.saving = false
    })
  }
}

export default CreateUser
