import { injectable } from 'inversify'
import { AuthHeader } from '@contract/resources'
import {
  Authenticable,
  AuthConfig,
  AuthRepository,
  IAuthorization,
  Token
} from '../contracts/services'
import { PasswordPayload, PermissionsList, RoleModel } from '../contracts/models'
import { Permission } from '@module/users/contracts/models'

/* global localStorage */

/**
 * Authorization is service class that provides token functionality.
 *
 * @author Łukasz Sitnicki <lukasz.sitnici@movecloser.pl>
 * @version 1.0.0
 */
@injectable()
export class Authorization implements IAuthorization {
  private _config: AuthConfig
  private _isLogged: boolean = false
  private _me: Authenticable = { id: null }
  private _permissions: Permission[] = []
  private _repository: AuthRepository
  private _roles: RoleModel[] = []

  constructor (config: AuthConfig, repository: AuthRepository) {
    this._config = config
    this._repository = repository
  }

  /**
   * Check if user is authenticated.
   */
  check (): boolean {
    return this._isLogged
  }

  /**
   * Send email with password reset link.
   */
  async forgotPassword (email: string): Promise<any> {
    return this._repository.forgotPassword(email)
  }

  /**
   * Return authorize header.
   */
  getAuthorizationHeader (): AuthHeader {
    let tokenHeader = ''
    const tokenData = this.retrieveToken()

    if (Object.prototype.hasOwnProperty.call(tokenData, 'tokenType') && tokenData.tokenType) {
      tokenHeader = `${tokenData.tokenType} ${tokenData.accessToken}`
    } else {
      tokenHeader = tokenData.accessToken !== null ? tokenData.accessToken : ''
    }

    return { Authorization: tokenHeader }
  }

  /**
   * Determine if me has expected level of access.
   */
  hasLevel (expected: number): boolean {
    return this._roles.filter((role: RoleModel) => {
      return role.level >= expected
    }).length > 0
  }

  /**
   * Determine if me has any role with given level of permission.
   */
  hasPermission (permission: string, level: number, restriction: string = 'all'): boolean {
    const scope = [...this._permissions].find(p => p.scope === permission)

    if (!scope) {
      return false
    } else {
      return scope.level >= level
    }

    // if (
    //   !this._permissions.hasOwnProperty(permission) ||
    //   typeof this._permissions[permission] === 'undefined'
    // ) {
    //   return false
    // }

    // let toCheck = []
    // if (restriction === '*') {
    //   toCheck = Object.keys(this._permissions[permission])
    // } else {
    //   toCheck = ['all']
    //
    //   if (restriction !== 'all') {
    //     toCheck.push(restriction)
    //   }
    // }
    //
    // for (const res of toCheck) {
    //   if (
    //     Object.prototype.hasOwnProperty.call(this._permissions[permission], res) &&
    //     this._permissions[permission][res] >= level
    //   ) {
    //     return true
    //   }
    // }
    //
    // return false
  }

  /**
   * Detect if me has given role assigned.
   */
  hasRole (expected: string): boolean {
    return this._roles.filter((role: RoleModel) => {
      return role.name === expected
    }).length > 0
  }

  /**
   * Login user.
   */
  async login (email: string, password: string): Promise<any> {
    const response = await this._repository.login(email, password)

    if (response.isSuccessful()) {
      this.storeToken(
        response.data.data.access_token,
        response.data.data.expires_in,
        response.data.data.token_type
      )

      this._roles = response.data.data.me.roles
      this._permissions = response.data.data.me.roles[0].permissions
      this._me = response.data.data.me

      this._isLogged = true
    }

    return this._isLogged
  }

  /**
   * Logout user.
   */
  async logout (): Promise<any> {
    const response = await this._repository.logout()

    if (response.isSuccessful()) {
      this.deleteToken()
      this._isLogged = false
    }

    return !this._isLogged
  }

  /**
   * Refresh token.
   */
  async refresh (): Promise<any> {
    const token: Token = this.retrieveToken()
    const response = await this._repository.refresh(token.accessToken)

    if (response.isSuccessful()) {
      this.storeToken(
        response.data.data.access_token,
        response.data.data.expires_in,
        response.data.data.token_type
      )

      this._roles = response.data.data.me.roles
      this._permissions = response.data.data.me.roles[0].permissions
      this._me = response.data.data.me

      this._isLogged = true
    } else if (response.status === 401) {
      this.deleteToken()
      this._isLogged = false
    }

    return this._isLogged
  }

  /**
   * Set new password.
   */
  async resetPassword (payload: PasswordPayload): Promise<any> {
    const response = await this._repository.resetPassword(payload)

    return response.isSuccessful()
  }

  /**
   * Return information about logged-in user.
   */
  user (): Authenticable|null {
    return this._me.id !== null ? this._me : null
  }

  /**
   * Deletes token.
   *
   */
  private deleteToken (): void {
    localStorage.removeItem(this._config.tokenName)
  }

  /**
   * Retrieves token from local storage.
   */
  retrieveToken (): Token {
    const token: Token = JSON.parse(
      localStorage.getItem(this._config.tokenName) as string
    )

    return (typeof token === 'object' && token !== null &&
      Object.prototype.hasOwnProperty.call(token, 'accessToken'))
      ? token : { accessToken: null, expiresIn: null, tokenType: null }
  }

  /**
   * Stores token in browsers local storage.
   */
  storeToken (accessToken: string, expiresIn: number, tokenType: string): void {
    localStorage.setItem(
      this._config.tokenName,
      JSON.stringify({ accessToken: accessToken, expiresIn: expiresIn, tokenType: tokenType })
    )
  }
}
