import { injectable } from 'inversify'
import moment, { Moment } from 'moment'

export interface IDateTime {
  now: (format: string) => string
  parse: (date: string) => string
  parseToFormat: (date: string, format: string) => string
  parseToDefaultFormat: (date: string) => string
}

/**
 * DateTime is service class that parses Date to wanted format
 *
 * @author  Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @version 1.0.0
 */
@injectable()
export class Datetime {
  /**
   * Default DateTime format.
   */
  public static DEFAULT_FORMAT: string = 'YYYY-MM-DD HH:mm:ss';

  constructor () {
    moment.locale('pl')
  }

  /**
   * Return now with given format.
   */
  now (format: string = ''): string {
    return format.length ? moment().format(format) : moment().format()
  }

  /**
   * Parse date to instance of moment.
   */
  parse (date: string): Moment {
    return moment(date)
  }

  /**
   * Returns date to specific format.
   */
  parseToFormat (date: string, format: string): string {
    return moment(date).format(format)
  }

  /**
   * Parse date to default format.
   *
   * @param {string} date
   */
  parseToDefaultFormat (date: string): string {
    return this.parseToFormat(date, Datetime.DEFAULT_FORMAT)
  }
}
