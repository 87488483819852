import { EventsList } from '@module/events/views/list.vue'
import { EditEvent } from '@module/events/views/show.vue'
import { CreateEvent } from '@module/events/views/create.vue'

export const eventModuleRoutes = [
  {
    path: 'events',
    name: 'list',
    component: EventsList,
    meta: { requiresAuth: true, permission: 'events', level: 1 }
  },
  {
    path: 'events/create',
    name: 'create',
    component: CreateEvent,
    meta: { requiresAuth: true, permission: 'events', level: 4 }
  },
  {
    path: 'events/:id',
    name: 'edit',
    component: EditEvent,
    meta: { requiresAuth: true, permission: 'events', level: 1 }
  }
]
