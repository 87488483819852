<template lang="html">
  <Dropdown class="header-dropdown" :alignRight="true" :showToggler="false">
    <template #title>
      <slot name="title"></slot>
    </template>

    <template>
      <slot></slot>
    </template>
  </Dropdown>
</template>

<script>
import Dropdown from '@component/dropdown'

export default {
  name: 'HeaderDropdown',
  components: { Dropdown }
}
</script>
