import { ActionContext } from 'vuex'

import { container } from '@/bootstrap/app'
import { IResponse, Payload } from '@contract/http'
import { IResources, ResourcesType } from '@contract/resources'

import { Invitation, State, Meta } from './contracts/models'
import { invitationAdapter } from './adapters/invitation'

export const invitationsModuleStore = {
  namespaced: true,
  state: {
    meta: {
      total: 0
    },
    invitationsList: [],
    invitationsImported: [],
    invitationSingle: {
      event: {}
    },
    tags: []
  },
  actions: {
    async deleteSingleInvitation ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('invitations', 'delete',
        { invitationId: payload.id },
        {}
      )

      if (!response.hasErrors()) {
        commit('removeSingleInvitation', payload.id)
      }

      return response
    },
    async fetchSingleInvitation ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('invitations', 'show',
        payload.params,
        payload.data
      )

      if (!response.hasErrors()) {
        commit('setSingleInvitation', response.data.data)
      }

      return response
    },
    async resentInvitationEmail ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('invitations', 'resent',
        { invitationId: payload.params.id },
        {}
      )

      if (!response.hasErrors()) {
        commit('setSingleInvitation', response.data.data)
      }

      return response
    },
    async importInvitations ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('invitations', 'import',
        payload.params,
        { data: payload.data }
      )

      if (!response.hasErrors()) {
        commit('setInvitationsImported', response.data.data)
      }

      return response
    },
    async updateSingleInvitation ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('invitations', 'update',
        { invitationId: payload.params.id },
        payload.data
      )

      if (!response.hasErrors()) {
        commit('setSingleInvitation', response.data.data)
      }

      return response
    },
    async resetSingleInvitationState ({ commit }: ActionContext<State, any>) {
      commit('setSingleInvitation', {
        event: {}
      })
    }
  },
  getters: {
    getMeta: (state: State): Meta => {
      return state.meta
    },
    getInvitationsImported: (state: State): Invitation[] => {
      return state.invitationsImported
    },
    getInvitationsList: (state: State): Invitation[] => {
      return state.invitationsList
    },
    getSingleInvitation: (state: State): Invitation => {
      return state.invitationSingle
    }
  },
  mutations: {
    removeSingleInvitation: (state: State, id: number) => {
      const flatInvitationsList = state.invitationsList.map((invitation: Invitation) => invitation.id)
      const index = flatInvitationsList.indexOf(id)

      if (index !== -1) {
        state.invitationsList.splice(index, 1)
      }
    },
    setInvitationsImported (state: State, collection: Invitation[]) {
      state.invitationsImported = invitationAdapter.collection<Invitation>(collection)
    },
    setInvitationsList (state: State, collection: Invitation[]) {
      state.invitationsList = invitationAdapter.collection<Invitation>(collection)
    },
    setMeta: (state: State, meta: Meta) => {
      state.meta = meta
    },
    setSingleInvitation: (state: State, invitation: Invitation) => {
      state.invitationSingle = invitationAdapter.model<Invitation>(invitation)
    }
  }
}

export default invitationsModuleStore
