
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

import { EventFields } from './event-fields.vue'
import { Field } from '@module/fields/contracts/models'
import { eventsFieldsPayloadMapper } from '@module/fields/adapters/field'

@Component({
  name: 'TabForm',
  components: { EventFields }
})
export class TabForm extends Vue {
  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Form name.
   *
   * @type {string}
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Form save button text.
   *
   * @type {string}
   */
  @Prop({ type: String, required: false, default: 'Zapisz zmiany' })
  public saveBtnText!: string;

  /**
   * Is form saving.
   *
   * @type {boolean}
   */
  public saving: boolean = false;

  /**
   * Event fields collection getter.
   *
   * @return {Field}
   */
  get collection (): Field[] {
    return [...this.$store.getters['events/getEventFields']]
  }

  set collection (items) {
    this.$store.commit('events/setEventFields', items)
  }

  created (): void {
    this.$store.dispatch('events/fetchEventFields',
      { id: this.$route.params.id }
    )
  }

  public async onSubmit (): Promise<void> {
    this.saving = true
    await this.updateEventFields()
    this.saving = false
  }

  /**
   * Update event fields.
   */
  protected async updateEventFields () : Promise<void> {
    const payload = this.collection.map((field: Field, index: number) => {
      return eventsFieldsPayloadMapper(field, index)
    })
    await this.$store.dispatch('events/updateEventFields', {
      id: this.$route.params.id,
      data: payload
    })
  }
}
export default TabForm
