import { Adapter, MappingConfig } from '@support/adapter'

class FileAdapter extends Adapter {
  /**
   * Adapter mapping types.
   *
   * @type {MappingConfig}
   */
  mapping: MappingConfig = {
    id: 'id',
    directory: 'directory',
    hasVariants: 'has_variants',
    file: 'file',
    purpose: 'purpose',
    mime: 'mime',
    size: 'size',
    name: 'name',
    author: 'author',
    capture: 'capture',
    title: 'title',
    alt: 'alt',
    note: 'note',
    addedBy: 'added_by',
    uploadedAt: 'uploaded_at'
  }
  preserve: boolean = false
}
export const fileAdapter = new FileAdapter()
