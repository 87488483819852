import { container } from '@/bootstrap/app'

import { IResponse } from '@contract/http'
import { IToaster, ToasterType } from '@contract/toaster'

/**
 * Handles HTTP 400.
 */
function badRequest (error: IResponse): void {
  const toaster: IToaster = container.get(ToasterType)

  if (error.status === 400 || error.status === 409) {
    toaster.error(
      error.errors !== null ? error.errors.message : error.status
    )
  }
}

/**
 * Handles errors of unauthorized request.
 *
 * @param {Response} error
 */
// export function forbidden (error) {
//   const toaster = container.make('toaster')
//
//   if (error.status === 403) {
//     toaster.error(error.errors.message)
//   }
// }
//
// /**
//  * Handles errors of not found resource.
//  *
//  * @param {Response} error
//  */
// export function notFound (error) {
//   if (error.status === 404) {
//     router.push('404')
//   }
// }
//
// /**
//  * Handles server error.
//  *
//  * @param  {Response} error
//  */
// export function serverError (error) {
//   const toaster = container.make('toaster')
//
//   if (error.status === 500) {
//     toaster.error(
//       process.env.VUE_APP_DEBUG ? error.errors.message : 'Upss... Chyba mamy bład serwera.'
//     )
//   }
// }

/**
 * Handles success and display toastr messages if needed.
 */
function success (response: IResponse): void {
  const toaster: IToaster = container.get(ToasterType)

  if (response.data.hasOwnProperty('message') && typeof response.data.message !== 'undefined') {
    toaster.success(response.data.message)
  }
}

// /**
//  * Handles errors of unauthenticated request.
//  *
//  * @param {Response} error
//  */
// export function unauthenticated (error) {
//   const authorization = container.make('authorization')
//
//   if (error.status === 401) {
//     authorization.refresh().then(result => {
//       if (!result) {
//         router.push('/')
//       }
//     })
//   }
// }

export type Handler = (response: IResponse) => void

export const handlers: Handler[] = [
  badRequest,
  success
]
