
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Component as VueComponent } from 'vue'

import { ISelectOption } from '@contract/components'
import { UiInput } from '@component/form/input.vue'
import { UiSelect } from '@component/form/select.vue'
import { UiStateButton } from '@component/button/state.vue'
import { UiText } from '@component/form/text.vue'

import { Role } from '../contracts/models'

@Component({
  name: 'RoleForm',
  components: {
    UiInput,
    UiSelect,
    UiText,
    UiStateButton
  }
})
export class RoleForm extends Vue {
  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string

  /**
   * Form name.
   */
  @Prop({ type: Object, required: true })
  public model!: Role

  /**
   * Is form saving.
   */
  @Prop({ type: Boolean, required: true })
  public saving!: boolean

  /**
   * Form save button text.
   */
  @Prop({ type: String, required: false, default: 'Zapisz zmiany' })
  public saveBtnText!: string

  /**
   * Is saving in progress?
   */
  public savingInProgress: boolean = this.saving

  /**
   * Available types getters.
   *
   * @return {ISelectOption[]}
   */
  // public get availableRoles (): ISelectOption[] {
  //   if (this.roles && this.roles.length) {
  //     return this.roles.map((role: Role) => {
  //       return {
  //         value: role.id,
  //         label: userRoleLabel(role.name)
  //       }
  //     })
  //   }
  //   return []
  // }

  public get availableLevels (): ISelectOption[] {
    return [
      { value: 4, label: 'Pełen dostęp' },
      { value: 1, label: 'Tylko odczyt' },
      { value: 0, label: 'Brak dostępu' }
    ]
  }

  /**
   * Event model getter.
   *
   * @return {Event}
   */
  get roles (): Role[] {
    return this.$store.getters['users/getRoles']
  }

  public created (): void {
    this.$store.dispatch('users/fetchRolesList')
  }

  /**
   * Submit form handler.
   */
  public submitHandler (): void {
    this.$emit('submit')
  }

  /**
   * Saving prop property watcher.
   *
   * @param {boolean} isSaving
   */
  @Watch('saving', { deep: true })
  public onSavingChange (isSaving: boolean): void {
    this.savingInProgress = isSaving
  }
}

export default RoleForm
