
import { Component, Prop, Watch } from 'vue-property-decorator'

import { AbstractInput } from '../abstract/input'

@Component({
  name: 'UiText'
})
export class UiText extends AbstractInput {
  @Prop({ required: false, default: '' })
  public classInput!: string

  @Prop({ required: false, default: '' })
  public placeholder!: string

  @Prop({ required: false, default: 4 })
  public rows!: number

  public local: string = ''

  created () {
    this.local = this.value && this.value.replace(/<br\s*[/]?>/gi, '\n')
  }

  @Watch('local')
  onLocalChange (value: string): void {
    this.value = value && value.replace(/\n/g, '<br />')
  }

  @Watch('value')
  onValueChange (value: string): void {
    this.local = this.value && this.value.replace(/<br\s*[/]?>/gi, '\n')
  }
}

export default UiText
