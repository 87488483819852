<template>
  <div class="block" :class="blockClass">
    <div class="block-header" :class="[headerClass, {'block-header-default': !headerClass}]" v-if="showHeaderSlot">
      <slot name="header"></slot>
    </div>
    <div class="block-content block-content-full" :class="contentClass">
      <slot></slot>
    </div>
    <div class="block-footer" :class="footerClass" v-if="showFooterSlot">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Block',
  props: {
    blockClass: String,
    contentClass: String,
    footerClass: String,
    headerClass: String
  },
  computed: {
    showFooterSlot () {
      return this.$slots.footer
    },
    showHeaderSlot () {
      return this.$slots.header
    }
  }
}
</script>
