
import { Component, Prop, Vue } from 'vue-property-decorator'

import NavItem from './nav-item.vue'

@Component({
  name: 'NavTabs',
  components: { NavItem }
})
export class NavTabs extends Vue {
  @Prop({ type: Number, required: true })
  public activeItem!: number;

  @Prop({ type: Boolean, required: true })
  public isDisabled!: boolean;

  @Prop({ type: Array, required: true })
  public items!: any[];

  @Prop({ type: String, required: true })
  public tabs!: string;

  public blocked: number | undefined;

  public get active (): number {
    return this.activeItem
  }

  public set active (value: number) {
    this.$emit('change', value)
  }

  public isItemBlocked (index: number): boolean {
    return this.blocked === index
  }

  public isItemDisabled (index: number): boolean {
    return index > 0 && this.isDisabled
  }
}

export default NavTabs

// mounted () {
//   EventBus.$on(`tabs:${this.tabs}.blocked`, id => {
//     this.blocked = id
//
//     setTimeout(() => {
//       this.blocked = null
//     }, 600)
//   })
// }
