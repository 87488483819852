import { ResourceResponseMeta } from '@contract/resources'

export interface Field {
  id: number,
  basic: boolean,
  autocomplete: boolean,
  type: FieldType | null,
  name: string,
  label: {
    en: string,
    pl: string
  },
  default: {
    en: string,
    pl: string
  },
  comment?: {
    en: string,
    pl: string
  },
  key?: string,
  placeholder: {
    en: string,
    pl: string
  },
  order: number,
  requirements: FieldRequirements,
  required?: number | null,
  taggable: boolean,
  dictionary?: FieldDictionaryItem[]
  createdAt: string,
  updatedAt: string
}

export interface FieldDictionaryItem {
  label: {
    en: string,
    pl: string
  }
  value: string,
}

export interface FieldRequirements {
  min?: number | null,
  max?: number | null,
  pattern?: string | null,
  type?: string | null,
  role?: string | null,
  multiple: boolean | null;
  isChoiceField: boolean | null;
}

export enum FieldType {
  'Boolean' = 'boolean',
  'Number' = 'number',
  'String' = 'string'
}

export { ResourceResponseMeta as Meta } from '@contract/resources'

export interface State {
  fields: Field[],
  fieldSingle: Field,
  meta: ResourceResponseMeta
}
