
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { Subject, Subscription } from 'rxjs'

import { Tag, Taggable } from '@module/participations/contracts/models'

import { UiInput } from '@component/form/input.vue'
import { UiStateButton } from '@component/button/state.vue'

@Component({
  name: 'Tags',
  components: {
    UiInput,
    UiStateButton
  }
})
export class Tags extends Vue {
  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Can edit model.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public isEditable!: boolean

  /**
   * Entity model
   */
  @Prop({ type: Object, required: true })
  public model!: Taggable

  /**
   * New tags.
   */
  @Prop({ type: Array, required: true })
  public newTags!: string[];

  /**
   * Is form saving.
   */
  @Prop({ type: Boolean, required: true })
  public saving!: boolean;

  /**
   * Search for tag label
   */
  public loadingTags: boolean = false

  /**
   * Is saving in progress?
   */
  public savingInProgress: boolean = this.saving;

  /**
   * Search for tag label
   */
  public search: string = ''

  /**
   * RxJS subscription object.
   *
   * @type {Subscription}
   */
  protected subscription!: Subscription

  /**
   * If any tags were added
   */
  public tagsEditted: boolean = false

  /**
   * RxJS subject object.
   *
   * @type {Subject<Query>}
   */
  protected querySubject!: Subject<string>

  /**
   * Check if tag is already added as new tag
   *
   * @return boolean
   */
  public get checkIfTagAlreadyInNew (): boolean {
    return [...this.newTags, ...this.modelTagsFlat(this.model.tags, 'label')].indexOf(this.search) !== -1
  }

  /**
   * Tag models collection getter.
   *
   * @return {Tag[]}
   */
  public get tags (): Tag[] {
    return this.$store.getters['participations/getTagsList']
  }

  /**
   * Add existing tag
   *
   * @return void
   */
  public addExistingTag (tag: Tag): void {
    this.tagsEditted = true
    this.model.tags.push(tag)
  }

  /**
   * Add new tag
   *
   * @return void
   */
  public addNewTag () {
    this.tagsEditted = true
    this.newTags.push(this.search)
    this.search = ''
  }

  /**
   * Check if tag is already added from existing tags
   *
   * @return boolean
   */
  public checkIfSearchAlreadyInTags (): boolean {
    return (this.modelTagsFlat(this.tags, 'label') as unknown as string[]).indexOf(this.search) !== -1
  }

  /**
   * Check if tag existing tag is already in use
   *
   * @return boolean
   */
  public checkIfTagAlreadyInExisted (id: number): boolean {
    return this.modelTagsFlat(this.model.tags, 'id').indexOf(id) !== -1
  }

  /**
   * Created Vue hook.
   */
  public created (): void {
    this.querySubject = new Subject()
    this.subscription = this.querySubject.pipe(
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe((query: string) => this.onTagsSearch(query))
  }

  /**
   * Tag models  getter.
   * @return {number[]}
   */
  public modelTagsFlat (tags: Tag[], key: string = 'id'): number[] {
    return tags.map((tag: any) => tag[key])
  }

  /**
   * Call to search action
   *
   * @return void
   */
  public async onTagsSearch (query: string) {
    if (query.length) {
      this.loadingTags = true
      await this.$store.dispatch('participations/fetchTagsList', {
        q: query
      })
      this.loadingTags = false
    } else {
      this.$store.dispatch('participations/clearTagsList')
    }
  }

  /**
   * Remove existing tag
   *
   * @return void
   */
  public removeExistingTag (tag: Tag): void {
    this.tagsEditted = true
    const flatTagsList = this.model.tags.map((tag: Tag) => tag.id)
    const index = flatTagsList.indexOf(tag.id)
    if (index !== -1) {
      this.model.tags.splice(index, 1)
    }
  }

  /**
   * Remove new tag
   *
   * @return void
   */
  public removeNewTag (index: number): void {
    this.newTags.splice(index, 1)
    this.tagsEditted = true
  }

  /**
   * Submit form handler.
   */
  public updateTagsHandler (): void {
    this.$emit('updateTags')
    this.tagsEditted = false
  }

  /**
   * Saving prop property watcher.
   *
   * @param {boolean} isSaving
   */
  @Watch('saving', { deep: true })
  public onSavingChange (isSaving: boolean): void {
    this.savingInProgress = isSaving
  }

  /**
   * search property watcher
   *
   * @param {boolean} isSaving
   */
  @Watch('search', { deep: true })
  public onSearchTagsChange (search: string): void {
    this.querySubject.next(search)
  }
}

export default Tags
