import { injectable } from 'inversify'
import { Payload } from '@contract/http'
import {
  IModelRepository,
  INestedRepository,
  IRepository,
  RepositoryAction,
  RepositoryConfig,
  RepositoryGetter
} from '@contract/repository'

import { store } from '@/bootstrap/app'

/**
 * Repository is service class that provides loading data via store.
 *
 * @author Łukasz Sitnicki <lukasz.sitnici@movecloser.pl>
 * @version 1.0.0
 */
@injectable()
export abstract class Repository implements IRepository {
  protected _config: RepositoryConfig = {}

  /**
   * Return collection from store.
   */
  get collection (): object[] {
    return this.callGetter(this.getState(RepositoryGetter.Collection)) as object[]
  }

  /**
   * Map model from state.
   */
  get meta (): object {
    return this.callGetter(this.getState(RepositoryGetter.Meta))
  }
  /**
   * Map model from state.
   */
  get model (): object {
    return this.callGetter(this.getState(RepositoryGetter.Model))
  }

  /**
   * Call store action.
   */
  protected callAction (action: string, payload: Payload = {}): Promise<any> {
    return store.dispatch(action, payload)
  }

  /**
   * Call store getter.
   */
  protected callGetter (getter: string): object[]|object {
    return store.getters[getter]
  }

  /**
   * Call store mutation.
   */
  protected callMutation (mutation: string, payload: Payload): void {
    store.commit(mutation, payload)
  }

  /**
   * Return action name to dispatch.
   */
  protected getAction (action: RepositoryAction): string {
    if (!this._config.hasOwnProperty(action) || typeof this._config[action] === 'undefined') {
      throw new Error(
        `Trying to call undefined Repository action [${action}].`
      )
    }

    return (this._config[action] as string)
  }

  /**
   * Return state key.
   */
  protected getState (key: RepositoryGetter): string {
    if (!this._config.hasOwnProperty(key) || typeof this._config[key] === 'undefined') {
      throw new Error(
        `Trying to get undefined Repository state [${key}].`
      )
    }

    return (this._config[key] as string)
  }
}

/**
 * ModelRepository is a generic repository for CRUD model.
 */
export abstract class ModelRepository extends Repository implements IModelRepository {
  /**
   * Remove model by id.
   */
  async delete (id: string|number): Promise<any> {
    return this.callAction(
      this.getAction(RepositoryAction.Delete),
      { id }
    )
  }

  /**
   * Load collecition via action.
   */
  async loadCollection (filters: Payload|null = null): Promise<any> {
    return this.callAction(
      this.getAction(RepositoryAction.Collection),
      filters !== null ? filters : {}
    )
  }

  /**
   * Load specific model via action.
   */
  async loadModel (id: string|number): Promise<any> {
    return this.callAction(
      this.getAction(RepositoryAction.Model),
      { id }
    )
  }

  /**
   * Store new model.
   */
  async store (payload: Payload): Promise<any> {
    return this.callAction(
      this.getAction(RepositoryAction.Store),
      { payload }
    )
  }

  /**
   * Update model by id.
   */
  async update (id: string|number, payload: Payload): Promise<any> {
    return this.callAction(
      this.getAction(RepositoryAction.Update),
      { id, payload }
    )
  }
}

/**
 * NestedRepository is a generic repository for CRUD nested model.
 */
export abstract class NestedRepository extends Repository implements INestedRepository {
  /**
   * Remove model by id.
   */
  async delete (parent: string|number, id: string|number): Promise<any> {
    return this.callAction(
      this.getAction(RepositoryAction.Delete),
      { parent, id }
    )
  }

  /**
   * Load collecition via action.
   */
  async loadCollection (parent: string|number, filters: Payload|null = null): Promise<any> {
    return this.callAction(
      this.getAction(RepositoryAction.Collection),
      { parent, filters }
    )
  }

  /**
   * Load specific model via action.
   */
  async loadModel (parent: string|number, id: string|number): Promise<any> {
    return this.callAction(
      this.getAction(RepositoryAction.Model),
      { parent, id }
    )
  }

  /**
   * Store new model.
   */
  async store (parent: string|number, payload: Payload): Promise<any> {
    return this.callAction(
      this.getAction(RepositoryAction.Store),
      { parent, payload }
    )
  }

  /**
   * Update model by id.
   */
  async update (parent: string|number, id: string|number, payload: Payload): Promise<any> {
    return this.callAction(
      this.getAction(RepositoryAction.Update),
      { parent, id, payload }
    )
  }
}
