<template lang="html">
  <div class="dropdown" :class="{show: isOpened}">
    <button type="button" class="btn btn-square border-0" :class="[buttonClass, {'dropdown-toggle': showToggler}]"
            @click="toggleDropdown" v-closable="{ handler: 'closeDropdown', exclude: [] }">
      <slot name="title"></slot>
    </button>
    <div class="dropdown-menu shadow" :class="{show: isOpened, 'dropdown-menu-right': alignRight}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import EventBus from '@service/eventbus'

export default {
  name: 'Dropdown',
  props: {
    alignRight: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonClass: {
      type: String,
      required: false
    },
    open: {
      type: Boolean,
      required: false,
      default: null
    },
    showToggler: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      isOpened: false
    }
  },
  mounted () {
    EventBus.$on('interface:dropdown.close', () => {
      this.isOpened = false
    })
  },
  methods: {
    closeDropdown () {
      this.isOpened = false
    },
    toggleDropdown () {
      this.isOpened = !this.isOpened
    }
  }
}
</script>
