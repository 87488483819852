
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { UiDate } from '@component/form/date.vue'
import { UiInput } from '@component/form/input.vue'
import { UiImage } from '@component/image.vue'
import { UiText } from '@component/form/text.vue'

import { Event as EventContract } from './../contracts/models'
import { IDateFieldTimePickerOptions, IFile } from '@contract/components'

@Component({
  name: 'EventFormBasicFields',
  components: { UiDate, UiInput, UiImage, UiText }
})
export class EventFormBasicFields extends Vue {
  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Event model.
   */
  @PropSync('model', { type: Object, required: true })
  public event!: EventContract

  /**
   * Time picker options.
   */
  public timePickerOptions: IDateFieldTimePickerOptions = {
    start: '00:00',
    step: '00:30',
    end: '23:30'
  }
}

export default EventFormBasicFields
