import { ActionContext } from 'vuex'

import { container } from '@/bootstrap/app'
import { AuthorizationType, IResources, ResourcesType } from '@contract/resources'
import { IResponse, Payload } from '@contract/http'
import { IValidation, ValidationType } from '@contract/validation'

import { IAuthorization } from '@module/auth/contracts/services'

import { MeState, State } from './contracts/store'

function startTimeout (expiresIn: number): void {
  const callIn = expiresIn > 10 ? expiresIn - 2 : expiresIn

  setTimeout(function () {
    const authorization: IAuthorization = container.get(AuthorizationType)
    authorization.refresh()
  }, callIn * 1000)
}

export default {
  namespaced: true,
  state: {
    me: {}
  },
  actions: {
    async forgotPassword (context: ActionContext<State, any>, payload: Payload) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'password', 'send',
        {}, { email: payload.email }
      )

      return response.isSuccessful()
    },

    async login ({ state }: ActionContext<State, any>, payload: Payload) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('auth', 'signIn', {}, payload)

      if (response.isSuccessful()) {
        state.me = {
          id: response.data.data.me.id,
          email: response.data.data.me.email,
          roles: response.data.data.me.roles,
          name: state.me.name,
          surname: state.me.surname
        }

        startTimeout(response.data.data.expires_in)
      } else if (response.status === 401) {
        const validation: IValidation = container.get(ValidationType)
        validation.pushErrors(
          'loginForm',
          response.errors !== null ? { email: [response.errors.message] } : {}
        )
      }

      return response
    },

    async logout () {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('auth', 'signOut', {})

      return response
    },

    async refresh ({ state, commit }: ActionContext<State, any>, payload: Payload) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'auth', 'refresh',
        {}, { token: payload.token }
      )

      if (response.isSuccessful()) {
        commit('setMe', response.data.data.me)

        startTimeout(response.data.data.expires_in)
      }

      return response
    },

    async resetPassword (context: ActionContext<State, any>, payload: Payload) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'password', 'reset',
        {},
        {
          email: payload.email,
          password: payload.password,
          password_confirmation: payload.password_confirmation,
          token: payload.token
        }
      )

      return response.isSuccessful()
    }

  },
  getters: {
    getMe: (state: State): MeState => {
      return {
        id: state.me.id,
        email: state.me.email,
        roles: state.me.roles,
        name: state.me.name,
        surname: state.me.surname
      }
    }
  },
  mutations: {
    setMe: (state: State, me: MeState) => {
      state.me = me
    }
  }
}
