import { ComponentOptions, VueConstructor } from 'vue'
import { interfaces } from 'inversify'
import { createDecorator } from 'vue-class-component'

let Vue: VueConstructor

export const Inject = (identifier?: interfaces.ServiceIdentifier<any>) => (proto: Vue, key: string) => {
  let Type: any

  if (typeof Reflect !== 'undefined' && typeof Reflect.getMetadata === 'function') {
    Type = Reflect.getMetadata('design:type', proto, key)
  }

  return createDecorator((options: ComponentOptions<Vue>, key) => {
    options.computed = options.computed || {}
    options.computed[key] = function (this: any) {
      return this.$container.get(identifier || Type)
    }
  })(proto, key)
}

function install (_Vue: VueConstructor) {
  if (Vue && _Vue === Vue) {
    if (process.env.NODE_ENV !== 'production') {
      /* eslint no-console: off */
      console.error('[inversify] already installed.')
    }
  } else {
    _Vue.mixin({ beforeCreate: initInversify })
  }
}

function initInversify () {
  // @ts-ignore
  const component: Vue = this
  const options: ComponentOptions<Vue> = component.$options

  if (options.container) {
    component.$container = options.container
  } else if (options.parent && options.parent.$container) {
    component.$container = options.parent.$container
  }
}

export default {
  install
}
