import { ActionsList } from '@module/actions/views/list.vue'
import { CreateAction } from '@module/actions/views/create.vue'
import { EditAction } from '@module/actions/views/edit.vue'

export const actionsModuleRoutes = [
  {
    path: 'actions',
    name: 'list',
    component: ActionsList,
    meta: { requiresAuth: true, permission: 'events', level: 1 }
  },
  {
    path: 'actions/create',
    name: 'create',
    component: CreateAction,
    meta: { requiresAuth: true, permission: 'events', level: 4 }
  },
  {
    path: 'actions/:id',
    name: 'edit',
    component: EditAction,
    meta: { requiresAuth: true, permission: 'events', level: 1 }
  }
]

export default actionsModuleRoutes
