import { Adapter, MappingConfig, MappingTypes } from '@support/adapter'

import { Payload } from '@contract/http'
import { Role } from '../contracts/models'
import { permissionAdapter } from '@module/users/adapters/permission'

class RoleAdapter extends Adapter {
  mapping: MappingConfig = {
    id: 'id',
    level: 'level',
    name: 'name',
    permissions: {
      type: MappingTypes.Adapter,
      value: 'permissions',
      adapter: permissionAdapter
    }
  }
  preserve: boolean = false
}
export const roleAdapter = new RoleAdapter()

export const rolePayloadMapper = (model: Role): Payload => {
  return {
    level: model.level,
    name: model.name,
    permissions: model.permissions
  }
}

export default {
  roleAdapter: roleAdapter,
  roleMapper: rolePayloadMapper
}
