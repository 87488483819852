
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'
import { ParseResult, parse } from 'papaparse'
import { VueConstructor } from 'vue'

import { AuthorizationType } from '@contract/resources'
import { IAuthorization } from '@module/auth/contracts/services'
import { ModelsList } from '@component/models/list.vue'
import { UiStateButton } from '@component/button/state.vue'
import { ViewContent } from '@view/partials/content.vue'

import { ImportInvitationRow } from '@module/invitations/components/import-invitation-row.vue'
import { InvitationRow } from '@module/invitations/components/invitation-row.vue'
import { InvitationFilters } from '@module/invitations/components/invitation-filters.vue'
import { invitationImportPayloadMapper } from '@module/invitations/adapters/invitation'
import { Invitation, InvitationParsed, InvitationToImport } from '@module/invitations/contracts/models'
import { IResponse } from '@contract/http'
import Breadcrumbs from '@component/breadcrumbs.vue'

@Component({
  name: 'ImportInvitations',
  components: {
    InvitationFilters,
    ModelsList,
    Breadcrumbs,
    UiStateButton,
    ViewContent
  }
})
export class ImportInvitations extends Vue {
  /**
   * Form authorization.
   *
   * @type {IAuthorization}
   */
  @Inject(AuthorizationType)
  private _authorization!: IAuthorization

  /**
   * Invitations to import collection.
   *
   * @type {InvitationToImport[]}
   */
  public collection: any = [];

  /**
   * Validation errors
   *
   * @type {string[]}
   */
  public errors: any = []

  /**
   * Form name.
   */
  public formName = 'invitationsImport';

  /**
   * Is import in progress.
   *
   * @type {boolean}
   */
  public loading: boolean = false;

  /**
   * List row component.
   *
   * @type {VueConstructor}
   */
  public rowComponent: VueConstructor = ImportInvitationRow;

  /**
   * Get breadcrumbs details.
   *
   * @return {object[]}
   */
  public get breadcrumbs (): object[] {
    return [
      { label: `Import zaproszeń` }
    ]
  }

  /**
   * Required scope of permissions.
   *
   * @type {string}
   */
  protected scope: string = 'events'

  /**
   * Invitations imported getter.
   *
   * @return {Invitation[]}
   */
  public get invitationsImported (): Invitation[] {
    return this.collection.map((invitation: InvitationToImport) => {
      invitation.errors = []
      invitation.imported = true

      if (invitation.id in this.errors) {
        invitation.errors = this.errors[invitation.id]
      }

      return invitation
    })
  }

  /**
   * If user can edit this form.
   *
   * @return {boolean}
   */
  public canEdit (): boolean {
    return this._authorization.hasPermission(this.scope, 4)
  }

  /**
   * Import data change handler.
   *
   * @param evt {Event}
   * @return {void}
   */
  public onImportFileChange (evt: Event): void {
    this.errors = []

    const target = evt.currentTarget as HTMLInputElement
    const file = (target.files) ? target.files.item(0) : null

    if (file) {
      parse(file, {
        header: true,
        complete: (results: ParseResult<InvitationParsed>) => {
          this.composeCollectionFromParsedResult(results)
        }
      })
    }
  }

  /**
   * Import start handler.
   *
   * @return {void}
   */
  public onImportStart (): void {
    this.errors = []
    this.loading = true

    this.importInvitations()
      .then((response: IResponse) => {
        // if response is successful
        // set valid component state
        if (response.isSuccessful()) {
          this.errors = response.data.errors || []
          this.collection = this.invitationsImported
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  /**
   * Compose collection from parsed CSV file.
   *
   * @return {void}
   */
  protected composeCollectionFromParsedResult (result: ParseResult<InvitationParsed>): void {
    this.collection = result.data.map((item, id) => {
      return {
        id: `i-${id}`,
        email: item.email,
        locale: item.locale,
        eventId: item.eventId,
        tags: `${item.tags}`
          .split('|')
          .map((tag: string) => {
            return tag.trim()
          })
          .filter((tag: string) => {
            return Boolean(tag)
          }),
        errors: [],
        imported: false
      }
    })
  }

  /**
   * Dispatch store import invitations action.
   *
   * @return {Promise<any>}
   */
  protected importInvitations (): Promise<any> {
    return this.$store.dispatch('invitations/importInvitations', {
      params: {},
      data: {
        ...invitationImportPayloadMapper(this.collection)
      }
    })
  }
}

export default ImportInvitations
