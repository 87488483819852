
import { Component, Prop } from 'vue-property-decorator'

import { AbstractInput } from '../abstract/input'

@Component({
  name: 'UiInput'
})
export class UiInput extends AbstractInput {
  @Prop({ required: false, default: () => {} })
  public attr!: object

  @Prop({ required: false, default: true })
  public autocomplete!: boolean

  @Prop({ required: false, default: false })
  public autofocus!: boolean

  @Prop({ required: false, default: '' })
  public classInput!: string

  @Prop({ required: false, default: '' })
  public placeholder!: string

  @Prop({ required: false, default: 'text' })
  public type!: string

  get classObject (): object {
    return {
      'form-control': !this.readonly,
      'form-control-plaintext': this.readonly,
      'is-invalid': this.hasErrors
    }
  }
}
export default UiInput
