import { ActionContext } from 'vuex'

import { container } from '@/bootstrap/app'
import { IResponse, Payload } from '@contract/http'
import { IResources, ResourcesType } from '@contract/resources'

import { eventAdapter } from './adapters/event'
import { Event, Meta, State } from './contracts/models'

import { fieldAdapter } from '@module/fields/adapters/field'
import { Field } from '@module/fields/contracts/models'
import { Invitation } from '@module/invitations/contracts/models'
import { invitationAdapter } from '@module/invitations/adapters/invitation'
import { Participation } from '@module/participations/contracts/models'
import { participationAdapter } from '@module/participations/adapters/participation'
import { IFile } from '@contract/components'
import { fileAdapter } from '@module/media/adapters/file'
import { IToaster, ToasterType } from '@contract/toaster'

function initialState (): Event {
  return {
    title: {
      pl: '',
      en: ''
    },
    address: {
      pl: '',
      en: ''
    },
    content: {
      pl: '',
      en: ''
    },
    contact: {
      phone: '',
      email: ''
    },
    links: {
      youtube: {
        label: {
          en: '',
          pl: ''
        },
        link: {
          en: '',
          pl: ''
        }
      },
      facebook: {
        label: {
          en: '',
          pl: ''
        },
        link: {
          en: '',
          pl: ''
        }
      }
    },
    isPhoneRequired: false
  } as Event
}

export const eventModuleStore = {
  namespaced: true,
  state: {
    events: [],
    meta: {
      total: 0
    },
    eventSingle: initialState(),
    eventMeta: {
      total: 0
    },
    eventFiles: [],
    eventFields: [],
    eventParticipations: [],
    eventInvitations: []
  },
  actions: {
    async addTagsToParticipations ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'participations',
        'addTags',
        {},
        payload
      )

      return response
    },
    async exportInvitationsAsCsv (store: ActionContext<State, any>, payload: Payload = {}) {
      if (payload.filters.hasOwnProperty('page')) {
        delete payload.filters.page
      }

      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'eventInvitations',
        'list',
        {
          eventId: payload.id
        },
        payload.filters,
        {
          'Accept': 'application/octet-stream'
        },
        'blob'
      )

      if (response.isSuccessful()) {
        return response.data
      }

      return null
    },
    async exportParticipationsAsCsv (store: ActionContext<State, any>, payload: Payload = {}) {
      if (payload.filters.hasOwnProperty('page')) {
        delete payload.filters.page
      }

      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'eventParticipations',
        'show',
        {
          eventId: payload.id
        },
        payload.filters,
        {
          'Accept': 'application/octet-stream'
        },
        'blob'
      )

      if (response.isSuccessful()) {
        return response.data
      }

      return null
    },
    async fetchEventsList ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'events',
        'list',
        {},
        payload,
        {
          'Accept': '*/*'
        }
      )

      if (response.isSuccessful()) {
        commit('setEventsList', response.data.data)
        commit('setMeta', response.data.meta)
      }

      return response
    },
    async fetchSingleEvent ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'events',
        'show',
        payload.params,
        payload.data
      )

      if (response.isSuccessful()) {
        commit('setSingleEvent', response.data.data)
        commit('setEventFiles', response.data.meta.files)
      }

      return response
    },
    async fetchEventFields ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'forms',
        'show',
        {
          eventId: payload.id
        },
        {}
      )

      if (response.isSuccessful()) {
        commit('setEventFields', response.data.data)
      }

      return response
    },
    async fetchEventInvitations ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'eventInvitations',
        'list',
        {
          eventId: payload.id
        },
        {
          ...payload.filters
        }
      )

      if (response.isSuccessful()) {
        commit('setEventInvitations', response.data.data)
        commit('setEventMeta', response.data.meta)
      }

      return response
    },
    async fetchEventParticipations ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'eventParticipations',
        'show',
        {
          eventId: payload.id
        },
        {
          ...payload.filters
        }
      )

      if (response.isSuccessful() && !payload.skipStore) {
        commit('setEventParticipations', response.data.data)
        commit('setEventMeta', response.data.meta)
      }

      return response
    },
    async createSingleEvent ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'events',
        'create',
        {},
        payload.data
      )

      if (response.isSuccessful()) {
        commit('setSingleEvent', response.data.data)
      }

      return response
    },
    async createEventInvitationsLink ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'eventInvitationsLinks',
        'create',
        { eventId: payload.params.id },
        payload.data
      )

      return response
    },
    async updateSingleEvent ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'events',
        'update',
        payload.params,
        payload.data
      )

      return response
    },
    async uploadEventMedia ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const data: FormData = payload.data

      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'events',
        'upload',
        payload.params,
        data,
        { 'content-type': 'application/x-www-form-urlencoded' }
      )

      if (response.isSuccessful()) {
        const file = fileAdapter.model<IFile>(response.data.data)
        commit('updateEventFiles', file)
        return file
      }

      return false
    },
    async removeEventMedia ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'files',
        'delete',
        payload
      )

      if (response.isSuccessful()) {
        commit('removeEventFiles', payload.id)
      }
    },
    async resentInvitationsMail ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const toaster: IToaster = container.get(ToasterType)
      const response: IResponse = await resources.call(
        'eventInvitations',
        'resent',
        { eventId: payload.id },
        payload.filters
      )

      if (response.isSuccessful()) {
        toaster.success('Zaproszenia zostały wysłane.')

        commit('setEventInvitations', response.data.data)
        commit('setEventMeta', response.data.meta)
      } else {
        toaster.error('Coś poszło nie tak.')
      }

      return response
    },
    async updateEventFields ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'forms',
        'update',
        {
          eventId: payload.id
        },
        {
          form_fields: payload.data
        }
      )

      if (response.isSuccessful()) {
        commit('setEventFields', response.data.data)
      }

      return response
    },
    async updateEventParticipants ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'eventParticipations',
        'update',
        {
          eventId: payload.id
        },
        {
          form_fields: payload.data
        }
      )

      if (response.isSuccessful()) {
        commit('setEventParticipations', response.data.data)
      }

      return response
    },
    async createEventFields ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'forms',
        'create',
        payload.params,
        payload.data,
        { 'content-type': 'multipart/form-data' }
      )

      if (response.isSuccessful()) {
        commit('setSingleEvent', response.data.data)
      }

      return response
    },
    async deleteSingleEvent ({ dispatch }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'events',
        'delete',
        payload.params,
        payload.data
      )

      if (response.isSuccessful()) {
        await dispatch('fetchEventsList', { data: payload.query })
      }

      if (response.status === 403) {
        const toaster: IToaster = container.get(ToasterType)
        // @ts-ignore
        toaster.error(response.errors.message)
      }

      return response
    },
    resetSingleEventState ({ commit }: ActionContext<State, any>) {
      commit('setSingleEvent', initialState())
    }
  },
  getters: {
    getMeta: (state: State): Meta => {
      return state.meta
    },
    getEventMeta: (state: State): Meta => {
      return state.eventMeta
    },
    getEvents: (state: State): Event[] => {
      return state.events
    },
    getEventFields: (state: State): Field[] => {
      return state.eventFields
    },
    getEventFiles: (state: State): IFile[] => {
      return state.eventFiles
    },
    getEventInvitations: (state: State): Invitation[] => {
      return state.eventInvitations
    },
    getEventParticipations: (state: State): Participation[] => {
      return state.eventParticipations
    },
    getSingleEvent: (state: State): Event => {
      return state.eventSingle
    }
  },
  mutations: {
    setEventsList (state: State, collection: Event[]) {
      state.events = eventAdapter.collection<Event>(collection)
    },
    setEventFields (state: State, collection: Field[]) {
      state.eventFields = fieldAdapter.collection<Field>(collection)
    },
    setEventInvitations (state: State, collection: Invitation[]) {
      state.eventInvitations = invitationAdapter.collection<Invitation>(collection)
    },
    setEventParticipations (state: State, collection: Participation[]) {
      state.eventParticipations = participationAdapter.collection<Participation>(collection)
    },
    addEventField (state: State, field: Field) {
      state.eventFields.push(field)
    },
    removeEventFiles (state: State, id: number) {
      const index: number = state.eventFiles.map((file: IFile) => file.id).indexOf(id)
      if (index >= 0) {
        state.eventFiles.splice(index, 1)
      }
    },
    setMeta: (state: State, meta: Meta) => {
      state.meta = meta
    },
    setEventMeta: (state: State, meta: Meta) => {
      state.eventMeta = meta
    },
    setSingleEvent: (state: State, event: Event) => {
      state.eventSingle = eventAdapter.model<Event>(event)
    },
    setEventFiles: (state: State, files: IFile[]) => {
      state.eventFiles = fileAdapter.collection<IFile>(files)
    },
    updateEventFiles: (state: State, file: IFile) => {
      state.eventFiles.push(file)
    }
  }
}

export default eventModuleStore
