
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

import { composeEventRequestPayload } from '@module/events/helpers/compose-event-request-payload'
import { Event } from '@module/events/contracts/models'
import { InvitationsLink } from '@module/invitations-links/contracts/models'
import { IResponse } from '@contract/http'

import { EventFormInvitationsLink } from './event-form-invitations-link.vue'
import { invitationsLinkPayloadMapper } from '@module/invitations-links/adapters/invitationsLink'

@Component({
  name: 'TabInvitationsLink',
  components: { EventFormInvitationsLink }
})
export class TabInvitationsLink extends Vue {
  /**
   * Can edit form.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public isEditable!: boolean

  /**
   * Form name.
   *
   * @type {string}
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Event model.
   */
  @Prop({ type: Object, required: true })
  public model!: Event

  /**
   * Is model exists.
   *
   * @type {string}
   */
  @Prop({ type: Boolean, required: true })
  public modelExist!: boolean;

  /**
   * Form save button text.
   *
   * @type {string}
   */
  @Prop({ type: String, required: false, default: 'Utwórz link' })
  public saveBtnText!: string;

  /**
   * Is form saving.
   *
   * @type {boolean}
   */
  public saving: boolean = false;

  /**
   * Participation model getter.
   *
   * @return {Participation}
   */
  get invitationsLinkModel (): InvitationsLink {
    return this.$store.getters['invitations-links/getInvitationsLink']
  }

  /**
   * Destroyed Vue hook.
   */
  public destroyed (): void {
    this.$store.dispatch('invitations-links/resetSingleInvitationsLinkState')
  }

  /**
   * Submit form handler.
   */
  public async onSubmit (): Promise<void> {
    this.saving = true

    await this.createInvitationsLink()

    this.saving = false
  }

  /**
   * Create event.
   */
  protected async createInvitationsLink (): Promise<void> {
    const response: IResponse = await this.$store.dispatch('events/createEventInvitationsLink', {
      params: {
        id: this.model.id
      },
      data: invitationsLinkPayloadMapper(this.invitationsLinkModel)
    })

    if (response && !response.hasErrors()) {
      await this.$router.push( // if created successfully, redirect to edit form
        { name: 'invitations-links.edit', params: { id: response.data.data.id } }
      )
    }
  }
}
export default TabInvitationsLink
