
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { ISelectOption } from '@contract/components'
import { UiDate } from '@component/form/date.vue'
import { UiInput } from '@component/form/input.vue'
import { UiSelect } from '@component/form/select.vue'
import { UiText } from '@component/form/text.vue'

import { Event } from './../contracts/models'

@Component({
  name: 'EventFormContactFields',
  components: { UiInput, UiSelect, UiText, UiDate }
})
export class EventFormContactFields extends Vue {
  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Form name.
   *
   * @type {string}
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Logical select options.
   *
   * @type {ISelectOption[]}
   */
  @Prop({ type: Array, required: true })
  public logicalSelectOptions!: ISelectOption[]

  /**
   * Event model.
   *
   * @type {Event}
   */
  @PropSync('model', { type: Object, required: true })
  public event!: Event
}

export default EventFormContactFields
