
import 'vue2-datepicker/index.css'
import { Component, Prop } from 'vue-property-decorator'
import DatePicker from 'vue2-datepicker'

import { AbstractInput } from '@component/abstract/input'
import { Datetime } from '@service/datetime'
import { IDateFieldTimePickerOptions } from '@contract/components'

@Component({
  name: 'UiDate',
  components: { DatePicker }
})
export class UiDate extends AbstractInput {
    @Prop({ type: Boolean, required: false, default: true })
    public autocomplete!: Boolean

    @Prop({ type: Boolean, required: false, default: false })
    public autofocus!: Boolean

    @Prop({ type: String, required: false, default: '' })
    public classInput!: String

    @Prop({ type: String, required: false, default: '' })
    public placeholder!: String

    @Prop({ type: Boolean, required: false, default: false })
    public range!: Boolean

    @Prop({ type: String, required: false, default: 'datetime' })
    public type!: String

    @Prop({ type: Object, required: false, default: () => {} })
    public options!: IDateFieldTimePickerOptions

    @Prop({ type: String, required: false, default: 'format' })
    public valueType!: String

    @Prop({ type: String, required: false, default: Datetime.DEFAULT_FORMAT })
    public format!: String

    get classObject () {
      return {
        'form-control': !this.readonly,
        'form-control-plaintext': this.readonly,
        'is-invalid': this.hasErrors
      }
    }
}

export default UiDate
