
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IFilterField } from '@contract/components'
import { Query } from '@contract/http'
import { UiDate } from '@component/form/date.vue'
import { UiInput } from '@component/form/input.vue'
import { UiSelect } from '@component/form/select.vue'

@Component({
  name: 'FilterItem',
  components: { UiDate, UiInput, UiSelect }
})
export class FilterItem extends Vue {
  @Prop({ type: Object, required: true })
  public field!: IFilterField

  @Prop({ type: String, required: true })
  public form!: string

  @Prop({ type: Object, required: true })
  protected queryParams!: Query

  get model (): string|null {
    if (
      !this.queryParams.hasOwnProperty(this.field.name) ||
      typeof this.queryParams[this.field.name] === 'undefined'
    ) {
      return this.field.default
    }

    return this.queryParams[this.field.name] as string
  }

  set model (value: string|null) {
    if (value === null) {
      value = ''
    }

    let data: Query = {}
    data[this.field.name] = value

    this.$emit('changed', { ...this.queryParams, ...data })
  }
}

export default FilterItem
