
import { Component, Prop, Vue } from 'vue-property-decorator'

import { UiInput } from '@component/form/input.vue'
import { UiText } from '@component/form/text.vue'

import { Action } from '@module/actions/contracts/models'

@Component({
  name: 'SMSNotifyForm',
  components: {
    UiInput,
    UiText
  }
})
export class SMSNotifyForm extends Vue {
  /**
   * Can edit forms.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public isEditable!: boolean

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Get event model.
   */
  public get model (): Action {
    return this.$store.getters['actions/getAction']
  }

  public addNewPhoneNumber (): void {
    if (this.model.handleFields.phone_numbers) {
      this.model.handleFields.phone_numbers.push('')
    }
  }

  public deletePhoneNumber (index: number): void {
    if (this.model.handleFields.phone_numbers) {
      this.model.handleFields.phone_numbers.splice(index, 1)
    }
  }
}

export default SMSNotifyForm
