import { BehaviorSubject } from 'rxjs'
import { injectable } from 'inversify'
import { VueConstructor } from 'vue'

import { ModalPayload, ModalRegistry, ModalState } from '@contract/modal'

/**
 * Http is service class that provides http functionality.
 *
 * @author  Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author  Kuba Fogel <kuba.fogel@movecloser.pl>
 * @version 1.0.1
 */
@injectable()
export class ModalConnector {
  protected _registry!: ModalRegistry
  protected _state!: ModalState
  protected _stream$: BehaviorSubject<ModalState>

  constructor (registry: ModalRegistry) {
    this._registry = registry
    this._stream$ = new BehaviorSubject<ModalState>({
      component: null,
      opened: false,
      payload: {},
      size: ''
    })

    this._stream$.subscribe((state: ModalState) => {
      this._state = state
    })
  }

  close (key: string|null = null): void {
    if (key === null || !key.length || this._state.component === key) {
      this._stream$.next({
        component: null,
        opened: false,
        payload: {},
        size: ''
      })
    }
  }

  get component (): VueConstructor {
    if (this._state.component === null) {
      throw new Error('Modal is not opened. Check if [isOpened] before calling for modal component.')
    }

    if (!this._registry.hasOwnProperty(this._state.component)) {
      throw new Error(`Unregistered modal component [${this._state.component}]`)
    }

    return this._registry[this._state.component]
  }

  get isOpened (): boolean {
    return this._state.opened && this._state.component !== null
  }

  get name (): string|null {
    return this._state.component
  }

  open (key: string, payload: ModalPayload = {}, size = ''): void {
    if (!this._registry.hasOwnProperty(key)) {
      throw new Error(`Unregistered modal component [${key}]`)
    }

    this._stream$.next({
      component: key,
      opened: true,
      payload: payload,
      size: size
    })
  }

  openAsync (key: string, promise: Promise<any>, payload: ModalPayload = {}, size = ''): void {
    if (!this._registry.hasOwnProperty(key)) {
      throw new Error(`Unregistered modal component [${key}]`)
    }

    promise.then(() => {
      this._stream$.next({
        component: key,
        opened: true,
        payload: payload,
        size: size
      })
    })
  }

  get payload (): ModalPayload {
    return this._state.payload
  }

  subscribe (callback: (open: ModalState) => any): void {
    this._stream$.subscribe((state: ModalState) => {
      callback(state)
    })
  }
}

export default ModalConnector
