/**
 * Focus directive for fields
 */
export default {
  bind (el: any, binding: any) {
    if (binding.value === true) {
      el.focus()
    }
  },
  update (el: any, binding: any) {
    if (binding.value === true) {
      el.focus()
    }
  }
}
