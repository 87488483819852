
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { ISelectOption } from '@contract/components'
import { UiInput } from '@component/form/input.vue'
import { UiSelect } from '@component/form/select.vue'

import { Field, FieldType } from './../../contracts/models'

@Component({
  name: 'FieldBasicSettings',
  components: {
    UiInput,
    UiSelect
  }
})
export class FieldBasicSettings extends Vue {
  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string

  /**
   * Logical select options.
   */
  @Prop({ required: true })
  public logicalSelectOptions!: ISelectOption[]

  /**
   * Field model.
   */
  @Prop({ required: true })
  public model!: Field

  public get isBooleanFieldType (): boolean {
    return this.model.type === FieldType.Boolean
  }

  public get labelForFieldByType () : string {
    if (this.isBooleanFieldType) {
      return 'Treść pola'
    }

    return 'Nazwa pola'
  }

  public get helperForFieldByType () : string {
    if (this.isBooleanFieldType) {
      return 'Treśc pola będzie wyświetlana obok pola.'
    }

    return 'Nazwa pola będzie wyświetlana ponad polem w formularzu wydarzenia.'
  }
}

export default FieldBasicSettings
