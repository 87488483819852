import { Adapter, MappingConfig, MappingTypes } from '@support/adapter'
import { Payload } from '@contract/http'

import { eventAdapter } from '@module/events/adapters/event'
import { participationAdapter } from '@module/participations/adapters/participation'

import { Invitation, InvitationToImport } from '../contracts/models'

class InvitationAdapter extends Adapter {
  mapping: MappingConfig = {
    id: 'id',
    key: 'key',
    email: 'email',
    locale: 'locale',
    status: 'status',
    tags: 'tags',
    retryCount: 'retry_count',
    event: {
      type: MappingTypes.Adapter,
      value: 'event',
      adapter: eventAdapter
    },
    participation: {
      type: MappingTypes.Adapter,
      value: 'participation',
      adapter: participationAdapter
    },
    createdAt: 'created_at',
    updatedAt: 'updated_at'
  }
  preserve: boolean = false
}
export const invitationAdapter = new InvitationAdapter()

export const invitationPayloadMapper = (model: Invitation): Payload => {
  return {
    'email': model.email,
    'locale': model.locale,
    'status': model.status,
    'tags': model.tags
  }
}

export const invitationImportPayloadMapper = (collection: InvitationToImport[]): Payload => {
  return collection.map((invitation :InvitationToImport) => {
    return {
      'id': invitation.id,
      'event_id': invitation.eventId,
      'email': invitation.email,
      'locale': invitation.locale,
      'tags': invitation.tags
    }
  })
}

export default {
  invitationAdapter: invitationAdapter,
  invitationMapper: invitationPayloadMapper
}
