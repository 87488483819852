import { ActionContext } from 'vuex'

import { container } from '@/bootstrap/app'
import { IResponse, Payload } from '@contract/http'
import { IResources, ResourcesType } from '@contract/resources'

import { invitationsLinkAdapter } from './adapters/invitationsLink'
import { InvitationsLink, Meta, State } from './contracts/models'

export const invitationsLinksModuleStore = {
  namespaced: true,
  state: {
    invitationsLinks: [],
    invitationsLinkSingle: {
      event: {}
    },
    meta: {
      total: 0
    }
  },
  actions: {
    async deleteSingleInvitationsLink ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'invitationsLinks',
        'delete',
        { invitationsLinkId: payload.id },
        {}
      )

      if (!response.hasErrors()) {
        commit('removeSingleInvitationsLink', payload.id)
      }

      return response
    },
    async fetchInvitationsLinksList ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'invitationsLinks',
        'list',
        {},
        payload
      )

      if (response.isSuccessful()) {
        commit('setInvitationsLinksList', response.data.data)
        commit('setMeta', response.data.meta)
      }

      return response
    },
    async fetchSingleInvitationsLink ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'invitationsLinks',
        'show',
        { invitationsLinkId: payload.id },
        {}
      )

      if (!response.hasErrors()) {
        commit('setSingleInvitationsLink', response.data.data)
      }

      return response
    },
    async sendInvitationsLink ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'invitationsLinks',
        'send',
        { invitationsLinkId: payload.id },
        {}
      )

      return response
    },
    async toggleInvitationsLinkStatus ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'invitationsLinks',
        'activation',
        { invitationsLinkId: payload.id },
        payload.params
      )

      if (!response.hasErrors()) {
        commit('updateInvitationsLink', response.data.data)
      }

      return response
    },
    async updateSingleInvitationsLink ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'invitationsLinks',
        'update',
        { invitationsLinkId: payload.params.id },
        payload.data
      )

      if (!response.hasErrors()) {
        commit('setSingleInvitationsLink', response.data.data)
      }

      return response
    },
    async updateInvitationsLinkTags ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'invitationsLinks',
        'updateTags',
        { invitationsLinkId: payload.params.id },
        payload.data
      )

      if (!response.hasErrors()) {
        commit('setSingleInvitationsLink', response.data.data)
      }

      return response
    },
    async resetSingleInvitationsLinkState ({ commit }: ActionContext<State, any>) {
      commit('setSingleInvitationsLink', {
        event: {}
      })
    }
  },
  getters: {
    getMeta: (state: State): Meta => {
      return state.meta
    },
    getInvitationsLinksList: (state: State): InvitationsLink[] => {
      return state.invitationsLinks
    },
    getInvitationsLink: (state: State): InvitationsLink => {
      return state.invitationsLinkSingle
    }
  },
  mutations: {
    removeSingleInvitationsLink: (state: State, id: number) => {
      const flatInvitationsLinksList = state.invitationsLinks.map((invitationsLink: InvitationsLink) => invitationsLink.id)
      const index = flatInvitationsLinksList.indexOf(id)
      if (index !== -1) {
        state.invitationsLinks.splice(index, 1)
      }
    },
    updateInvitationsLink: (state: State, invitationsLink: InvitationsLink) => {
      const invitationsLinkModel: InvitationsLink = invitationsLinkAdapter.model<InvitationsLink>(invitationsLink)
      const flatInvitationsLinksList = state.invitationsLinks.map((invitationsLink: InvitationsLink) => invitationsLink.id)
      const index = flatInvitationsLinksList.indexOf(invitationsLinkModel.id)
      if (index !== -1) {
        state.invitationsLinks.splice(index, 1, invitationsLinkModel)
      }
    },
    setInvitationsLinksList (state: State, collection: InvitationsLink[]) {
      state.invitationsLinks = invitationsLinkAdapter.collection<InvitationsLink>(collection)
    },
    setSingleInvitationsLink: (state: State, invitationsLink: InvitationsLink) => {
      state.invitationsLinkSingle = invitationsLinkAdapter.model<InvitationsLink>(invitationsLink)
    },
    setMeta: (state: State, meta: Meta) => {
      state.meta = meta
    }
  }
}

export default invitationsLinksModuleStore
