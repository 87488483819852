import { ActionContext } from 'vuex'

import { container } from '@/bootstrap/app'
import { IResources, ResourcesType } from '@contract/resources'
import { IResponse, Payload } from '@contract/http'

import { State } from './contracts/models'

export const rootState = {
  namespaced: true,
  state: {
    prices: []
  },
  actions: {
    async loadInit ({ commit }: ActionContext<State, any>, payload: Payload) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('settings', 'init', {}, payload)

      if (response.isSuccessful()) {
        commit('initLoaded', response.data)
        return true
      }

      return false
    }
  },
  getters: {
    getPrices: (state: State): number[] => {
      return state.prices
    }
  },
  mutations: {
    initLoaded: (state: State, init: any) => {
      state.prices = init.prices
    }
  }
}
