
import { Component, Vue } from 'vue-property-decorator'

import Block from '@component/block.vue'
import { Breadcrumbs } from '@component/breadcrumbs.vue'
import { IResponse } from '@contract/http'
import { ViewContent } from '@view/partials/content.vue'

import { AccessableComponent } from '@module/auth/shared/access-helpers'

import { rolePayloadMapper } from '@module/users/adapters/role'
import { Role } from '@module/users/contracts/models'
import { RoleForm } from '@module/users/components/role-form.vue'

@Component({
  name: 'SingleRole',
  components: { Block, Breadcrumbs, RoleForm, ViewContent },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch('users/fetchRoleSingle',
        { params: { roleId: vm.$route.params.id } }
      )
    })
  }
})
export class EditRole extends AccessableComponent {
  protected scope: string = 'users'

  /**
   * Is saving in progress.
   */
  public saving: boolean = false;

  /**
   * Form save button text..
   */
  public saveBtnText: string = 'Zapisz role';

  /**
   * Form name.
   */
  public formName: string = 'editRoles';
  /**
   * Breadcrumbs data getter.
   *
   * @return {object[]}
   */
  get breadcrumbs (): object[] {
    return [
      {
        label: `${this.model && this.model.name ? this.model.name : ''}`
      }
    ]
  }

  /**
   * Role model getter.
   *
   * @return {Role}
   */
  get model (): Role {
    return this.$store.getters['users/getRoleSingle']
  }

  /**
   * Destroyed Vue hook.
   */
  public destroyed (): void {
    this.$store.dispatch('users/resetRoleSingleState')
  }

  /**
   * Update user action.
   *
   * @return {Promise<IResponse>}
   */
  public updateRole (): Promise<IResponse> {
    return this.$store.dispatch('users/updateRoleSingle', {
      params: { roleId: this.$route.params.id }, data: rolePayloadMapper(this.model)
    })
  }

  /**
   * Submit form handler.
   */
  public onSubmitHandler (): void {
    this.saving = true

    this.updateRole().finally(() => {
      this.saving = false
    })
  }
}

export default EditRole
