import { AppModule, Module } from '@support/modules'
import { rootState } from './store'
import Home from './views/home.vue'

@AppModule({
  name: 'root',
  routes: [
    {
      path: '/',
      component: Home
    }
  ],
  state: rootState
})
export class RootModule extends Module {}
