import { ActionTrigger, ActionTriggerFields } from '../contracts/models'

const participationVisitedTagMapper = (triggerFields: any): ActionTriggerFields => {
  return {
    tags: triggerFields && triggerFields.tags ? triggerFields.tags : []
  }
}

export const triggerFieldsMapper = (action: any): any => {
  switch (action.trigger) {
    case ActionTrigger.PARTICIPATION_VISITED_TAG: return participationVisitedTagMapper(action.trigger_fields)
  }
}

export default triggerFieldsMapper
