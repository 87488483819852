import { Action } from '../contracts/models'
import { Adapter, MappingConfig, MappingTypes } from '@support/adapter'
import { handleFieldsMapper } from './handleFields'
import { triggerFieldsMapper } from './triggerFields'
import { Payload } from '@contract/http'

class ActionAdapter extends Adapter {
  /**
   * Adapter mapping types.
   *
   * @type {MappingConfig}
   */
  mapping: MappingConfig = {
    id: 'id',
    title: 'title',
    handle: 'handle',
    trigger: 'trigger',
    handleFields: {
      type: MappingTypes.Function,
      value: (action: any) => handleFieldsMapper(action)
    },
    triggerFields: {
      type: MappingTypes.Function,
      value: (action: any) => triggerFieldsMapper(action)
    },
    createdAt: 'created_at',
    updatedAt: 'updated_at'
  }
  preserve: boolean = false
}

export const actionPayloadMapper = (model: Action): Payload => {
  return {
    title: model.title,
    handle: model.handle,
    trigger: model.trigger,
    handle_fields: model.handleFields,
    trigger_fields: model.triggerFields
  }
}

export const actionsAdapter = new ActionAdapter()
