
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

import { composeEventRequestPayload } from '@module/events/helpers/compose-event-request-payload'
import { Event } from '@module/events/contracts/models'
import { IResponse } from '@contract/http'

import { EventForm } from './event-form.vue'
import { eventPayloadMapper } from '@module/events/adapters/event'

@Component({
  name: 'TabDetails',
  components: { EventForm }
})
export class TabDetails extends Vue {
  /**
   * Form name.
   *
   * @type {string}
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Event model.
   */
  @Prop({ type: Object, required: true })
  public model!: Event

  /**
   * Form name.
   *
   * @type {string}
   */
  @Prop({ type: Boolean, required: true })
  public modelExist!: boolean;

  /**
   * Form save button text.
   *
   * @type {string}
   */
  @Prop({ type: String, required: false, default: 'Zapisz zmiany' })
  public saveBtnText!: string;

  /**
   * Is form saving.
   *
   * @type {boolean}
   */
  public saving: boolean = false;

  /**
   * Submit form handler.
   */
  public async onSubmit (): Promise<void> {
    this.saving = true

    if (this.modelExist) {
      await this.updateEvent()
    } else {
      await this.createEvent()
    }

    this.saving = false
  }

  /**
   * Create event.
   */
  protected async createEvent (): Promise<void> {
    const response: IResponse = await this.$store.dispatch('events/createSingleEvent', {
      params: {},
      data: eventPayloadMapper(this.model)
    })

    if (response && !response.hasErrors()) {
      await this.$router.push( // if created successfully, redirect to edit form
        { name: 'events.edit', params: { id: response.data.data.id } }
      )
    }
  }

  /**
   * Update event.
   */
  protected async updateEvent () : Promise<void> {
    await this.$store.dispatch('events/updateSingleEvent', {
      params: { eventId: this.$route.params.id },
      data: eventPayloadMapper(this.model)
    })
  }
}
export default TabDetails
