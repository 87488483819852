<template>
  <li class="nav-item" :class="{ active: isActive, disabled: isDisabled }">
    <a role="link" class="nav-link" @click="performClick">
      <component :is="item.title.component" v-bind="item.title.props" v-if="hasComponent(item)" />
      <span v-if="!hasComponent(item)">{{ item.title }}</span>
    </a>
  </li>
</template>

<script>
/* global window */
export default {
  name: 'NavItem',
  props: {
    active: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    isActive () {
      return this.active === this.index
    }
  },
  methods: {
    hasComponent (item) {
      return Object.prototype.hasOwnProperty.call(item.title, 'component')
    },
    performClick () {
      if (this.isDisabled) {
        return
      }

      if (typeof this.item.url === 'string') {
        window.location = this.item.url
      }

      this.$emit('update:active', this.index)
    }
  }
}
</script>
