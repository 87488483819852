
import { Component, Vue } from 'vue-property-decorator'

import Block from '@component/block.vue'
import { Breadcrumbs } from '@component/breadcrumbs.vue'
import { IResponse } from '@contract/http'
import { ViewContent } from '@view/partials/content.vue'

import { AccessableComponent } from '@module/auth/shared/access-helpers'

import { userPayloadMapper } from '../../adapters/user'
import { Role, User } from '../../contracts/models'
import { UserForm } from '@module/users/components/user-form.vue'

@Component({
  name: 'SingleUser',
  components: { Block, Breadcrumbs, UserForm, ViewContent },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch('users/fetchUserSingle',
        { params: { userId: vm.$route.params.id } }
      )
    })
  }
})
export class EditUser extends AccessableComponent {
  protected scope: string = 'users'
  /**
   * Is saving in progress.
   */
  public saving: boolean = false;

  /**
   * Form save button text..
   */
  public saveBtnText: string = 'Zapisz użytkownika';

  /**
   * Form name.
   */
  public formName: string = 'createUsers';
  /**
   * Breadcrumbs data getter.
   *
   * @return {object[]}
   */
  get breadcrumbs (): object[] {
    return [
      {
        label: `${this.model && this.model.firstName
          ? this.model.firstName + ' ' + this.model.lastName
          : ''}`
      }
    ]
  }

  /**
   * User model getter.
   *
   * @return {User}
   */
  get model (): User {
    return this.$store.getters['users/getUserSingle']
  }

  /**
   * Destroyed Vue hook.
   */
  public destroyed (): void {
    this.$store.dispatch('users/resetUserSingleState')
  }

  /**
   * Update user action.
   *
   * @return {Promise<IResponse>}
   */
  public updateUser (): Promise<IResponse> {
    return this.$store.dispatch('users/updateUserSingle', {
      params: { userId: this.$route.params.id }, data: userPayloadMapper(this.model)
    })
  }

  /**
   * Submit form handler.
   */
  public onSubmitHandler (): void {
    this.saving = true

    this.updateUser().finally(() => {
      this.saving = false
    })
  }
}

export default EditUser
