
import { Component, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { AuthorizationType } from '@contract/resources'
import { enterKey, IEnterEvent } from '@ext/events'
import EventBus from '@service/eventbus'
import FormInput from '@component/form/input.vue'
import StateButton from '@component/button/state.vue'

import { IAuthorization } from '../contracts/services'

@Component({
  name: 'ForgetPage',
  components: { FormInput, StateButton }
})
export class ForgetPage extends Vue implements IEnterEvent {
  @Inject(AuthorizationType)
  protected authorization!: IAuthorization
  protected listener = enterKey.bind(this)

  public formName: string = 'forgotForm'
  public email: string = ''
  public sending: boolean = false
  public sent: boolean = false

  mounted () {
    window.addEventListener('keyup', this.listener)

    EventBus.$on('forgotForm.success', () => {
      this.sent = true
    })
  }

  destroyed () {
    window.removeEventListener('keyup', this.listener)
  }

  sendResetLink () {
    if (this.email) {
      this.sending = true

      this.authorization.forgotPassword(this.email).then((result: boolean) => {
        if (result) {
          this.sent = true
        }

        this.sending = false
      })
    }
  }

  enterPressed () {
    this.sendResetLink()
  }
}

export default ForgetPage
