
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

export interface IDuplicatorItem {
  index: number
  label: string
  readonly: boolean
  visible: boolean
  [key: string]: any
}

@Component({
  name: 'DuplicatorItem'
})

export class DuplicatorItem extends Vue {
  @Prop({ required: false, default: false })
  protected disabled!: boolean

  @Prop({ required: true })
  protected index!: number

  @Prop({ required: true })
  protected label!: string

  @Prop({ required: false, default: false })
  protected readonly!: boolean

  @Prop({ required: false, default: false })
  protected toggled!: boolean

  @Prop({ required: false, default: null })
  protected visible!: boolean

  @Prop({ required: false, default: false })
  protected visibility!: boolean

  private clicks: number = 0
  private isOpened: boolean = this.toggled

  toggle (): void {
    this.isOpened = !this.isOpened
  }

  toggleVisibility (): void {
    this.$emit('toggle', {
      index: this.index,
      visible: !this.visible
    })
  }

  remove (): void {
    this.clicks++

    setTimeout(() => {
      this.clicks = 0
    }, 1000)
  }

  removed (): void {
    this.$emit('remove', this.index)
  }

  @Watch('clicks')
  onClicksChanged () {
    if (this.clicks === 2) {
      this.removed()
    }
  }
}

export default DuplicatorItem
