import { ResourceResponseMeta } from '@contract/resources'

export interface Permission {
  level: number,
  scope: PermissionScope
}

export enum PermissionScope {
  Event = 'events',
  Files = 'files',
  Participations = 'participations',
  Users = 'users'
}

export interface State {
  roles: Role[],
  roleSingle: Role,
  users: User[],
  userSingle: User,
  meta: ResourceResponseMeta
}

export { ResourceResponseMeta as Meta } from '@contract/resources'

export interface Role {
  id: number
  name: UserRole,
  level: number,
  permissions: Permission[]
}

export interface User {
  id: number,
  firstName: string,
  lastName: string,
  email: string
  role: string,
  roles: number[]
  createdAt: string,
  updatedAt: string
}

export enum UserRole {
  Administrator = 'administrator',
  Manager = 'manager',
  Coordinator = 'coordinator'
}
