
import { Component, Prop, Vue } from 'vue-property-decorator'

import { Badge } from '@component/badge.vue'
import { ModelActions } from '@component/models/actions.vue'

import { Field, FieldType } from '../contracts/models'
import { fieldTypeLabel } from '../helpers/field-type-label'

@Component({
  name: 'FieldRow',
  components: { Badge, ModelActions }
})
export class FieldRow extends Vue {
  /**
   * Event row index.
   *
   * @type {number}
   */
  @Prop({ required: true })
  public index!: number

  /**
   * Can edit row
   *
   * @type {number}
   */
  @Prop({ required: false })
  public canEdit!: Boolean

  /**
  * Event model.
  *
  * @type {number}
  */
  @Prop({ required: true })
  public model!: Field

  /**
   * Delete event action.
   */
  public deleteSingleItem (): void {
    this.$store.dispatch('fields/deleteSingleField', {
      params: {
        fieldId: this.model.id
      },
      query: {
        ...this.$route.query
      }
    })
  }

  /**
   * Get event status label.
   */
  public fieldTypeLabel (value: FieldType): string {
    return fieldTypeLabel(value)
  }
}

export default FieldRow
