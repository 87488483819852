
import { Component, Prop, Vue } from 'vue-property-decorator'

import { EventParticipations } from './event-participations.vue'
import { Participation } from '@module/participations/contracts/models'

@Component({
  name: 'TabParticipations',
  components: { EventParticipations }
})
export class TabParticipations extends Vue {
  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Event fields collection getter.
   *
   * @return {Participation}
   */
  get collection (): Participation[] {
    return [...this.$store.getters['events/getEventParticipations']]
  }
}
export default TabParticipations
