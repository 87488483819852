
import saveAs from 'file-saver'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { AuthorizationType } from '@contract/resources'
import { IAuthorization } from '@module/auth/contracts/services'
import { Datetime } from '@service/datetime'
import { ModelActions } from '@component/models/actions.vue'

import { Participation, ParticipationStatus } from '../contracts/models'
import { participationStatusLabel } from '@module/participations/helpers/participation-status-label'

@Component({
  name: 'ParticipationRow',
  components: { ModelActions }
})
export class ParticipationRow extends Vue {
  @Inject(AuthorizationType)
  protected _authorization!: IAuthorization

  protected scope: string = 'events'

  public canView (): boolean {
    return this._authorization.hasPermission(this.scope, 1)
  }
  /**
   * Event row index.
   *
   * @type {number}
   */
  @Prop({ required: true })
  public index!: number

  /**
   * Can edit row
   *
   * @type {number}
   */
  @Prop({ required: false })
  public canEdit!: Boolean

  /**
  * Event model.
  *
  * @type {number}
  */
  @Prop({ required: true })
  public model!: Participation

  /**
   * Resources per page.
   */
  public static perPage: number = 12

  /**
   * Is exporting participation ticket.
   *
   * @type {boolean}
   */
  public exportingTicket: boolean = false

  /**
   * Creation date.
   *
   * @type {number}
   */
  get createdDate (): string {
    const datetime = new Datetime()
    return datetime.parseToFormat(this.model.createdAt, 'DD.MM.YYYY hh:mm')
  }

  /**
   * Get participation status label.
   */
  public participationStatusLabel (value: ParticipationStatus): { label: string, class: string } {
    return participationStatusLabel(value)
  }

  /**
   * Delete event action.
   */
  public deleteSingleItem (): void {
    this.$store.dispatch('participations/deleteSingleParticipation', {
      id: this.model.id
    })
  }

  public exportParticipantTicket (): void {
    this.exportingTicket = true

    this.$store.dispatch(
      'participations/exportTicket',
      {
        id: this.model.id
      }
    ).then((response: string|null) => {
      if (response !== null) {
        // @ts-ignore
        saveAs(
          response,
          `papaya-social-${this.model.firstName.toLowerCase()}-${this.model.lastName.toLowerCase()}.pdf`,
          { autoBom: true }
        )
      }
    }).finally(() => {
      this.exportingTicket = false
    })
  }
}

export default ParticipationRow
