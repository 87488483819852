import { Payload } from '@contract/http'

export interface IModelRepository extends IRepository {
  delete (id: string|number): Promise<any>
  loadCollection (filters?: Payload|null): Promise<any>
  loadModel (id: string|number): Promise<any>
  store (payload: Payload): Promise<any>
  update (id: string|number, payload: Payload): Promise<any>
}

export interface INestedRepository extends IRepository {
  delete (parent: string|number, id: string|number): Promise<any>
  loadCollection (parent: string|number, filters: Payload|null): Promise<any>
  loadModel (parent: string|number, id: string|number): Promise<any>
  store (parent: string|number, payload: Payload): Promise<any>
  update (parent: string|number, id: string|number, payload: Payload): Promise<any>
}

export interface IRepository {
  collection: object[]
  meta: object|boolean
  model: object|boolean
}

export enum RepositoryAction {
  Collection = 'loadCollection',
  Delete = 'deleteModel',
  Model = 'loadModel',
  Store = 'storeModel',
  Update = 'updateModel'
}
export enum RepositoryGetter {
  Collection = 'collection',
  Meta = 'meta',
  Model = 'model'
}

export interface RepositoryConfig {
  collection?: string
  deleteModel?: string
  loadCollection?: string
  loadModel?: string
  meta?: string
  model?: string
  storeModel?: string
  updateModel?: string
}

export type RepositoryConstructor = new() => IModelRepository|INestedRepository
