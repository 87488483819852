import { AppModule, Module } from '@support/modules'

import { usersModuleStore } from './store'
import { usersModuleRoutes } from './routes'

@AppModule({
  name: 'users',
  routes: usersModuleRoutes,
  state: usersModuleStore
})
export class UsersModule extends Module {}
export default UsersModule
