
import { Component, Watch, Vue } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { AccessableComponent } from '@module/auth/shared/access-helpers'
import { Query, IResponse } from '@contract/http'

import { Breadcrumbs } from '@component/breadcrumbs.vue'
import { ModelsList } from '@component/models/list.vue'
import { ViewContent } from '@view/partials/content.vue'

import { Action, Meta } from '../contracts/models'

import { ActionRow } from '../components/action-row.vue'

@Component({
  name: 'ActionsList',
  components: { Breadcrumbs, ModelsList, ViewContent },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch('actions/fetchActionsList',
        { page: 1, per_page: ActionsList.perPage, ...vm.$route.query }
      )
    })
  }
})
export class ActionsList extends AccessableComponent {
  protected scope: string = 'events'

  /**
   * Is list loading.
   *
   * @type {boolean}
   */
  public loading: boolean = false

  /**
   * Resources per page.
   */
  public static perPage: number = 12

  /**
   * List row component.
   *
   * @type {VueConstructor}
   */
  public rowComponent: VueConstructor = ActionRow

  /**
   * Get breadcrumbs details.
   *
   * @return {object[]}
   */
  public get breadcrumbs (): object[] {
    return [
      { label: `Lista (${this.actionsTotal})` }
    ]
  }

  /**
   * Actions models collection getter.
   *
   * @return {Action[]}
   */
  public get actions (): Action[] {
    return this.$store.getters['actions/getActionsList']
  }

  /**
   * Get actions total count.
   *
   * @return {number}
   */
  public get actionsTotal (): number {
    if (!this.meta || !this.meta.hasOwnProperty('total') || typeof this.meta.total === 'undefined') {
      return 0
    }

    return this.meta.total
  }

  /**
   * Actions collection meta data.
   *
   * @return {Meta}
   */
  public get meta (): Meta {
    return this.$store.getters['actions/getMeta']
  }

  /**
   * Get current page.
   *
   * @return {number}
   */
  public get page (): number {
    if (this.$route.query.hasOwnProperty('page') && this.$route.query.page != null) {
      return parseInt(this.$route.query.page as string, 10)
    }

    return this.meta.current_page || 1
  }

  /**
   * Get query params.
   *
   * @return {Query}
   */
  public get queryParams (): Query {
    return {
      ...this.$route.query
    }
  }

  /**
   * Load invitations links collection.
   *
   * @param {object} params
   */
  public loadCollection (params: object): Promise<IResponse> {
    return this.$store.dispatch('actions/fetchActionsList', {
      page: 1,
      per_page: ActionsList.perPage,
      sort: 'id',
      ...params
    })
  }

  /**
   * Set page and change it.
   *
   * @param {number} page
   */
  public setPage (page: number): void {
    if (this.page !== page) {
      this.$router.push({ name: 'actions.list', query: { ...this.queryParams, page: `${page}` } })
    }
  }

  /**
   * Watch page change and re-load collection.
   *
   * @param {Query} query
   */
  @Watch('queryParams', { deep: true })
  public onPageChange (query: Query): void {
    this.loading = true

    this.loadCollection(query).then((response: IResponse) => {
      if (response) {
        this.loading = false
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        this.$router.back()
      }
    })
  }
}

export default ActionsList
