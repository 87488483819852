
import { Component, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { AuthorizationType } from '@contract/resources'
import { sidebarItems } from '@/config/sidebar'
import { SidebarItem } from '@contract/sidebar'
import { UiModal } from '@component/modal.vue'

import { IAuthorization } from '@module/auth/contracts/services'
import { MeRepository, MeRepositoryType } from '@module/auth/repositories/me'

import HeaderDropdown from './partials/header-dropdown.vue'
import NavItem from './partials/nav-item.vue'

@Component({
  name: 'App',
  components: { HeaderDropdown, UiModal, NavItem }
})
export default class App extends Vue {
  @Inject(AuthorizationType)
  protected _authorization!: IAuthorization

  @Inject(MeRepositoryType)
  protected _meRepository!: MeRepository
  protected showSideBar: boolean = false

  get availableItems (): SidebarItem[] {
    return sidebarItems
  }

  get me (): object {
    return this._meRepository.model
  }

  get isSidebarVisible (): boolean {
    return this.showSideBar
  }

  hideSidebar (): void {
    this.showSideBar = false
  }

  logout (): void {
    this._authorization.logout().then((result: boolean) => {
      if (result) {
        this.$router.push('/login')
      }
    })
  }

  openSideBar (): void {
    this.showSideBar = true
  }
}
