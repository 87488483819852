
import { Component, Prop, Vue } from 'vue-property-decorator'
import { UiInput } from '../../../shared/components/form/input.vue'
import { ISelectOption } from '@contract/components'

@Component({
  name: 'EventFormAdditionalInfo',
  components: { UiInput }
})

export class EventFormAdditionalInfo extends Vue {
  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  @Prop({ type: Array, required: true })
  public infos!: string[]

  @Prop({ type: String, required: true })
  public label!: string

  @Prop({ type: String, required: true })
  public formName!: string

  public newInfo: string = ''

  public addNewInfo (): void {
    this.infos.push(this.newInfo)
    this.newInfo = ''
  }

  public removeInfo (index: number): void {
    this.infos.splice(index, 1)
  }
}
export default EventFormAdditionalInfo
