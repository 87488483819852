
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { Subject, Subscription } from 'rxjs'

import { Tag } from '@module/participations/contracts/models'

import { UiInput } from '@component/form/input.vue'
import { UiStateButton } from '@component/button/state.vue'

import { Action } from '../../contracts/models'

@Component({
  name: 'ParticipationWithTagForm',
  components: {
    UiInput,
    UiStateButton
  }
})
export class ParticipationWithTagForm extends Vue {
  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Can edit model.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public isEditable!: boolean

  /**
   * Search for tag label
   */
  public loadingTags: boolean = false

  /**
   * Search for tag label
   */
  public search: string = ''

  /**
   * RxJS subscription object.
   *
   * @type {Subscription}
   */
  protected subscription!: Subscription

  /**
   * RxJS subject object.
   *
   * @type {Subject<Query>}
   */
  protected querySubject!: Subject<string>

  /**
   * Check if tag is already added as new tag
   *
   * @return boolean
   */
  public get checkIfTagAlreadyInNew (): boolean {
    if (this.model.triggerFields.tags) {
      return this.model.triggerFields.tags.indexOf(this.search) !== -1
    }
    return false
  }

  /**
   * Get event model.
   */
  public get model (): Action {
    return this.$store.getters['actions/getAction']
  }

  /**
   * Tag models collection getter.
   *
   * @return {Tag[]}
   */
  public get tags (): Tag[] {
    return this.$store.getters['participations/getTagsList']
  }

  /**
   * Add existing tag
   *
   * @return void
   */
  public addExistingTag (tag: Tag): void {
    if (this.model.triggerFields.tags) {
      this.model.triggerFields.tags.push(tag.label)
    }
  }

  /**
   * Check if tag existing tag is already in use
   *
   * @return boolean
   */
  public checkIfTagAlreadyInExisted (label: string): boolean {
    if (this.model.triggerFields.tags) {
      return this.model.triggerFields.tags.indexOf(label) !== -1
    }
    return false
  }

  /**
   * Created Vue hook.
   */
  public created (): void {
    this.querySubject = new Subject()
    this.subscription = this.querySubject.pipe(
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe((query: string) => this.onTagsSearch(query))
  }

  /**
   * Call to search action
   *
   * @return void
   */
  public async onTagsSearch (query: string) {
    if (query.length) {
      this.loadingTags = true
      await this.$store.dispatch('participations/fetchTagsList', {
        q: query
      })
      this.loadingTags = false
    } else {
      this.$store.dispatch('participations/clearTagsList')
    }
  }

  /**
   * Remove existing tag
   *
   * @return void
   */
  public removeExistingTag (tag: string): void {
    if (this.model.triggerFields.tags) {
      const index = this.model.triggerFields.tags.indexOf(tag)

      if (index !== -1) {
        this.model.triggerFields.tags.splice(index, 1)
      }
    }
  }

  /**
   * search property watcher
   *
   * @param {boolean} isSaving
   */
  @Watch('search', { deep: true })
  public onSearchTagsChange (search: string): void {
    this.querySubject.next(search)
  }
}

export default ParticipationWithTagForm
