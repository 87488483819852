import closableBehaviour from '@ext/closable'
import focus from '@ext/focus'
import striphtmlFilter from '@ext/striphtml'
import trimFilter from '@ext/trim'
import ucfirstFilter from '@ext/ucfirst'
import webAppUrl from '@ext/web-app-url'

export const extentions = {
  filters: {
    striphtml: striphtmlFilter,
    trim: trimFilter,
    ucfirst: ucfirstFilter,
    webAppUrl: webAppUrl
  },
  directives: {
    closable: closableBehaviour,
    focus: focus
  }
}
