
import { Inject } from '@plugin/inversify'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import EventBus from '@service/eventbus'

import { UiDate } from '@component/form/date.vue'
import { UiDuplicator } from '@component/duplicator.vue'
import { UiInput } from '@component/form/input.vue'
import { UiImage } from '@component/image.vue'

import { IValidation, ValidationType } from '@contract/validation'
import { UiText } from '@component/form/text.vue'
import { Event as EventContract, EventFilesPayload, EventMediaPurpose, EventPartnerItem } from './../contracts/models'
import { IFile } from '@contract/components'
import { IModal, ModalType } from '@contract/modal'
import { Subscription } from 'rxjs'

@Component({
  name: 'EventFormImages',
  components: { UiDate, UiDuplicator, UiInput, UiImage, UiText }
})
export class EventFormImages extends Vue {
  @Inject(ModalType)
  protected _connector!: IModal

  @Inject(ValidationType)
  private _validation!: IValidation

  /**
   * Is form saving.
   */
  @Prop({ type: Boolean, required: true })
  public saving!: boolean;

  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Event model.
   */
  @PropSync('model', { type: Object, required: true })
  public event!: EventContract

  /**
   * Single item for duplicator
   */
  public singleItem = {
    label: '',
    items: []
  }

  /**
   * Single item for logos duplicator
   */
  public singleLogoItem: EventPartnerItem = {
    logoId: 0,
    url: ''
  }

  /**
   * Validation subscription
   */
  private _vSubscription!: Subscription

  /**
   * Validation errors
   */
  public errors: string[] = []

  /**
   * Get cover data.
   *
   * @return {IFile}
   */
  public get coverPl (): IFile|void {
    let coverId = this.event.cover.pl

    if (this.event && this.event.files) {
      return this.eventFiles.find((file: IFile) => {
        return (file.id === coverId)
      })
    }
  }

  public get coverEn (): IFile|void {
    let coverId = this.event.cover.en

    if (this.event && this.event.files) {
      return this.eventFiles.find((file: IFile) => {
        return (file.id === coverId)
      })
    }
  }

  public get emailCoverPl (): IFile|void {
    let coverId = this.event.emailCover.pl

    if (this.event && this.event.files) {
      return this.eventFiles.find((file: IFile) => {
        return (file.id === coverId)
      })
    }
  }

  public get emailCoverEn (): IFile|void {
    let coverId = this.event.emailCover.en

    if (this.event && this.event.files) {
      return this.eventFiles.find((file: IFile) => {
        return (file.id === coverId)
      })
    }
  }

  /**
   * Get event files
   *
   * @return {IFile[]}
   */
  public get eventFiles (): IFile[] {
    return this.$store.getters['events/getEventFiles']
  }

  public get partnersImages (): IFile[] {
    return this.$store.getters['events/getEventFiles']
      .filter((file: IFile) => {
        return file.purpose !== 'cover'
      })
  }

  /**
   * Vue created hook
   */
  public created (): void {
    EventBus.$on('imagesAdded', (payload: EventFilesPayload) => {
      const { index, items } = payload
      this.event.partners[index].items = this.mapPartnerImages(items, index)
    })

    this._vSubscription = this._validation.onFormErrors(this.formName, (errors: any[]) => {
      this.errors = errors
    })
  }

  /**
   * Vue destroyed hook
   */
  public destroyed (): void {
    EventBus.$off('imagesAdded')

    this._vSubscription.unsubscribe()
  }

  /**
   * Cover file input change event handler.
   * Emits event and set cover thumbnail.
   */
  public onCoverPlInputChange (evt: Event): void {
    const eventTarget = evt.currentTarget as HTMLInputElement
    const file = this.getFileFromInput(eventTarget)

    if (file) {
      this.$emit('coverChanged', file, EventMediaPurpose.Cover, 'pl')
    }
  }

  /**
   * Cover file input change event handler.
   * Emits event and set cover thumbnail.
   */
  public onCoverEnInputChange (evt: Event): void {
    const eventTarget = evt.currentTarget as HTMLInputElement
    const file = this.getFileFromInput(eventTarget)

    if (file) {
      this.$emit('coverChanged', file, EventMediaPurpose.Cover, 'en')
    }
  }

  /**
   * Cover file input change event handler.
   * Emits event and set cover thumbnail.
   */
  public onEmailCoverPlInputChange (evt: Event): void {
    const eventTarget = evt.currentTarget as HTMLInputElement
    const file = this.getFileFromInput(eventTarget)

    if (file) {
      this.$emit('coverChanged', file, EventMediaPurpose.EmailCover, 'pl')
    }
  }

  /**
   * Cover file input change event handler.
   * Emits event and set cover thumbnail.
   */
  public onEmailCoverEnInputChange (evt: Event): void {
    const eventTarget = evt.currentTarget as HTMLInputElement
    const file = this.getFileFromInput(eventTarget)

    if (file) {
      this.$emit('coverChanged', file, EventMediaPurpose.EmailCover, 'en')
    }
  }

  /**
   * Images add handler
   */
  public onImagesAdd (index: number) {
    this._connector.open('imagesAdd', {
      index: index,
      initialItems: this.event.partners[index].items.map((item: EventPartnerItem) => item.logoId)
    })
  }

  /**
   * Add new partner logo
   */
  public onFileInputChange (evt: Event): void {
    const eventTarget = evt.currentTarget as HTMLInputElement
    const file = this.getFileFromInput(eventTarget)

    if (file) {
      this.$emit('fileChanged', file)
    }
  }

  /**
   * Add new Partner group action
   */
  public onFieldAdd () {
    this.event.partners.push({
      labels: {
        pl: '',
        en: ''
      },
      items: []
    })
  }

  /**
   * Compose array of proper files to display
   */
  public getImagesToDisplay (items: EventPartnerItem[]) {
    return items.map((item: EventPartnerItem) => {
      return this.partnersImages.find((file: IFile) => {
        return item.logoId === file.id
      })
    })
  }

  /**
   * Find image file to display
   */
  public getImageFile (item: EventPartnerItem): IFile | null {
    return this.partnersImages.find((file: IFile) => {
      return item.logoId === file.id
    }) || null
  }

  /**
   * Get file from input.
   *
   * @param {HTMLInputElement} input
   */
  protected getFileFromInput (input: HTMLInputElement): File | false {
    const inputFiles = input.files

    if (inputFiles && inputFiles.length) {
      const file = inputFiles[0]

      if (!file) {
        return false
      }

      return file
    }

    return false
  }

  private mapPartnerImages (items: number[], index: number): EventPartnerItem[] {
    let actualItems: EventPartnerItem[] = this.event.partners[index].items || []
    let newItems: EventPartnerItem[] = actualItems.filter((item: EventPartnerItem) => items.includes(item.logoId))

    items.forEach((item: number) => {
      if (newItems.findIndex((i: EventPartnerItem) => i.logoId === item) === -1) {
        newItems.push({
          logoId: item,
          url: ''
        })
      }
    })

    return newItems
  }
}

export default EventFormImages
