import Vue from 'vue'
import { Inject } from '@plugin/inversify'

import { AuthorizationType } from '@contract/resources'
import { IAuthorization } from '@module/auth/contracts/services'

export abstract class AccessableComponent extends Vue {
  @Inject(AuthorizationType)
  protected _authorization!: IAuthorization

  protected abstract scope: string

  public canEdit (): boolean {
    return this._authorization.hasPermission(this.scope, 4)
  }
}
