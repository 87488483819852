import { AxiosRequestConfig } from 'axios'
import { DriverConfig } from '@contract/http'

export const axios: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_HTTP_BASE,
  headers: {
    'Accept-Language': 'pl-PL',
    'Public-Key': 'dashboard.56616c4726ddcd9b445774b314ab00dc'
  }
}

export const common: DriverConfig = {
  logResponse: Boolean(process.env.VUE_APP_HTTP_LOG),
  simpleLog: Boolean(process.env.VUE_APP_HTTP_SIMPLE_LOG)
}
