
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { ISelectOption } from '@contract/components'
import { UiSelect } from '@component/form/select.vue'

import { Field, FieldType } from '@module/fields/contracts/models'
import { FieldsDetails } from '@module/fields/shared/field-details.vue'
import { IModal, IModalComponent, ModalPayload, ModalType } from '@contract/modal'

@Component({
  name: 'FieldsAddModal',
  components: { UiSelect, FieldsDetails }
})
export class FieldsAddModal extends Vue implements IModalComponent {
  public selectedField: Field|null = null

  @Prop({ required: true })
  public payload!: ModalPayload

  @Inject(ModalType)
  protected _connector!: IModal

  /**
   * Fields already added to event collection getter.
   *
   * @return {Field[]}
   */
  public get existingFields () {
    return this.$store.getters['events/getEventFields']
  }

  /**
   * Vue created lifecycle hook
   */
  public created (): void {
    this.$store.dispatch('fields/fetchFieldsList')
  }

  /**
   * Fields models collection getter.
   *
   * @return {Field[]}
   */
  public get fields (): Field[] {
    return this.$store.getters['fields/getFields']
  }

  /**
   * Map existing fields to select options
   * Filter already added fields from select options
   *
   * @return {Field[]}
   */
  public get fieldsOptions (): any[] {
    let fieldsToMap = this.fields

    // Options filtering
    if (this.existingFields && Array.isArray(this.existingFields)) {
      const existingFields: number[] = this.existingFields.map((field: Field) => field.id)
      fieldsToMap = this.fields.filter((field: Field) => {
        return existingFields.indexOf(field.id) === -1
      })
    }

    // Options mapping
    if (this.fields && Array.isArray(this.fields)) {
      return fieldsToMap.map(field => {
        if (!field.key) {
          field.key = field.name
        }

        if (field.required === null) {
          field.required = 0
        }

        return {
          label: field.name,
          value: field
        }
      })
    }
    return []
  }

  /**
   * Add field do event handler
   */
  public onAddField (): void {
    try {
      this.$store.commit('events/addEventField', this.selectedField)
      this.close()
    } catch (err) {
    }
  }

  /**
   * Close modal.
   *
   */
  public close (): void {
    this._connector.close()
  }
}

export default FieldsAddModal
