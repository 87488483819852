import { AppModule, Module } from '@support/modules'
import { invitationsModuleRoutes } from '@module/invitations/routes'
import { invitationsModuleStore } from '@module/invitations/store'

@AppModule({
  name: 'invitations',
  routes: invitationsModuleRoutes,
  state: invitationsModuleStore
})
export class InvitationsModule extends Module {}
export default InvitationsModule
