
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { ISelectOption } from '@contract/components'
import { UiInput } from '@component/form/input.vue'
import { UiSelect } from '@component/form/select.vue'
import { UiStateButton } from '@component/button/state.vue'

import { Action, ActionHandle, ActionTrigger } from '@module/actions/contracts/models'
import { actionHandleLabel } from '../helpers/action-handle-label'
import { actionTriggerLabel } from '../helpers/action-trigger-label'

@Component({
  name: 'ActionCreateForm',
  components: {
    UiInput,
    UiSelect,
    UiStateButton
  }
})
export class ActionCreateForm extends Vue {
  /**
   * Can edit forms.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public isEditable!: boolean

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Is form saving.
   */
  @Prop({ type: Boolean, required: true })
  public saving!: boolean;

  /**
   * Form save button text.
   */
  @Prop({ type: String, required: false, default: 'Zapisz zmiany' })
  public saveBtnText!: string;

  /**
   * Is saving in progress?
   */
  public savingInProgress: boolean = this.saving;

  /**
   * Action handle select options.
   *
   * @return {ISelectOption[]}
   */
  public get handleSelectOptions (): ISelectOption[] {
    return [
      { value: ActionHandle.SMS_NOTIFY, label: actionHandleLabel(ActionHandle.SMS_NOTIFY) }
    ]
  }

  /**
   * Action trigger select options.
   *
   * @return {ISelectOption[]}
   */
  public get triggerSelectOptions (): ISelectOption[] {
    return [
      { value: ActionTrigger.PARTICIPATION_VISITED_TAG, label: actionTriggerLabel(ActionTrigger.PARTICIPATION_VISITED_TAG) }
    ]
  }

  /**
   * Get event model.
   */
  public get model (): Action {
    return this.$store.getters['actions/getAction']
  }

  /**
   * Submit form handler.
   */
  public submitHandler (): void {
    this.$emit('submit')
  }

  /**
   * Saving prop property watcher.
   *
   * @param {boolean} isSaving
   */
  @Watch('saving', { deep: true })
  public onSavingChange (isSaving: boolean): void {
    this.savingInProgress = isSaving
  }
}

export default ActionCreateForm
