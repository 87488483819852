<template lang="html">
  <div class="element draggable-element">
    <div class="element-inner" :class="{ 'border-primary': isOpened }">
      <div class="element-header">
        <h5 class="title" @click="toggle">
          <i class="fa fa-arrows-alt mr-1" v-handle v-if="!disabled"></i>
          {{ label }} #{{ index + 1 }}
        </h5>

        <div v-if="!disabled">
          <span class="remove" :class="{ clicked: clicks > 0 }" @click="remove">
            <i class="fa fa-trash"></i>
          </span>
        </div>
      </div>
      <div class="element-content" v-show="isOpened">
          <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { ElementMixin, HandleDirective } from 'vue-slicksort'

import DuplicatorItem from './item.vue'

@Component({
  name: 'DuplicatorDraggable',
  extends: DuplicatorItem,
  directives: { handle: HandleDirective },
  mixins: [ElementMixin]
})

export class DuplicatorDraggable extends Vue {
}

export default DuplicatorDraggable
</script>
