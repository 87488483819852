import { AppModule, Module } from '@support/modules'

import { eventModuleStore } from './store'
import { eventModuleRoutes } from './routes'

@AppModule({
  name: 'events',
  routes: eventModuleRoutes,
  state: eventModuleStore
})
export class EventsModule extends Module {}
export default EventsModule
