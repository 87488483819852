
import { VueConstructor } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import Block from '@component/block.vue'
import Pagination from '@component/pagination.vue'
import Table from '@component/table.vue'

@Component({
  name: 'ModelsList',
  components: { Block, Pagination, Table }
})
export class ModelsList extends Vue {
  @Prop({ type: Number, required: false, default: null })
  private actual!: number | null

  @Prop({ type: Boolean, required: false, default: false })
  public hideActions!: boolean

  @Prop({ type: Array, required: true })
  private items!: any[]

  @Prop({ type: Boolean, required: false, default: false })
  public loading!: boolean

  @Prop({ type: Number, required: false, default: 10 })
  private onPage!: number

  @Prop({ type: Boolean, required: false, default: true })
  private paginate!: boolean

  @Prop({ type: Function, required: true })
  public row!: VueConstructor

  @Prop({ type: Object, required: false, default: null })
  private serverMeta!: any

  @Prop({ type: Boolean, required: false, default: false })
  private serverSide!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public shadow!: boolean

  @Prop({ type: String, required: false, default: '' })
  public tableClass!: string

  private page: number = 1

  public get actualPage (): number {
    if (this.actual === null) {
      return this.page
    }
    return this.actual
  }

  public set actualPage (value) {
    if (this.actual === null) {
      this.page = value
    } else {
      this.$emit('change', value)
    }
  }

  public get isPaginated (): boolean {
    return this.serverSide || this.paginate
  }

  public get pageItems (): any[] {
    if (!this.paginate || this.serverSide) {
      return this.items
    }

    return this.items.slice(
      (this.actualPage - 1) * this.perPage,
      this.actualPage * this.perPage
    )
  }

  public get perPage (): number {
    if (this.serverSide) {
      return this.serverMeta.per_page
    }

    return this.onPage
  }

  public get total (): number {
    if (this.serverSide) {
      return this.serverMeta.total
    }

    return this.items.length
  }

  created () {
    if (this.serverSide && this.actual === null) {
      throw new Error(
        'Server-side pagination requires [actual] to be hosted by parent component.'
      )
    }

    if (this.serverSide && this.serverMeta === null) {
      throw new Error(
        'Server-side pagination requires [serverMeta] present.'
      )
    }
  }
}

export default ModelsList
