import FieldsList from './views/list.vue'
import EditField from './views/edit.vue'
import { CreateField } from './views/create.vue'

export const fieldsModuleRoutes = [
  {
    path: 'fields',
    name: 'list',
    component: FieldsList,
    meta: { requiresAuth: true, permission: 'events', level: 1 }
  },
  {
    path: 'fields/create',
    name: 'create',
    component: CreateField,
    meta: { requiresAuth: true, permission: 'events', level: 4 }
  },
  {
    path: 'fields/:id',
    name: 'edit',
    component: EditField,
    meta: { requiresAuth: true, permission: 'events', level: 1 }
  }
]
