
import { Component, Vue } from 'vue-property-decorator'

import Block from '@component/block.vue'
import { Breadcrumbs } from '@component/breadcrumbs.vue'
import { IResponse } from '@contract/http'
import { ViewContent } from '@view/partials/content.vue'

import { AccessableComponent } from '@module/auth/shared/access-helpers'

import { fieldPayloadMapper } from '../adapters/field'
import { Field } from '../contracts/models'
import { FieldForm } from '@module/fields/components/field-form.vue'

@Component({
  name: 'EditField',
  components: { Block, Breadcrumbs, FieldForm, ViewContent },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch('fields/fetchSingleField',
        { params: { fieldId: vm.$route.params.id } }
      )
    })
  }
})
export class EditField extends AccessableComponent {
  protected scope: string = 'events'
  /**
   * Is saving in progress.
   */
  public saving: boolean = false;

  /**
   * Form name.
   */
  public formName: string = 'createFields';
  /**
   * Breadcrumbs data getter.
   *
   * @return {object[]}
   */
  get breadcrumbs (): object[] {
    return [
      { label: this.model.name }
    ]
  }

  /**
   * Form save button text..
   */
  public get saveBtnText (): string {
    return this.$route.params.id ? 'Zapisz pole' : 'Dodaj pole'
  }

  /**
   * Event model getter.
   *
   * @return {Event}
   */
  get model (): Field {
    return this.$store.getters['fields/getSingleField']
  }

  /**
   * Destroyed Vue hook.
   */
  public destroyed (): void {
    this.$store.dispatch('fields/resetSingleFieldState')
  }

  /**
   * Update field action.
   *
   * @return {Promise<IResponse>}
   */
  public updateField (): Promise<IResponse> {
    return this.$store.dispatch('fields/updateSingleField', {
      params: { fieldId: this.$route.params.id }, data: fieldPayloadMapper(this.model)
    })
  }

  /**
   * Submit form handler.
   */
  public onSubmitHandler (): void {
    this.saving = true

    this.updateField().finally(() => {
      this.saving = false
    })
  }
}

export default EditField
