import { ResourceResponseMeta } from '@contract/resources'

export enum ActionHandle {
  SMS_NOTIFY = 'sms_notify'
}

export enum ActionTrigger {
  PARTICIPATION_VISITED_TAG = 'participation_visited_tag'
}

export interface ActionHandleFields {
  message?: string
  'phone_numbers'?: string[]
}

export interface ActionTriggerFields {
  tags?: string[]
}

export interface Action {
  id: number
  title: String
  handle: ActionHandle
  trigger: ActionTrigger
  handleFields: ActionHandleFields
  triggerFields: ActionTriggerFields
  createdAt: string
  updatedAt: string
}

export { ResourceResponseMeta as Meta } from '@contract/resources'

export interface State {
  action: Action
  actions: Action[]
  meta: ResourceResponseMeta
}
