
import { Component, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { AuthorizationType } from '@contract/resources'
import { enterKey, IEnterEvent } from '@ext/events'
import FormInput from '@component/form/input.vue'
import StateButton from '@component/button/state.vue'

import { IAuthorization } from '../contracts/services'
import { LoginModel } from '../contracts/models'

@Component({
  name: 'LoginPage',
  components: { FormInput, StateButton }
})
export class LoginPage extends Vue implements IEnterEvent {
  @Inject(AuthorizationType)
  protected authorization!: IAuthorization
  protected listener = enterKey.bind(this)

  public formName: string = 'loginForm'
  public profile: LoginModel = {
    email: '',
    password: ''
  }
  public sending: boolean = false

  mounted () {
    window.addEventListener('keyup', this.listener)
  }

  destroyed () {
    window.removeEventListener('keyup', this.listener)
  }

  authenticate () {
    if (this.profile.email && this.profile.password) {
      this.sending = true

      this.authorization.login(this.profile.email, this.profile.password)
        .then((result: boolean) => {
          if (result) {
            this.profile.email = null
            this.profile.password = null
            this.$router.push('/app')
          }

          this.sending = false
        })
    }
  }

  enterPressed () {
    this.authenticate()
  }
}

export default LoginPage
