
import { Component } from 'vue-property-decorator'
import { Route } from 'vue-router/types/router'

import Block from '@component/block.vue'
import { Breadcrumbs } from '@component/breadcrumbs.vue'
import { IResponse } from '@contract/http'
import { ViewContent } from '@view/partials/content.vue'

import { Invitation } from '@module/invitations/contracts/models'
import { InvitationForm } from '@module/invitations/components/invitation-form.vue'
import { invitationPayloadMapper } from '@module/invitations/adapters/invitation'
import { AccessableComponent } from '@module/auth/shared/access-helpers'

@Component({
  name: 'EditInvitation',
  components: { Block, Breadcrumbs, InvitationForm, ViewContent },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.name === 'events.edit') {
        // @ts-ignore
        vm.fromEvent = from
      }

      vm.$store.dispatch('invitations/fetchSingleInvitation',
        { params: { invitationId: vm.$route.params.id }, data: { with: 'participation' } }
      )
    })
  }
})
export class EditInvitation extends AccessableComponent {
  protected scope: string = 'events'
  /**
   * Search for tag label
   */
  public newTags: string[] = []

  /**
   * Search for tag label
   */
  public fromEvent: Route|boolean = false
  /**
   * Is saving in progress.
   */
  public saving: boolean = false;

  /**
   * Form save button text..
   */
  public saveBtnText: string = 'Zapisz zaproszenie';

  /**
   * Form name.
   */
  public formName: string = 'createFields';
  /**
   * Breadcrumbs data getter.
   *
   * @return {object[]}
   */
  get breadcrumbs (): object[] {
    return [
      { label: this.model.email }
    ]
  }

  /**
   * Event model getter.
   *
   * @return {Invitation}
   */
  get event (): Event {
    return this.$store.getters['events/getSingleEvent']
  }

  /**
   * Invitation model getter.
   *
   * @return {Invitation}
   */
  get model (): Invitation {
    return this.$store.getters['invitations/getSingleInvitation']
  }

  /**
   * Destroyed Vue hook.
   */
  public destroyed (): void {
    this.$store.dispatch('invitations/resetSingleInvitationState')
  }

  /**
   * Update field action.
   *
   * @return {Promise<IResponse>}
   */
  public updateField (): Promise<IResponse> {
    return this.$store.dispatch('invitations/updateSingleInvitation', {
      params: { id: this.$route.params.id },
      data: {
        with: 'tags',
        ...invitationPayloadMapper(this.model)
      }
    })
  }

  /**
   * Submit form handler.
   */
  public onSubmit (): void {
    this.saving = true

    this.updateField().finally(() => {
      this.saving = false
    })
  }
}

export default EditInvitation
