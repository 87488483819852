import { AppModule, Module } from '@support/modules'

import { actionsModuleStore } from './store'
import { actionsModuleRoutes } from './routes'

@AppModule({
  name: 'actions',
  routes: actionsModuleRoutes,
  state: actionsModuleStore
})
export class ActionsModule extends Module {}
export default ActionsModule
