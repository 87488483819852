
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IFile, IFileData, IFileDataVariant } from '@contract/components'

@Component({
  name: 'UiImage'
})
export class UiImage extends Vue {
  /**
   * Image model prop.
   *
   * @type {IFile}
   */
  @Prop({ required: true })
  protected model!: IFile

  /**
   * Image variant size to display.
   * Default 450.
   *
   * @type {number}
   */
  @Prop({ required: false, default: 450 })
  protected size!: number

  /**
   * Placeholder URl.
   */
  protected placeholderUrl: string = require('@/assets/images/papaya-films-logo.svg')

  /**
   * Is load error.
   */
  protected loadError: boolean = false

  /**
   * Alt text image getter.
   */
  public get altText (): string {
    if (!this.model.alt) {
      return 'Alternate text'
    }

    return this.model.alt
  }

  /**
   * File source URl getter.
   *
   * @return {string}
   */
  public get source (): string {
    if (!this.loadError) {
      const bySize = this.findVariantBySize()

      if (bySize) {
        return bySize.url
      }

      return this.fileUrl
    } else {
      return this.placeholderUrl
    }
  }

  /**
   * Image load error handler
   */
  public imageLoadErrorHandler (): void {
    this.loadError = true
  }

  /**
   * Model file getter.
   *
   * @return {IFileData}
   */
  protected get file (): IFileData {
    return this.model.file
  }

  /**
   * Image variants getter.
   * If does not have variants return empty array.
   *
   * @return {IFileDataVariant[]}
   */
  protected get variants (): IFileDataVariant[] {
    if (
      !this.file.variants ||
      !this.file.variants.length ||
      !this.model.hasVariants
    ) {
      return []
    }

    return this.file.variants
  }

  /**
   * Find image variant by passed to component size.
   * If any variant is not assignable return false.
   *
   * @return {IFileDataVariant | Boolean}
   */
  protected findVariantBySize (): IFileDataVariant | false {
    const bySize = this.variants.find((v: IFileDataVariant) => {
      if (v.width >= this.size) {
        return v
      }
    })

    if (!bySize) {
      return false
    }

    return bySize
  }

  /**
   * File URl getter.
   *
   * @return {string}
   */
  protected get fileUrl (): string {
    if (this.file.url) {
      return this.file.url
    }

    if (this.file.thumbnail) {
      return this.file.thumbnail
    }

    return this.placeholderUrl
  }
}

export default UiImage
