import { AsyncContainerModule, ContainerModule } from 'inversify'
import { RouteConfig } from 'vue-router/types/router'
import { Module as VuexModule, ModuleTree } from 'vuex/types/index'

export function AppModule (registry: ModuleRegistry) {
  return function _AppModule (target: ModuleConstructor): ModuleConstructor {
    return <typeof target><ModuleConstructor> class extends target {
      constructor () {
        super()
        this.name = registry.name
        this.moduleProviders = (
          registry.hasOwnProperty('providers') &&
          typeof registry.providers !== 'undefined'
        ) ? registry.providers : null

        this.moduleRoutes = (
          registry.hasOwnProperty('routes') &&
          typeof registry.routes !== 'undefined'
        ) ? registry.routes : []

        this.moduleState = (
          registry.hasOwnProperty('state') &&
          typeof registry.state !== 'undefined'
        ) ? registry.state : {}
      }
    }
  }
}

export interface IModulesBootstraper {
  resolvedProviders: ProviderConfig[]
  resolvedRouting: RouteConfig[]
  resolvedStates: ModuleTree<any>
}

export interface IModule {
  providers: () => ProviderConfig|null
  routes: () => RouteConfig[]
  state: () => VuexModule<any, any>|null
}

export abstract class Module implements IModule {
  name: string|null = null
  moduleProviders: ProviderConfig|null = null
  moduleRoutes: RouteConfig[] = []
  moduleState: VuexModule<any, any> = {}

  providers () : ProviderConfig|null {
    return this.moduleProviders
  }

  routes (): RouteConfig[] {
    return this.moduleRoutes.map(item => {
      if (this.name !== null && this.name.length && item.hasOwnProperty('name')) {
        item.name = `${this.name}.${item.name}`
      }

      return item
    })
  }

  state (): ModuleTree<any>|null {
    if (!Object.keys(this.moduleState).length) {
      return null
    }

    const state: ModuleTree<any> = {}
    state[this.name as string] = this.moduleState

    return state
  }
}

type ModuleConstructor = new () => Module

export interface ModuleRegistry {
  name: string
  providers?: ProviderConfig,
  routes?: RouteConfig[],
  state?: VuexModule<any, any>
}

export interface ProviderConfig {
  module: ContainerModule|AsyncContainerModule
  type: ProviderType
}

export enum ProviderType {
  Async = 'async',
  Sync = 'sync'
}
