
import { Component, Vue } from 'vue-property-decorator'

import { BlockTabs } from '@component/block/tabs.vue'
import { Breadcrumbs } from '@component/breadcrumbs.vue'
import { ITabConfig } from '@contract/components'
import { ViewContent } from '@view/partials/content.vue'

import { Event } from '../contracts/models'
import { TabDetails } from '../components/tab-details.vue'
import { AccessableComponent } from '@module/auth/shared/access-helpers'

@Component({
  name: 'CreateEvent',
  components: { Breadcrumbs, ViewContent, BlockTabs },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch('events/resetSingleEventState')
    })
  }
})
export class CreateEvent extends AccessableComponent {
  protected scope: string = 'events'

  protected coverImage!: File

  /**
   * Event model getter.
   *
   * @return {Event}
   */
  get model (): Event {
    return this.$store.getters['events/getSingleEvent']
  }

  /**
   * Breadcrumbs data getter.
   *
   * @return {object[]}
   */
  get breadcrumbs (): object[] {
    return [
      { label: 'Dodaj nowe wydarzenie', route: 'create' },
      { label: this.model.title.pl }
    ]
  }

  /**
   * Tabs config getters.
   *
   * @return {ITabConfig[]}
   */
  get tabs (): ITabConfig[] {
    return [
      {
        title: 'Wydarzenie',
        component: TabDetails,
        props: {
          formName: 'createEvents',
          saveBtnText: 'Dodaj wydarzenie',
          model: this.model,
          modelExist: false,
          canEdit: this.canEdit()
        }
      }
    ]
  }
}

export default CreateEvent
