
import { Component, Vue } from 'vue-property-decorator'

import { AccessableComponent } from '@module/auth/shared/access-helpers'
import { BlockTabs } from '@component/block/tabs.vue'
import { Breadcrumbs } from '@component/breadcrumbs.vue'
import { ITabConfig } from '@contract/components'
import { ViewContent } from '@view/partials/content.vue'

import { Event } from '../contracts/models'
import { TabDetails } from '../components/tab-details.vue'
import { TabForm } from '../components/tab-forms.vue'
import { TabInvitations } from '../components/tab-invitations.vue'
import { TabInvitationsLink } from '../components/tab-invitations-link.vue'
import { TabMedia } from '@module/events/components/tab-media.vue'
import { TabParticipations } from '../components/tab-participations.vue'

@Component({
  name: 'EditEvent',
  components: { BlockTabs, Breadcrumbs, ViewContent },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      Promise.all([
        vm.$store.dispatch('events/resetSingleEventState'),
        vm.$store.dispatch('events/fetchSingleEvent',
          { params: { eventId: vm.$route.params.id }, data: { with: 'files' } }
        )
      ])
    })
  }
})
export class EditEvent extends AccessableComponent {
  protected scope: string = 'events'
  /**
   * Event model getter.
   *
   * @return {Event}
   */
  get model (): Event {
    return this.$store.getters['events/getSingleEvent']
  }

  /**
   * Breadcrumbs data getter.
   *
   * @return {object[]}
   */
  get breadcrumbs (): object[] {
    return [
      { label: this.model && this.model.title && this.model.title.pl ? this.model.title.pl : '' }
    ]
  }

  /**
   * Tabs config getters.
   *
   * @return {ITabConfig[]}
   */
  get tabs (): ITabConfig[] {
    return [
      {
        title: 'Wydarzenie',
        component: TabDetails,
        props: {
          formName: 'updateEvents',
          model: this.model,
          modelExist: true,
          canEdit: this.canEdit()
        }
      },
      {
        title: 'Media',
        component: TabMedia,
        props: {
          formName: 'updateEvents',
          saveBtnText: 'Zaktualizuj media',
          model: this.model,
          canEdit: this.canEdit()
        }
      },
      {
        title: 'Formularz',
        component: TabForm,
        props: {
          formName: 'updateForms',
          modelExist: true,
          canEdit: this.canEdit()
        }
      },
      {
        title: this.participantsTabTitle,
        component: TabParticipations,
        props: {
          formName: 'updateEventParticipations',
          modelExist: true,
          canEdit: this.canEdit()
        }
      },
      {
        title: `Zaproszenia`,
        component: TabInvitations,
        props: {
          formName: 'updateEventInvitations',
          modelExist: true,
          canEdit: this.canEdit()
        }
      },
      {
        title: 'Utwórz link do zaproszeń',
        component: TabInvitationsLink,
        props: {
          isEditable: this.canEdit(),
          formName: 'createInvitationsLink',
          model: this.model,
          modelExist: true
        }
      }
    ]
  }

  protected get participantsTabTitle () {
    const title = 'Zgłoszenia'
    const segments = {
      count: 0,
      max: 0
    }

    if (this.model) {
      if ('participantsCount' in this.model && this.model.participantsCount) {
        segments.count = this.model.participantsCount
      }

      if ('maxParticipants' in this.model && this.model.maxParticipants) {
        segments.max = this.model.maxParticipants
      }
    }
    return `${title} (${segments.count}/${segments.max})`
  }
}

export default EditEvent
