import { IResponse } from '@contract/http'
import { Repository } from '@support/repository'

import { AuthRepository } from '../contracts/services'
import { PasswordPayload } from '../contracts/models'

/**
 * MeRepository controls me data model.
 *
 * @author  Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @version 1.0.0
 */
export class MeRepository extends Repository implements AuthRepository {
  protected _config = {
    model: 'auth/getMe'
  }

  /**
   * Send e-mail when user forgot password.
   */
  forgotPassword (email: string): Promise<IResponse> {
    return this.callAction('auth/forgotPassword', { email })
  }

  /**
   * Login user.
   */
  login (email: string, password: string): Promise<IResponse> {
    return this.callAction('auth/login', { grand_type: 'credentials', email, password })
  }

  /**
   * Loagout user.
   */
  logout (): Promise<IResponse> {
    return this.callAction('auth/logout')
  }

  /**
   * Refresh token.
   */
  refresh (accessToken: string|null): Promise<IResponse> {
    return this.callAction('auth/refresh', { token: accessToken })
  }

  /**
   * Setup new password for a user.
   */
  resetPassword (payload: PasswordPayload): Promise<IResponse> {
    return this.callAction('auth/resetPassword', payload)
  }
}

export const MeRepositoryType = Symbol.for('MeRepository')
