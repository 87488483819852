import { ParticipationsList } from './views/list.vue'
import { EditParticipation } from './views/edit.vue'

export const participationsModuleRoutes = [
  {
    path: 'participations',
    name: 'list',
    component: ParticipationsList,
    meta: { requiresAuth: true, permission: 'participations', level: 1 }
  },
  {
    path: 'participations/:id',
    name: 'edit',
    component: EditParticipation,
    meta: { requiresAuth: true, permission: 'participations', level: 1 }
  }
]

export default participationsModuleRoutes
