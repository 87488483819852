import { Adapter, MappingConfig, MappingTypes } from '@support/adapter'

import { Payload } from '@contract/http'
import { Role, User } from '../contracts/models'

class UserAdapter extends Adapter {
  mapping: MappingConfig = {
    id: 'id',
    email: 'email',
    firstName: 'name',
    lastName: 'surname',
    role: {
      type: MappingTypes.Function,
      value: (user: any) => {
        if (user && user.roles && user.roles.length) {
          return user.roles.map((role: Role) => role.name).join('')
        }
        return ''
      }
    },
    roles: {
      type: MappingTypes.Function,
      value: (user: any) => {
        if (user && user.roles && user.roles.length) {
          return parseInt(user.roles.map((role: Role) => role.id).join(''), 10)
        }
        return null
      }
    },
    createdAt: 'created_at',
    updatedAt: 'updated_at'
  }
  preserve: boolean = false
}
export const userAdapter = new UserAdapter()

export const userPayloadMapper = (model: User): Payload => {
  return {
    email: model.email,
    first_name: model.firstName,
    last_name: model.lastName,
    roles: [model.roles]
  }
}

export default {
  userAdapter: userAdapter,
  userMapper: userPayloadMapper
}
