import Vue from 'vue'
import Vuex from 'vuex'

import { AsyncContainerModule, Container, ContainerModule } from 'inversify'

import appRouter from './router'
import { ProviderConfig, ProviderType } from '@support/modules'
import services from '@/config/services'

import { ModulesBootstraper } from './modules'
import { registerExtentions } from './extentions'

const appContainer = new Container()
const syncProviders: ContainerModule[] = [services]
const asyncProviders: AsyncContainerModule[] = []

const modulesBootstraper = ModulesBootstraper.instance()
asyncProviders.push(
  ...modulesBootstraper.resolvedProviders.filter((item: ProviderConfig) => item.type === ProviderType.Async).map((item: ProviderConfig) => {
    return (item.module as AsyncContainerModule)
  })
)
syncProviders.push(
  ...modulesBootstraper.resolvedProviders.filter((item: ProviderConfig) => item.type === ProviderType.Sync).map((item: ProviderConfig) => {
    return (item.module as ContainerModule)
  })
)

if (syncProviders.length) {
  appContainer.load(...syncProviders)
}

if (asyncProviders.length) {
  appContainer.loadAsync(...asyncProviders)
}

registerExtentions(Vue)

Vue.use(Vuex)

const appState = new Vuex.Store({
  modules: modulesBootstraper.resolvedStates
})

export const container = appContainer
export const router = appRouter
export const store = appState
