import { FieldType } from '@module/fields/contracts/models'

export const fieldTypeLabel = (type: FieldType): string => {
  switch (type) {
    case FieldType.Boolean: return 'Logiczny'
    case FieldType.Number: return 'Liczbowy'
    case FieldType.String: return 'Tekstowy'
  }
}

export default fieldTypeLabel
