
import { Component, Prop, Vue } from 'vue-property-decorator'

import { Badge } from '@component/badge.vue'
import { ModelActions } from '@component/models/actions.vue'

import { User, UserRole } from '../contracts/models'
import { userRoleLabel } from '../helpers/user-type-label'

@Component({
  name: 'UserRow',
  components: { Badge, ModelActions }
})
export class UserRow extends Vue {
  /**
   * Event row index.
   *
   * @type {number}
   */
  @Prop({ required: true })
  public index!: number

  /**
   * Can edit row
   *
   * @type {number}
   */
  @Prop({ required: false })
  public canEdit!: Boolean

  /**
  * Event model.
  *
  * @type {number}
  */
  @Prop({ required: true })
  public model!: User

  /**
   * Delete event action.
   */
  public deleteSingleItem (): void {
    this.$store.dispatch('users/deleteUserSingle', {
      params: {
        userId: this.model.id
      },
      query: {
        ...this.$route.query
      }
    })
  }

  /**
   * Get event status label.
   */
  public userRoleLabel (value: UserRole): string {
    return userRoleLabel(value)
  }
}

export default UserRow
