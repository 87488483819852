
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'
import saveAs from 'file-saver'
import { VueConstructor } from 'vue'
import EventBus from '@service/eventbus'

import { AuthorizationType } from '@contract/resources'
import { IAuthorization } from '@module/auth/contracts/services'
import { IResponse, Query } from '@contract/http'
import { IModal, ModalType } from '@contract/modal'
import { ModelsList } from '@component/models/list.vue'
import { UiStateButton } from '@component/button/state.vue'

import { Meta, Invitation } from '@module/invitations/contracts/models'
import { InvitationRow } from '@module/invitations/components/invitation-row.vue'
import { InvitationFilters } from '@module/invitations/components/invitation-filters.vue'

@Component({
  name: 'EventInvitations',
  components: {
    ModelsList,
    UiStateButton,
    InvitationFilters
  }
})
export class EventInvitations extends Vue {
  @Prop({ type: Array, required: true })
  public collection!: Invitation[]

  @Inject(AuthorizationType)
  protected _authorization!: IAuthorization

  @Inject(ModalType)
  protected _connector!: IModal

  public exporting: boolean = false
  public sending: boolean = false
  public loading: boolean = false
  public static perPage: number = 12
  public rowComponent: VueConstructor = InvitationRow
  protected scope: string = 'events'

  public canEdit (): boolean {
    return this._authorization.hasPermission(this.scope, 4)
  }

  /**
   * Vue lifecycle hook
   *
   * @return {number}
   */
  created (): void {
    this.$store.dispatch('events/fetchEventInvitations',
      {
        id: this.$route.params.id,
        filters: {
          page: 1,
          per_page: EventInvitations.perPage,
          sort: 'id'
        }
      }
    )

    EventBus.$on('sendingConfirmed', () => {
      this.resendMails()
    })
  }

  /**
   * Vue destroyed hook
   */
  destroyed (): void {
    EventBus.$off('sendingConfirmed')
  }

  /**
   * Exports data as csv.
   */
  public exportAsCsv (): void {
    this.exporting = true

    this.$store.dispatch(
      'events/exportInvitationsAsCsv',
      {
        id: this.$route.params.id,
        filters: this.queryParams
      }
    ).then((response: string|null) => {
      if (response !== null) {
        // @ts-ignore
        saveAs(
          response,
          `e_${this.$route.params.id}_invitations_${(new Date()).getTime()}.csv`,
          { autoBom: true }
        )
      }

      this.exporting = false
    })
  }

  public confirmResending (): void {
    this._connector.open('confirmSendingEmails')
  }

  /**
   * Resend mails to filtered mails
   */
  public resendMails (): void {
    this.sending = true

    this.$store.dispatch(
      'events/resentInvitationsMail',
      {
        id: this.$route.params.id,
        filters: {
          sort: 'id',
          page: 1,
          per_page: EventInvitations.perPage,
          ...this.queryParams
        }
      }
    ).finally(() => {
      this.sending = false
    })
  }

  /**
   * Event invitations collection getter.
   *
   * @return {Invitation}
   */
  get invitations (): Invitation[] {
    if (!Array.isArray(this.collection)) {
      return []
    }

    return this.collection
  }

  /**
   * Get invitations total count.
   *
   * @return {number}
   */
  public get invitationsTotal (): number {
  //   if (this.invitations && this.invitations.hasOwnProperty('length')) {
  //     return this.invitations.length
  //   }
  //   return 0
    if (!this.meta || !this.meta.hasOwnProperty('total') || typeof this.meta.total === 'undefined') {
      return 0
    }

    return this.meta.total
  }

  /**
   * Events collection meta data.
   *
   * @return {Meta}
   */
  public get meta (): Meta {
    return this.$store.getters['events/getEventMeta']
  }

  /**
   * Load invitations collection.
   *
   * @param {object} params
   */
  public loadCollection (params: object): Promise<IResponse> {
    return this.$store.dispatch('events/fetchEventInvitations', {
      id: this.$route.params.id,
      filters: {
        page: 1,
        per_page: EventInvitations.perPage,
        sort: 'id',
        ...params
      }
    })
  }

  /**
   * Get current page.
   *
   * @return {number}
   */
  public get page (): number {
    if (this.$route.query.hasOwnProperty('page') && this.$route.query.page != null) {
      return parseInt(this.$route.query.page as string, 10)
    }

    return this.meta.current_page || 1
  }

  /**
   * Set page and change it.
   *
   * @param {number} page
   */
  public setPage (page: number): void {
    if (this.page !== page) {
      this.$router.push({ name: 'events.edit', params: { id: this.$route.params.id }, query: { ...this.queryParams, page: `${page}` } })
    }
  }

  /**
   * Set query and re-load collection.
   *
   * @param {Query} query
   */
  public setQuery (query: Query): void {
    if (this.queryParams !== query) {
      this.$router.push({ name: 'events.edit', params: { id: this.$route.params.id }, query: query })
    }
  }

  /**
   * Get query params.
   *
   * @return {Query}
   */
  public get queryParams (): Query {
    return {
      ...this.$route.query
    }
  }

  /**
   * Watch page change and re-load collection.
   *
   * @param {Query} query
   */
  @Watch('queryParams', { deep: true })
  public onPageChange (query: Query): void {
    this.loading = true

    this.loadCollection(query).then((response: IResponse) => {
      if (response) {
        this.loading = false
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        this.$router.back()
      }
    })
  }
}

export default EventInvitations
