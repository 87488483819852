
import { Component, Prop, Vue } from 'vue-property-decorator'

import { Badge } from '@component/badge.vue'
import { ModelActions } from '@component/models/actions.vue'
import { Datetime } from '@service/datetime'

import { Event, EventStatus } from '../contracts/models'
import { eventStatusLabel } from '../helpers/event-status-label'

@Component({
  name: 'EventRow',
  components: { Badge, ModelActions }
})
export class EventRow extends Vue {
  /**
   * Event row index.
   *
   * @type {number}
   */
  @Prop({ required: true })
  public index!: number

  /**
   * Can edit row
   *
   * @type {number}
   */
  @Prop({ required: false })
  public canEdit!: Boolean

  /**
  * Event model.
  *
  * @type {number}
  */
  @Prop({ required: true })
  public model!: Event

  /**
   * Get event status label.
   */
  public eventStatusLabel (value: EventStatus): string {
    return eventStatusLabel(value)
  }

  public dateTime = new Datetime()

  /**
   * Delete event action.
   */
  public deleteSingleItem (): void {
    this.$store.dispatch('events/deleteSingleEvent', {
      params: {
        eventId: this.model.id
      },
      query: {
        ...this.$route.query,
        with: 'participations'
      }
    })
  }

  /**
   * Check if event has max Participants.
   */
  public get hasFullParticipipants (): boolean {
    if (this.model.participantsCount) {
      return this.model.participantsCount === this.model.maxParticipants
    }
    return false
  }

  /**
   * Check if event has max Participants.
   */
  public get hasNoParticipipants (): boolean {
    if (this.model.hasOwnProperty('participantsCount')) {
      return this.model.participantsCount === 0
    }
    return false
  }
}

export default EventRow
