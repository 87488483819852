import { AppModule, Module } from '@support/modules'

import { invitationsLinksModuleStore } from './store'
import { invitationsLinksModuleRoutes } from './routes'

@AppModule({
  name: 'invitations-links',
  routes: invitationsLinksModuleRoutes,
  state: invitationsLinksModuleStore
})
export class InvitationsLinksModule extends Module {}
export default InvitationsLinksModule
