
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import EventBus from '@service/eventbus'
import { IFile } from '@contract/components'
import { UiImage } from '@component/image.vue'
import { Event as EventModel, EventMediaPurpose } from '@module/events/contracts/models'

import { IModal, IModalComponent, ModalPayload, ModalType } from '@contract/modal'

@Component({
  name: 'ImagesAddModal',
  components: { UiImage }
})
export class ImagesAddModal extends Vue implements IModalComponent {
  public selectedImages: Array<number> = []

  @Inject(ModalType)
  protected _connector!: IModal

  @Prop({ required: true })
  public payload!: ModalPayload

  /**
   * Event images collection getter.
   *
   * @return {IFile[]}
   */
  public get images (): IFile[]|Array<any> {
    return this.$store.getters['events/getEventFiles']
      .filter((file: IFile) => {
        const purpose = file.purpose

        return (purpose !== EventMediaPurpose.Cover && purpose !== EventMediaPurpose.EmailCover)
      })
  }

  /**
   * Fields models collection getter.
   *
   * @return {Field[]}
   */
  public get model (): EventModel {
    return this.$store.getters['events/getSingleEvent']
  }

  /**
   * Vue created hook
   */
  public created (): void {
    this.selectedImages = [...this.payload.initialItems]
  }

  /**
   * Check if item is selected
   */
  public checkSelection (id: number): boolean {
    return this.selectedImages.indexOf(id) !== -1
  }

  /**
   *  Add image action
   */
  public onAddImage (): void {
    try {
      EventBus.$emit('imagesAdded', {
        index: this.payload.index,
        items: this.selectedImages
      })
      this.close()
    } catch (err) {
      throw new Error(err)
    }
  }

  /**
   * Image selection handler
   */
  public onImageSelection (id: number): void {
    if (!this.checkSelection(id)) {
      this.selectedImages.push(id)
    } else {
      this.selectedImages.splice(this.selectedImages.indexOf(id), 1)
    }
  }

  /**
   * Remove item handler
   */
  public async onImageRemoval (id: number): Promise<void> {
    try {
      await this.$store.dispatch('events/removeEventMedia', { id: id })
    } catch (err) {
      throw new Error(err)
    }
  }

  /**
   * Close modal.
   *
   */
  public close (): void {
    this._connector.close()
  }
}

export default ImagesAddModal
