import { Methods } from '@contract/http'
import { ResourcesRegistry } from '@contract/resources'

const routing: ResourcesRegistry = {
  actions: {
    prefix: 'actions',
    methods: {
      create: {
        url: '',
        method: Methods.Post,
        auth: true
      },
      delete: {
        url: '{actionId}',
        method: Methods.Delete,
        params: ['actionId'],
        auth: true
      },
      list: {
        url: '',
        method: Methods.Get,
        auth: true
      },
      show: {
        url: '{actionId}',
        method: Methods.Get,
        params: ['actionId'],
        auth: true
      },
      update: {
        url: '{actionId}',
        method: Methods.Put,
        params: ['actionId'],
        auth: true
      }
    }
  },
  auth: {
    prefix: 'access',
    methods: {
      signIn: {
        url: '?withUserPermissions=1',
        method: Methods.Post,
        formName: 'loginForm'
      },
      refresh: {
        url: 'refresh?withUserPermissions=1',
        method: Methods.Post,
        auth: true
      },
      signOut: {
        url: '',
        method: Methods.Delete,
        auth: true
      }
    }
  },
  events: {
    prefix: 'events',
    methods: {
      create: {
        url: '',
        method: Methods.Post,
        auth: true
      },
      delete: {
        url: '{eventId}',
        method: Methods.Delete,
        params: ['eventId'],
        auth: true
      },
      list: {
        url: '',
        method: Methods.Get,
        auth: true
      },
      show: {
        url: '{eventId}',
        method: Methods.Get,
        params: ['eventId'],
        auth: true
      },
      update: {
        url: '{eventId}',
        method: Methods.Put,
        params: ['eventId'],
        auth: true
      },
      upload: {
        url: '{eventId}/files',
        method: Methods.Post,
        params: ['eventId'],
        auth: true
      }
    }
  },
  eventParticipations: {
    prefix: 'events',
    methods: {
      show: {
        url: '{eventId}/participations',
        method: Methods.Get,
        params: ['eventId'],
        auth: true
      },
      update: {
        url: '{eventId}/participations',
        method: Methods.Post,
        params: ['eventId'],
        auth: true
      }
    }
  },
  eventInvitations: {
    prefix: 'events',
    methods: {
      list: {
        url: '{eventId}/invitations',
        method: Methods.Get,
        params: ['eventId'],
        auth: true
      },
      resent: {
        url: '{eventId}/resent-invitations',
        method: Methods.Post,
        params: ['eventId'],
        auth: true
      }
      // update: {
      //   url: '{eventId}/participations',
      //   method: Methods.Post,
      //   params: ['eventId'],
      //   auth: true
      // }
    }
  },
  eventInvitationsLinks: {
    prefix: 'events',
    methods: {
      create: {
        url: '{eventId}/invitations-link',
        method: Methods.Post,
        params: ['eventId'],
        auth: true
      }
    }
  },
  files: {
    prefix: 'files',
    methods: {
      delete: {
        url: '{id}/delete',
        method: Methods.Delete,
        params: ['id'],
        auth: true
      }
    }
  },
  forms: {
    prefix: 'events',
    methods: {
      show: {
        url: '{eventId}/fields',
        method: Methods.Get,
        params: ['eventId'],
        auth: true
      },
      update: {
        url: '{eventId}/fields',
        method: Methods.Put,
        params: ['eventId'],
        auth: true
      }
    }
  },
  fields: {
    prefix: 'fields',
    methods: {
      list: {
        url: '',
        method: Methods.Get,
        auth: true
      },
      show: {
        url: '{fieldId}',
        method: Methods.Get,
        params: ['fieldId'],
        auth: true
      },
      update: {
        url: '{fieldId}',
        method: Methods.Put,
        params: ['fieldId'],
        auth: true
      },
      create: {
        url: '',
        method: Methods.Post,
        auth: true
      },
      delete: {
        url: '{fieldId}',
        method: Methods.Delete,
        params: ['fieldId'],
        auth: true
      }
    }
  },
  invitations: {
    prefix: 'invitations',
    methods: {
      delete: {
        url: '{invitationId}',
        method: Methods.Delete,
        params: ['invitationId'],
        auth: true
      },
      import: {
        url: 'import',
        method: Methods.Post,
        auth: true
      },
      resent: {
        url: '{invitationId}/resent',
        method: Methods.Post,
        params: ['invitationId'],
        auth: true
      },
      show: {
        url: '{invitationId}',
        method: Methods.Get,
        params: ['invitationId'],
        auth: true
      },
      update: {
        url: '{invitationId}',
        method: Methods.Put,
        params: ['invitationId'],
        auth: true
      }
    }
  },
  invitationsLinks: {
    prefix: 'invitations-links',
    methods: {
      list: {
        url: '',
        method: Methods.Get,
        auth: true
      },
      show: {
        url: '{invitationsLinkId}',
        method: Methods.Get,
        params: ['invitationsLinkId'],
        auth: true
      },
      activation: {
        url: '{invitationsLinkId}/active',
        method: Methods.Put,
        params: ['invitationsLinkId'],
        auth: true
      },
      update: {
        url: '{invitationsLinkId}',
        method: Methods.Put,
        params: ['invitationsLinkId'],
        auth: true
      },
      updateTags: {
        url: '{invitationsLinkId}/tags',
        method: Methods.Post,
        params: ['invitationsLinkId'],
        auth: true
      },
      delete: {
        url: '{invitationsLinkId}',
        method: Methods.Delete,
        params: ['invitationsLinkId'],
        auth: true
      },
      send: {
        url: '{invitationsLinkId}/send',
        method: Methods.Put,
        params: ['invitationsLinkId'],
        auth: true
      }
    }
  },
  participations: {
    prefix: 'participations',
    methods: {
      addTags: {
        url: 'tags',
        method: Methods.Put,
        auth: true
      },
      exportTicket: {
        url: '{participationId}/export-ticket',
        method: Methods.Get,
        params: ['participationId'],
        auth: true
      },
      exportTickets: {
        // url: '{participationId}/export-ticket',
        url: 'export-tickets',
        method: Methods.Post,
        // params: ['participationId'],
        auth: true
      },
      list: {
        url: '',
        method: Methods.Get,
        auth: true
      },
      show: {
        url: '{participationId}',
        method: Methods.Get,
        params: ['participationId'],
        auth: true
      },
      update: {
        url: '{participationId}',
        method: Methods.Put,
        params: ['participationId'],
        auth: true
      },
      updateTags: {
        url: '{participationId}/tags',
        method: Methods.Post,
        params: ['participationId'],
        auth: true
      },
      delete: {
        url: '{participationId}',
        method: Methods.Delete,
        params: ['participationId'],
        auth: true
      }
    }
  },
  password: {
    prefix: 'password',
    methods: {
      reset: {
        url: 'reset',
        method: Methods.Post,
        formName: 'resetPassword'
      },
      send: {
        url: 'send',
        method: Methods.Post
      }
    }
  },
  tags: {
    prefix: 'tags',
    methods: {
      list: {
        url: '',
        method: Methods.Get,
        auth: true
      }
    }
  },
  users: {
    prefix: 'users',
    methods: {
      list: {
        url: '',
        method: Methods.Get,
        auth: true
      },
      show: {
        url: '{userId}',
        method: Methods.Get,
        params: ['userId'],
        auth: true
      },
      update: {
        url: '{userId}',
        method: Methods.Put,
        params: ['userId'],
        auth: true
      },
      create: {
        url: '',
        method: Methods.Post,
        auth: true
      },
      delete: {
        url: '{userId}',
        method: Methods.Delete,
        params: ['userId'],
        auth: true
      }
    }
  },
  roles: {
    prefix: 'roles',
    methods: {
      list: {
        url: '',
        method: Methods.Get,
        auth: true
      },
      show: {
        url: '{roleId}',
        method: Methods.Get,
        params: ['roleId'],
        auth: true
      },
      update: {
        url: '{roleId}',
        method: Methods.Put,
        params: ['roleId'],
        auth: true
      },
      create: {
        url: '',
        method: Methods.Post,
        auth: true
      },
      delete: {
        url: '{roleId}',
        method: Methods.Delete,
        params: ['roleId'],
        auth: true
      }
    }
  }
}

export default routing
