
import { Component, Watch, Vue } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { Breadcrumbs } from '@component/breadcrumbs.vue'
import { ModelsList } from '@component/models/list.vue'
import { IResponse, Query } from '@contract/http'
import { ViewContent } from '@view/partials/content.vue'

import { Meta, Participation } from '@module/participations/contracts/models'
import { ParticipationRow } from '@module/participations/components/participation-row.vue'
import { ParticipationFilters } from '@module/participations/components/participation-filters.vue'
import { AccessableComponent } from '@module/auth/shared/access-helpers'

@Component({
  name: 'ParticipationsList',
  components: { Breadcrumbs, ModelsList, ParticipationFilters, ViewContent },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch('participations/fetchParticipationsList',
        { page: 1, per_page: ParticipationsList.perPage, ...vm.$route.query }
      )
    })
  }
})
export class ParticipationsList extends AccessableComponent {
  protected scope: string = 'participations'
  /**
   * Is list loading.
   *
   * @type {boolean}
   */
  public loading: boolean = false

  /**
   * Resources per page.
   */
  public static perPage: number = 12

  /**
   * List row component.
   *
   * @type {VueConstructor}
   */
  public rowComponent: VueConstructor = ParticipationRow

  /**
   * Get breadcrumbs details.
   *
   * @return {object[]}
   */
  public get breadcrumbs (): object[] {
    return [
      { label: `Lista (${this.participationsTotal})` }
    ]
  }

  /**
   * Events collection meta data.
   *
   * @return {Meta}
   */
  public get meta (): Meta {
    return this.$store.getters['participations/getMeta']
  }

  /**
   * Get current page.
   *
   * @return {number}
   */
  public get page (): number {
    if (this.$route.query.hasOwnProperty('page') && this.$route.query.page != null) {
      return parseInt(this.$route.query.page as string, 10)
    }

    return this.meta.current_page || 1
  }

  /**
   * Participation models collection getter.
   *
   * @return {Event[]}
   */
  public get participations (): Participation[] {
    return this.$store.getters['participations/getParticipationsList']
  }

  /**
   * Get participations total count.
   *
   * @return {number}
   */
  public get participationsTotal (): number {
    if (!this.meta || !this.meta.hasOwnProperty('total') || typeof this.meta.total === 'undefined') {
      return 0
    }

    return this.meta.total
  }

  /**
   * Load participations collection.
   *
   * @param {object} params
   */
  public loadCollection (params: object): Promise<IResponse> {
    console.log(params)
    return this.$store.dispatch('participations/fetchParticipationsList', {
      page: 1,
      per_page: ParticipationsList.perPage,
      sort: 'id',
      ...params
    })
  }

  /**
   * Set page and change it.
   *
   * @param {number} page
   */
  public setPage (page: number): void {
    if (this.page !== page) {
      this.$router.push({ name: 'participations.list', query: { ...this.queryParams, page: `${page}` } })
    }
  }

  /**
   * Set query and re-load collection.
   *
   * @param {Query} query
   */
  public setQuery (query: Query): void {
    if (this.queryParams !== query) {
      this.$router.push({ name: 'participations.list', query: query })
    }
  }

  /**
   * Get query params.
   *
   * @return {Query}
   */
  public get queryParams (): Query {
    return {
      ...this.$route.query
    }
  }

  /**
   * Watch page change and re-load collection.
   *
   * @param {Query} query
   */
  @Watch('queryParams', { deep: true })
  public onPageChange (query: Query): void {
    this.loading = true

    this.loadCollection(query).then((response: IResponse) => {
      if (response) {
        this.loading = false
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        this.$router.back()
      }
    })
  }
}

export default ParticipationsList
