import { EditInvitation } from '@module/invitations/views/edit.vue'
import { ImportInvitations } from '@module/invitations/views/import.vue'

export const invitationsModuleRoutes = [
  {
    path: 'invitations/import',
    name: 'import',
    component: ImportInvitations,
    meta: { requiresAuth: true, permission: 'events', level: 1 }
  },
  {
    path: 'invitations/:id',
    name: 'edit',
    component: EditInvitation,
    meta: { requiresAuth: true, permission: 'events', level: 1 }
  }
]

export default invitationsModuleRoutes
