import { Adapter, MappingConfig, MappingTypes } from '@support/adapter'
import { eventAdapter } from '@module/events/adapters/event'
import { Payload } from '@contract/http'
import { tagAdapter } from '@module/participations/adapters/tag'

import { InvitationsLink } from '../contracts/models'

class InvitationsLinkAdapter extends Adapter {
  /**
   * Adapter mapping types.
   *
   * @type {MappingConfig}
   */
  mapping: MappingConfig = {
    id: 'id',
    slug: 'slug',
    locale: 'locale',
    content: 'content',
    url: 'url',
    active: 'active',
    uses: 'uses',
    email: 'email',
    event: {
      type: MappingTypes.Adapter,
      value: 'event',
      adapter: eventAdapter
    },
    tags: {
      type: MappingTypes.Adapter,
      value: 'tags',
      adapter: tagAdapter
    },
    createdAt: 'created_at',
    updatedAt: 'updated_at'
  }
  preserve: boolean = false
}

export const invitationsLinkPayloadMapper = (model: InvitationsLink): Payload => {
  return {
    id: model.id,
    slug: model.slug,
    locale: model.locale,
    content: model.content,
    url: model.url,
    email: model.email,
    active: model.active,
    uses: model.uses,
    event: model.event,
    tags: model.tags,
    createdAt: model.createdAt,
    updatedAt: model.updatedAt
  }
}

export const invitationsLinkAdapter = new InvitationsLinkAdapter()
