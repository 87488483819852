
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'
import saveAs from 'file-saver'
import JSZip from 'jszip'

import { VueConstructor } from 'vue'

import { AuthorizationType } from '@contract/resources'
import { IAuthorization } from '@module/auth/contracts/services'
import { IModal, ModalType } from '@contract/modal'
import { IResponse, Query } from '@contract/http'
import { ModelsList } from '@component/models/list.vue'
import { UiStateButton } from '@component/button/state.vue'

import { Meta, Participation } from '@module/participations/contracts/models'
import { ParticipationRow } from '@module/participations/components/participation-row.vue'
import { ParticipationFilters } from '@module/participations/components/participation-filters.vue'
import { participationAdapter } from '@module/participations/adapters/participation'

@Component({
  name: 'EventParticipations',
  components: {
    ModelsList,
    ParticipationFilters,
    UiStateButton
  }
})
export class EventParticipations extends Vue {
  @Prop({ type: Array, required: true })
  public collection!: Participation[]

  @Inject(AuthorizationType)
  protected _authorization!: IAuthorization

  @Inject(ModalType)
  protected _connector!: IModal

  public exporting: boolean = false
  public loading: boolean = false
  public static perPage: number = 12
  public rowComponent: VueConstructor = ParticipationRow
  protected scope: string = 'participations'
  public downloadCounter: number = 0

  public canEdit (): boolean {
    return this._authorization.hasPermission(this.scope, 4)
  }

  created (): void {
    // this.$store.dispatch('events/fetchEventParticipations',
    //   {
    //     id: this.$route.params.id,
    //     filters: {
    //       page: 1,
    //       per_page: EventParticipations.perPage,
    //       sort: 'id'
    //     }
    //   }
    // )
  }

  public async exportParticipationTickets (): Promise<void> {
    const participations = await this.reloadAllParticipations()
    this.performTicketsExport(participations)
  }

  private async reloadAllParticipations () {
    const response = await this.$store.dispatch('events/fetchEventParticipations', {
      id: this.$route.params.id,
      skipStore: true,
      filters: {
        page: 1,
        sort: 'id',
        ...this.$route.query,
        per_page: 1000
      }
    })

    return participationAdapter.collection<Participation>(response.data.data)
  }

  private performTicketsExport (participations: Participation[]) {
    this.exporting = true

    const arrOfFiles: Promise<Blob>[] = participations.map(p => {
      return new Promise((resolve) => {
        this.$store.dispatch(
          'participations/exportTicket',
          { id: p.id }
        ).then(val => {
          this.downloadCounter = this.downloadCounter + 1
          console.log(`Downloading [${p.id}]`)
          resolve(val)
        })
      })
    })

    const zip = new JSZip()

    Promise.all(arrOfFiles)
      .then((response: Blob[] | null[]) => {
        let count = response.length

        if (response) {
          response.forEach((blob, index) => {
            const participation = participations[index]
            const filename = `ticket-${participation.id}-${participation.firstName}-${participation.lastName}.pdf`

            zip.file(filename, blob, { binary: true })
            console.info(`File [${filename}] created.`)

            count--

            if (count === 0) {
              const zipFileName = 'papaya-social.zip'
              zip.generateAsync({ type: 'blob' }).then(function (content) {
                saveAs(content, zipFileName, { autoBom: true })
                console.info(`Zip [${zipFileName}] created.`)
              })
            }
          })
        }
      }).finally(() => {
        this.exporting = false
        this.downloadCounter = 0
      })
  }

  /**
   * Exports data as csv.
   */
  public exportAsCsv (): void {
    this.exporting = true

    this.$store.dispatch(
      'events/exportParticipationsAsCsv',
      {
        id: this.$route.params.id,
        filters: this.queryParams
      }
    ).then((response: string | null) => {
      if (response !== null) {
        // @ts-ignore
        saveAs(
          response,
          `e_${this.$route.params.id}_participations_${(new Date()).getTime()}.csv`,
          { autoBom: true }
        )
      }

      this.exporting = false
    })
  }

  /**
   * Events collection meta data.
   *
   * @return {Meta}
   */
  public get meta (): Meta {
    return this.$store.getters['events/getEventMeta']
  }

  /**
   * Get current page.
   *
   * @return {number}
   */
  public get page (): number {
    if (this.$route.query.hasOwnProperty('page') && this.$route.query.page != null) {
      return parseInt(this.$route.query.page as string, 10)
    }

    return this.meta.current_page || 1
  }

  /**
   * Event participations collection getter.
   *
   * @return {Participation}
   */
  get participations (): Participation[] {
    if (!Array.isArray(this.collection)) {
      return []
    }

    return this.collection
  }

  /**
   * Get participations total count.
   *
   * @return {number}
   */
  public get participationsTotal (): number {
    if (!this.meta || !this.meta.hasOwnProperty('total') || typeof this.meta.total === 'undefined') {
      return 0
    }

    return this.meta.total
  }

  /**
   * Load participations collection.
   *
   * @param {object} params
   */
  public loadCollection (params: object): Promise<IResponse> {
    return this.$store.dispatch('events/fetchEventParticipations', {
      id: this.$route.params.id,
      filters: {
        page: 1,
        per_page: EventParticipations.perPage,
        sort: 'id',
        ...params
      }
    })
  }

  public openTagAddingModal (): void {
    this._connector.open('addTags')
  }

  /**
   * Set page and change it.
   *
   * @param {number} page
   */
  public setPage (page: number): void {
    if (this.page !== page) {
      this.$router.push({ name: 'events.edit', params: { id: this.$route.params.id }, query: { ...this.queryParams, page: `${page}` } })
    }
  }

  /**
   * Set query and re-load collection.
   *
   * @param {Query} query
   */
  public setQuery (query: Query): void {
    if (this.queryParams !== query) {
      this.$router.push({ name: 'events.edit', params: { id: this.$route.params.id }, query: query })
    }
  }

  /**
   * Get query params.
   *
   * @return {Query}
   */
  public get queryParams (): Query {
    return {
      ...this.$route.query
    }
  }

  /**
   * Watch page change and re-load collection.
   *
   * @param {Query} query
   */
  @Watch('queryParams', { deep: true, immediate: true })
  public onPageChange (query: Query): void {
    this.loading = true

    this.loadCollection(query).then((response: IResponse) => {
      if (response) {
        this.loading = false
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        this.$router.back()
      }
    })
  }
}

export default EventParticipations
