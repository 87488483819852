import Vue from 'vue'

export interface EventBus extends Vue {}

/**
 * This is generic EventBus to handle cross-components
 * eventing.
 *
 * @author  Łukasz Sitnicki <mr-luke>
 * @version 1.0.0
 *
 * Usage:
 *
 * You can emit any Event to entire application using
 * EventBus.$emit(<string> channel, <mixed> payload)
 *
 * Than to subscribe you can use
 * EventBus.$on(<string> channel, callback)
 *
 * E.g:
 *
 * mounted () {
 *  EventBus.$on('STH_HAPPEND', (payload) => {
 *    doSth(payload)
 *  })
 * }
 */
const eventbus: EventBus = new Vue()
Object.freeze(eventbus)

export default eventbus
