
import { Component, Prop, Vue } from 'vue-property-decorator'

import { composeEventRequestPayload } from '@module/events/helpers/compose-event-request-payload'
import { Event, EventMediaPurpose } from '@module/events/contracts/models'

import { EventMedia } from './event-media.vue'
import { eventPayloadMapper } from '@module/events/adapters/event'
import { IFile } from '@contract/components'

@Component({
  name: 'TabMedia',
  components: { EventMedia }
})
export class TabMedia extends Vue {
  /**
   * Form name.
   *
   * @type {string}
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Event model.
   */
  @Prop({ type: Object, required: true })
  public model!: Event

  /**
   * Form save button text.
   *
   * @type {string}
   */
  @Prop({ type: String, required: false, default: 'Zapisz zmiany' })
  public saveBtnText!: string;

  /**
   * Is form saving.
   *
   * @type {boolean}
   */
  public saving: boolean = false;

  /**
   * Set cover file.
   */
  public async onCoverChanged (file: File, purpose: EventMediaPurpose, locale: string): Promise<void> {
    this.saving = true
    let mediaProperty = 'cover'

    const cover: IFile = await this.updateMedia(file, purpose, locale)

    if (purpose === EventMediaPurpose.EmailCover) {
      mediaProperty = 'emailCover'
    }

    if (cover && 'id' in cover && this.model[mediaProperty]) {
      this.model[mediaProperty][locale] = cover['id'] as number
    }

    this.saving = false
  }

  /**
   * Set cover file.
   */
  public async onFileChanged (file: File): Promise<void> {
    this.saving = true
    await this.updateMedia(file, EventMediaPurpose.Partner)
    this.saving = false
  }

  /**
   * Submit form handler.
   */
  public async onSubmit (): Promise<void> {
    this.saving = true
    await this.updateEvent()
    this.saving = false
  }

  protected async updateMedia (file: File, purpose: EventMediaPurpose, locale: string = 'pl'): Promise<IFile> {
    return this.$store.dispatch('events/uploadEventMedia', {
      params: { eventId: this.$route.params.id },
      data: composeEventRequestPayload(file, purpose, locale)
    })
  }

  protected async updateEvent (): Promise<void> {
    await this.$store.dispatch('events/updateSingleEvent', {
      params: { eventId: this.$route.params.id },
      data: eventPayloadMapper(this.model)
    })
  }
}
export default TabMedia
