
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'
import { VueConstructor } from 'vue'

import { IModal, ModalPayload, ModalState, ModalType } from '@contract/modal'
import { IWindow, WindowType } from '@service/window'

@Component({
  name: 'UiModal'
})
export class UiModal extends Vue {
  @Inject(ModalType)
  protected _connector!: IModal

  @Inject(WindowType)
  private _window!: IWindow

  public opened: boolean = false
  public payload: ModalPayload = {}
  public size: string = ''

  get component (): VueConstructor|null {
    if (!this.opened) {
      return null
    }

    return this._connector.component
  }

  created () {
    this._connector.subscribe((payload: ModalState) => {
      this.payload = payload.payload
      this.opened = payload.opened
      this.size = payload.size
    })
  }

  closeModal (): void {
    this._connector.close()
    this.payload = {}
  }

  get modalSize (): string {
    if (this.size.length === 0) {
      return ''
    }

    return `modal-${this.size}`
  }

  @Watch('opened')
  onOpenChange (next: boolean) {
    if (this._window.isClient) {
      if (next) {
        document.body.classList.add('locked')
      } else {
        document.body.classList.remove('locked')
      }
    }
  }
}
export default UiModal
