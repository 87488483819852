import { ActionContext } from 'vuex'

import { container } from '@/bootstrap/app'
import { IResponse, Payload } from '@contract/http'
import { IResources, ResourcesType } from '@contract/resources'

import { Participation, State, Meta, Tag } from './contracts/models'
import { participationAdapter } from './adapters/participation'

export const participationModuleStore = {
  namespaced: true,
  state: {
    meta: {
      total: 0
    },
    participationsList: [],
    participationSingle: {
      event: {}
    },
    tags: []
  },
  actions: {
    clearTagsList ({ commit }: ActionContext<State, any>) {
      commit('setTagsList', [])
    },
    async deleteSingleParticipation ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('participations', 'delete',
        { participationId: payload.id },
        {}
      )

      if (!response.hasErrors()) {
        commit('removeSingleParticipation', payload.id)
      }

      return response
    },
    async exportTicket (store: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'participations',
        'exportTicket',
        {
          participationId: payload.id
        },
        {},
        {
          'Accept': 'application/octet-stream'
        },
        'blob'
      )

      if (response.isSuccessful()) {
        return response.data
      }

      return null
    },
    async exportTickets (store: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'participations',
        'exportTickets',
        {
        //   participationId: payload.id
        },
        {
          participations: payload.participations
        },
        {
          'Accept': 'application/octet-stream'
        },
        'blob'
      )

      if (response.isSuccessful()) {
        return response.data
      }

      return null
    },
    async fetchParticipationsList ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'participations',
        'list',
        {},
        payload
      )

      if (response.isSuccessful()) {
        commit('setParticipationsList', response.data.data)
        commit('setMeta', response.data.meta)
      }

      return response
    },
    async fetchSingleParticipation ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('participations', 'show',
        { participationId: payload.id },
        {}
      )

      if (!response.hasErrors()) {
        commit('setSingleParticipation', response.data.data)
      }

      return response
    },
    async fetchTagsList ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'tags',
        'list',
        {},
        payload
      )

      if (response.isSuccessful()) {
        commit('setTagsList', response.data.data)
      }

      return response
    },
    async updateSingleParticipation ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('participations', 'update',
        { participationId: payload.params.id },
        payload.data
      )

      if (!response.hasErrors()) {
        commit('setSingleParticipation', response.data.data)
      }

      return response
    },
    async updateParticipationTags ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('participations', 'updateTags',
        { participationId: payload.params.id },
        payload.data
      )

      if (!response.hasErrors()) {
        commit('setSingleParticipation', response.data.data)
      }

      return response
    },
    async resetSingleParticipationState ({ commit }: ActionContext<State, any>) {
      commit('setSingleParticipation', {
        contact: {}
      })
    }
  },
  getters: {
    getMeta: (state: State): Meta => {
      return state.meta
    },
    getParticipationsList: (state: State): Participation[] => {
      return state.participationsList
    },
    getSingleParticipation: (state: State): Participation => {
      return state.participationSingle
    },
    getTagsList: (state: State): Tag[] => {
      return state.tags
    }
  },
  mutations: {
    removeSingleParticipation: (state: State, id: number) => {
      const flatParticipationsList = state.participationsList.map((participation: Participation) => participation.id)
      const index = flatParticipationsList.indexOf(id)
      if (index !== -1) {
        state.participationsList.splice(index, 1)
      }
    },
    setParticipationsList (state: State, collection: Participation[]) {
      state.participationsList = participationAdapter.collection<Participation>(collection)
    },
    setMeta: (state: State, meta: Meta) => {
      state.meta = meta
    },
    setTagsList: (state: State, tags: Tag[]) => {
      state.tags = tags
    },
    setSingleParticipation: (state: State, participation: Participation) => {
      state.participationSingle = participationAdapter.model<Participation>(participation)
    }

  }
}

export default participationModuleStore
