import { UsersList } from '@module/users/views/users/list.vue'
import { EditUser } from '@module/users/views/users/edit.vue'
import { CreateUser } from '@module/users/views/users/create.vue'

import { RolesList } from '@module/users/views/roles/list.vue'
import { EditRole } from '@module/users/views/roles/edit.vue'
import { CreateRole } from '@module/users/views/roles/create.vue'

export const usersModuleRoutes = [

  {
    path: 'users',
    name: 'list',
    component: UsersList,
    meta: { requiresAuth: true, permission: 'users', level: 1 }
  },
  {
    path: 'users/create',
    name: 'create',
    component: CreateUser,
    meta: { requiresAuth: true, permission: 'users', level: 4 }
  },
  {
    path: 'users/:id',
    name: 'show',
    component: EditUser,
    meta: { requiresAuth: true, permission: 'users', level: 1 }
  },

  {
    path: 'roles',
    name: 'rolesList',
    component: RolesList,
    meta: { requiresAuth: true, permission: 'users', level: 1 }
  },
  {
    path: 'roles/create',
    name: 'rolesCreate',
    component: CreateRole,
    meta: { requiresAuth: true, permission: 'users', level: 4 }
  },
  {
    path: 'roles/:id',
    name: 'rolesShow',
    component: EditRole,
    meta: { requiresAuth: true, permission: 'users', level: 1 }
  }
]
