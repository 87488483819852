<template lang="html">
  <span class="badge" :class="{'badge-pill': pillow}">
    <slot></slot>
  </span>
</template>

<script>
export const Badge = {
  name: 'Badge',
  props: {
    pillow: {
      type: Boolean,
      required: false
    }
  }
}

export default Badge
</script>
