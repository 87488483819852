
import { Component, Prop, Vue } from 'vue-property-decorator'

import { EventInvitations } from './event-invitations.vue'
import { Invitation } from '@module/invitations/contracts/models'

@Component({
  name: 'TabInvitations',
  components: { EventInvitations }
})
export class TabInvitations extends Vue {
  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Event invitation collection getter.
   *
   * @return {Invitation[]}
   */
  get collection (): Invitation[] {
    return [...this.$store.getters['events/getEventInvitations']]
  }
}
export default TabInvitations
