import { Adapter, MappingConfig } from '@support/adapter'
import { Payload } from '@contract/http'
import { Tag } from '@module/participations/contracts/models'

class TagAdapter extends Adapter {
  mapping: MappingConfig = {
    id: 'id',
    label: 'label',
    createdAt: 'created_at',
    updatedAt: 'updated_at'
  }
  preserve: boolean = false
}
export const tagAdapter = new TagAdapter()

export const tagPayloadMapper = (model: Tag): Payload => {
  return {
    id: model.id,
    label: model.label
  }
}

export default {
  tagAdapter: tagAdapter,
  tagPayloadMapper: tagPayloadMapper
}
