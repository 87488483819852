import Vue from 'vue'
import VueRouter from 'vue-router'
import { Route, RouteConfig } from 'vue-router/types/router'

import { routes as authRoutes } from '@module/auth/module'
import { AuthorizationType } from '@contract/resources'
import { container } from '@/bootstrap/app'
import { IAuthorization } from '@module/auth/contracts/services'
import { ModulesBootstraper } from '@/bootstrap/modules'

import App from '@view/app.vue'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: '/app',
    component: App,
    meta: { requiresAuth: true },
    children: [
      ...ModulesBootstraper.instance().resolvedRouting
    ]
  },
  ...authRoutes,
  {
    path: '/404',
    name: 'not-found',
    component: App,
    meta: { requiresAuth: false }
    // component: ErrorPage404,
    // component: () => import(/* webpackChunkName: "about" */ './shared/views/About.vue')
    // (about.[hash].js)
  },
  { path: '/', redirect: '/app/events', meta: { requiresAuth: true } },
  { path: '*', redirect: '/404' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * Global guard handle loggedIn state.
 */
router.beforeEach((to: Route, from: Route, next) => {
  const authorizeService: IAuthorization = container.get(AuthorizationType)

  if (!authorizeService.check() && to.matched.some(record => record.meta.requiresAuth)) {
    authorizeService.refresh().then((result) => {
      result ? next() : next({ name: 'login' })
    })
  } else {
    next()
  }
})

/**
 * Global guard handle min required level.
 */
router.beforeEach((to: Route, from: Route, next) => {
  const authorizeService: IAuthorization = container.get(AuthorizationType)

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (authorizeService.hasLevel(3)) {
      next()
    } else {
      authorizeService.logout().then(() => {
        next({ name: 'login' })
      })
    }
  } else {
    next()
  }
})

/**
 * Global guard handle role state.
 */
router.beforeEach((to: Route, from: Route, next) => {
  const authorizeService: IAuthorization = container.get(AuthorizationType)

  if (to.matched.some(record => record.meta.permission)) {
    let level = 1

    if (!to.matched.some(record => record.meta.level)) {
      level = to.meta!.level
    }
    authorizeService.hasPermission(to.meta!.permission, level, to.meta!.restriction || 'all') ? next() : next({ name: 'not-found' })
  } else {
    next()
  }
})

/**
 * Redirect to events from main dashboard route.
 */
router.beforeEach((to: Route, from: Route, next) => {
  if (to.path === '/app') {
    next({
      path: '/app/events'
    })
  }
  next()
})

export default router
