
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { AuthorizationType } from '@contract/resources'
import { IAuthorization } from '@module/auth/contracts/services'
import { ISelectOption } from '@contract/components'
import { UiStateButton } from '@component/button/state.vue'

import { Event, EventStatus } from '../contracts/models'
import { EventFormBasicFields } from './event-form-basic-fields.vue'
import { EventFormContactFields } from './event-form-contact-fields.vue'
import { EventFormAdditionalFields } from './event-form-additional-fields.vue'
import { EventFormLinksFields } from '@module/events/components/event-form-links-fields.vue'
import { eventStatusLabel } from '../helpers/event-status-label'

@Component({
  name: 'EventForm',
  components: {
    EventFormBasicFields,
    EventFormContactFields,
    EventFormAdditionalFields,
    EventFormLinksFields,
    UiStateButton
  }
})
export class EventForm extends Vue {
  @Inject(AuthorizationType)
  protected _authorization!: IAuthorization
  protected scope: string = 'events'

  public canEdit (): boolean {
    return this._authorization.hasPermission(this.scope, 4)
  }

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Event model.
   */
  @Prop({ type: Object, required: true })
  public model!: Event

  /**
   * Is form saving.
   */
  @Prop({ type: Boolean, required: true })
  public saving!: boolean;

  /**
   * Form save button text.
   */
  @Prop({ type: String, required: false, default: 'Zapisz zmiany' })
  public saveBtnText!: string;

  /**
   * Is saving in progress?
   */
  public savingInProgress: boolean = this.saving;

  /**
   * Statuses select options.
   *
   * @return {ISelectOption[]}
   */
  public get statusesSelectOptions (): ISelectOption[] {
    return [
      { value: EventStatus.Draft, label: eventStatusLabel(EventStatus.Draft) },
      { value: EventStatus.Public, label: eventStatusLabel(EventStatus.Public) },
      { value: EventStatus.Archive, label: eventStatusLabel(EventStatus.Archive) }
    ]
  }

  /**
   * Logical select options.
   *
   * @return {ISelectOption[]}
   */
  public get logicalSelectOptions (): ISelectOption[] {
    return [
      { value: 1, label: 'Tak' },
      { value: 0, label: 'Nie' }
    ]
  }

  /**
   * Priority select options.
   *
   * @return {ISelectOption[]}
   */
  public get prioritySelectOptions (): ISelectOption[] {
    const priorityOptions: ISelectOption[] = []

    for (let i = 0; i <= 10; i++) {
      priorityOptions.push({ value: i, label: i })
    }

    return priorityOptions
  }

  /**
   * Submit form handler.
   */
  public onSubmit (): void {
    this.$emit('submit')
  }

  public onCoverChanged (file: File, locale: string) {
    this.$emit('coverChanged', file, locale)
  }

  /**
   * Saving prop property watcher.
   *
   * @param {boolean} isSaving
   */
  @Watch('saving', { deep: true })
  public onSavingChange (isSaving: boolean): void {
    this.savingInProgress = isSaving
  }
}

export default EventForm
