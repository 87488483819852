
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Subject, Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

import { IModal, IModalComponent, ModalPayload, ModalType } from '@contract/modal'
import { Inject } from '@plugin/inversify'
import { Participation, Tag } from '@module/participations/contracts/models'

import { UiInput } from '@component/form/input.vue'
import { UiStateButton } from '@component/button/state.vue'
import { IResponse, Query } from '@contract/http'

@Component({
  name: 'AddTagsModal',
  components: {
    UiInput,
    UiStateButton
  }
})
export class AddTagsModal extends Vue implements IModalComponent {
  @Prop({ required: true })
  public payload!: ModalPayload

  @Inject(ModalType)
  protected _connector!: IModal

  public errorOccured: boolean = false
  public loading: boolean = false
  public loadingTags: boolean = false
  public search: string = ''
  protected subscription!: Subscription
  public tags: string[] = []
  protected querySubject!: Subject<string>

  /**
   * Tag models collection getter.
   *
   * @return {Tag[]}
   */
  public get existingTags (): Tag[] {
    return this.$store.getters['participations/getTagsList']
  }

  /**
   * Event fields collection getter.
   *
   * @return {Participation}
   */
  public get participations (): Participation[] {
    return [...this.$store.getters['events/getEventParticipations']]
  }

  /**
   * Get query params.
   *
   * @return {Query}
   */
  public get queryParams (): Query {
    return {
      ...this.$route.query
    }
  }

  /**
   * Mounted Vue hook.
   */
  public mounted (): void {
    this.querySubject = new Subject()
    this.subscription = this.querySubject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((query: string) => this.onTagsSearch(query))
  }

  public addTag (tag: string): void {
    this.tags.push(tag)
  }

  public addTagsToParticipations (): void {
    this.loading = true
    this.errorOccured = false

    this.$store.dispatch('events/addTagsToParticipations', {
      ...this.queryParams,
      event_id: this.$route.params.id,
      tags_to_add: this.tags
    }).then((response: IResponse) => {
      if (response.isSuccessful()) {
        setTimeout(() => {
          location.reload()
        }, 1000)
      } else {
        this.errorOccured = true
      }
    }).finally(() => {
      this.loading = false
    })
  }

  public addSearchAsTag (): void {
    this.tags.push(this.search)
    this.search = ''
  }

  /**
   * Check if tag is already added from existing tags
   *
   * @return boolean
   */
  public checkIfSearchAlreadyInTags (): boolean {
    return this.existingTags.map((tag: Tag) => tag.label).indexOf(this.search) !== -1
  }

  public checkIsTagChoosed (tag: string): boolean {
    return this.tags.indexOf(tag) !== -1
  }

  /**
   * Close modal.
   */
  public close (): void {
    this._connector.close()
  }

  /**
   * Call to search action
   *
   * @return void
   */
  public async onTagsSearch (query: string) {
    if (query.length) {
      this.loadingTags = true
      await this.$store.dispatch('participations/fetchTagsList', {
        q: query
      })
      this.loadingTags = false
    } else {
      await this.$store.dispatch('participations/clearTagsList')
    }
  }

  public removeTag (tag: string): void {
    const index: number = this.tags.indexOf(tag)

    if (index > -1) {
      this.tags.splice(index, 1)
    }
  }

  /**
   * search property watcher
   */
  @Watch('search', { deep: true })
  public onSearchTagsChange (search: string): void {
    this.querySubject.next(search)
  }
}

export default AddTagsModal
