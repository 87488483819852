import { AppModule, Module } from '@support/modules'

import { fieldModuleStore } from './store'
import { fieldsModuleRoutes } from './routes'

@AppModule({
  name: 'fields',
  routes: fieldsModuleRoutes,
  state: fieldModuleStore
})
export class FieldsModule extends Module {}
export default FieldsModule
