import { VueConstructor } from 'vue'
import { MeModel } from '@module/auth/contracts/models'
import { EventMediaPurpose } from '@module/events/contracts/models'

export enum FilterFieldType {
  Insert = 'input',
  Selection = 'select',
  Date = 'date'
}

export interface IAvatar {
  variants: IPicture[]
  title: string
  alt?: string
}

export interface IFilterField {
  type: FilterFieldType,
  name: string,
  default: string | null,
  options?: ISelectOption[],
  size?: string,
  placeholder?: string,
  range?: boolean,
  datePickerType?: string,
  datePickerFormat?: string
}

export interface IFile {
  id: number,
  directory: number,
  hasVariants: boolean,
  purpose: EventMediaPurpose,
  file: IFileData,
  mime: string,
  size: number,
  name: string,
  author?: string,
  capture?: string,
  title: string,
  alt?: string,
  note: string,
  addedBy: MeModel,
  uploadedAt: string
}

export interface IFileData {
  thumbnail?: string,
  url?: string
  variants?: IFileDataVariant[]
}

export interface IFileDataVariant {
  width: number,
  url: string
}

export interface IImage {
  src?: string
  variants?: IPicture[]
  title: string
  alt?: string
}

export interface IPicture {
  width: number
  url: string
}

export interface ISelectOption {
  label: string | number
  value: string | number | boolean | null
}

export interface ITabConfig {
  title: string
  component: VueConstructor
  props?: ITabProp
}

export interface ITabProp {
  [key: string]: any
}

export interface IVideo {
  type: string
  resolution: string
  source: string
  uploadedAt: string
}

export interface IDateFieldTimePickerOptions {
  start: string,
  step: string,
  end: string
}
