
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { AuthorizationType } from '@contract/resources'
import { IAuthorization } from '@module/auth/contracts/services'
import { UiStateButton } from '@component/button/state.vue'

import { Event, EventMediaPurpose } from '../contracts/models'
import { EventFormImages } from './event-form-images.vue'

@Component({
  name: 'EventMedia',
  components: {
    EventFormImages,
    UiStateButton
  }
})
export class EventMedia extends Vue {
  @Inject(AuthorizationType)
  protected _authorization!: IAuthorization
  protected scope: string = 'events'

  public canEdit (): boolean {
    return this._authorization.hasPermission(this.scope, 4)
  }

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Event model.
   */
  @Prop({ type: Object, required: true })
  public model!: Event

  /**
   * Is form saving.
   */
  @Prop({ type: Boolean, required: true })
  public saving!: boolean;

  /**
   * Form save button text.
   */
  @Prop({ type: String, required: false, default: 'Zapisz zmiany' })
  public saveBtnText!: string;

  /**
   * Is saving in progress?
   */
  public savingInProgress: boolean = this.saving;

  /**
   * Submit form handler.
   */
  public onSubmit (): void {
    this.$emit('submit')
  }

  /**
   * Handle event onCoverChanged
   * Emit onCoverChanged event to parent.
   */
  public onCoverChanged (file: File, purpose: EventMediaPurpose, locale: string) {
    this.$emit('coverChanged', file, purpose, locale)
  }

  /**
   * Handle event fileChanged
   * Emit fileChanged event to parent.
   */
  public onFileChanged (file: File) {
    this.$emit('fileChanged', file)
  }

  /**
   * Saving prop property watcher.
   *
   * @param {boolean} isSaving
   */
  @Watch('saving', { deep: true })
  public onSavingChange (isSaving: boolean): void {
    this.savingInProgress = isSaving
  }
}

export default EventMedia
