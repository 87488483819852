import { ModuleTree } from 'vuex/types/index'
import { RouteConfig } from 'vue-router/types/router'
import { IModulesBootstraper, IModule, ProviderConfig } from '@support/modules'

import { registry } from '@/config/modules'

let instance: IModulesBootstraper|null = null

export class ModulesBootstraper implements IModulesBootstraper {
  private modules: IModule[]

  constructor () { this.modules = registry }

  /**
   * Build singleton instance of bootstraper.
   */
  static instance (): IModulesBootstraper {
    if (instance === null) {
      instance = new ModulesBootstraper()
    }

    return instance
  }

  /**
   * Return registered providers module.
   */
  get resolvedProviders (): ProviderConfig[] {
    const providers: ProviderConfig[] = []

    for (const m of this.modules) {
      let mProviders: ProviderConfig|null = m.providers()

      if (mProviders !== null) {
        providers.push(mProviders)
      }
    }

    return providers
  }

  /**
   * Return registered modules' routing.
   */
  get resolvedRouting (): RouteConfig[] {
    const routing: RouteConfig[] = []

    for (const m of this.modules) {
      routing.push(...m.routes())
    }

    return routing
  }

  /**
   * Return registerd modules' state.
   */
  get resolvedStates (): ModuleTree<any> {
    let tree: ModuleTree<any> = {}

    for (const m of this.modules) {
      let state = m.state()

      if (state !== null && typeof state !== 'undefined') {
        Object.assign(tree, state)
      }
    }

    return tree
  }
}
