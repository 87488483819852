
import { Component, Vue } from 'vue-property-decorator'

import Block from '@component/block.vue'
import { Breadcrumbs } from '@component/breadcrumbs.vue'
import { IResponse } from '@contract/http'
import { ViewContent } from '@view/partials/content.vue'

import { fieldPayloadMapper } from '../adapters/field'
import { Field } from '../contracts/models'
import { FieldForm } from '@module/fields/components/field-form.vue'
import { AccessableComponent } from '@module/auth/shared/access-helpers'

@Component({
  name: 'CreateField',
  components: { Block, Breadcrumbs, FieldForm, ViewContent }
})
export class CreateField extends AccessableComponent {
  protected scope: string = 'events'
  /**
   * Is saving in progress.
   */
  public saving: boolean = false;

  /**
   * Form save button text..
   */
  public saveBtnText: string = 'Dodaj pole';

  /**
   * Form name.
   */
  public formName: string = 'createFields';

  /**
   * Breadcrumbs data getter.
   *
   * @return {object[]}
   */
  get breadcrumbs (): object[] {
    return [
      { label: 'Dodaj nowe pole' },
      { label: this.model.name }
    ]
  }

  /**
   * Field model getter.
   *
   * @return {Field}
   */
  get model (): Field {
    return this.$store.getters['fields/getSingleField']
  }

  /**
   * Create form action.
   *
   * @return {Promise<IResponse>}
   */
  public async createForm (): Promise<void> {
    const response = await this.$store.dispatch('fields/createSingleField', {
      params: {}, data: fieldPayloadMapper(this.model)
    })

    if (!response.hasErrors()) {
      this.$router.push({ name: 'fields.edit', params: { id: response.data.data.id } })
    }
  }

  /**
   * Destroyed Vue hook.
   */
  public destroyed (): void {
    this.$store.dispatch('fields/resetSingleFieldState')
  }

  /**
   * Submit form handler.
   */
  public onSubmitHandler (): void {
    this.saving = true

    this.createForm().finally(() => {
      this.saving = false
    })
  }
}

export default CreateField
