import { Adapter, MappingConfig } from '@support/adapter'

class PermissionAdapter extends Adapter {
  mapping: MappingConfig = {
    level: 'level',
    scope: 'scope'
  }
  preserve: boolean = false
}
export const permissionAdapter = new PermissionAdapter()

export default {
  permissionAdapter: permissionAdapter
}
