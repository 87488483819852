import { VueConstructor } from 'vue'
import { extentions } from '@/config/extentions'

/**
 * Register all Vue extentions.
 *
 * @author Łukasz Sitnicki <lukasz.sitnici@movecloser.pl>
 * @version 1.0.0
 */
export function registerExtentions (Vue: VueConstructor): void {
  // Register all custom directives
  for (const [name, handler] of Object.entries(extentions.directives)) {
    Vue.directive(name, handler)
  }
  // Register all custom filters
  for (const [name, filter] of Object.entries(extentions.filters)) {
    Vue.filter(name, filter)
  }
}
