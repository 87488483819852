/**
 * Filter that uppercase first letter.
 *
 * @param  {String} value
 * @return {String}
 */
export default function (value: string|null): string {
  if (!value) {
    return ''
  }

  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}
