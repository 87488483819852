<template lang="html">
  <div class="elements">
    <slot></slot>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { ContainerMixin } from 'vue-slicksort'

@Component({
  name: 'DuplicatorWrapper',
  mixins: [ContainerMixin]
})

export class DuplicatorWrapper extends Vue {}

export default DuplicatorWrapper
</script>
