
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import EventBus from '@service/eventbus'
import { IFile } from '@contract/components'
import { UiImage } from '@component/image.vue'
import { Event as EventModel, EventMediaPurpose } from '@module/events/contracts/models'

import { IModal, IModalComponent, ModalPayload, ModalType } from '@contract/modal'

@Component({
  name: 'SendInvitationsAgainModal',
  components: { UiImage }
})
export class SendInvitationsAgainModal extends Vue implements IModalComponent {
  @Prop({ required: true })
  public payload!: ModalPayload

  @Inject(ModalType)
  protected _connector!: IModal

  /**
   * Accept sending and close modal.
   */
  public send (): void {
    EventBus.$emit('sendingConfirmed')
    this.close()
  }

  /**
   * Close modal.
   */
  public close (): void {
    this._connector.close()
  }
}

export default SendInvitationsAgainModal
