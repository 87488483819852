
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import EventBus from '@service/eventbus'
import { UiSelect } from '@component/form/select.vue'
import { UiInput } from '@component/form/input.vue'

import { IModal, IModalComponent, ModalPayload, ModalType } from '@contract/modal'
import { ParticipationTagFilterTypes, Tag } from '@module/participations/contracts/models'
import { ISelectOption } from '@contract/components'
import { Subject, Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

@Component({
  name: 'ParticipationTagModal',
  components: { UiSelect, UiInput }
})
export class ParticipationTagModal extends Vue implements IModalComponent {
  @Prop({ required: true })
  public payload!: ModalPayload

  @Inject(ModalType)
  protected _connector!: IModal

  /**
   * Search for tag label
   */
  public loadingTags: boolean = false

  /**
   * Search for tag label
   */
  public search: string = ''

  /**
   * RxJS subscription object.
   *
   * @type {Subscription}
   */
  protected subscription!: Subscription
  public tagFilterType: ParticipationTagFilterTypes = ParticipationTagFilterTypes.Or;
  public tags: String[] = [];

  /**
   * RxJS subject object.
   *
   * @type {Subject<Query>}
   */
  protected querySubject!: Subject<string>

  public get tagFilterTypes (): ISelectOption[] {
    return [
      {
        label: 'przynajmniej jeden tag ze wskazanych',
        value: ParticipationTagFilterTypes.Or
      },
      {
        label: 'wszystkie tagi ze wskazanych',
        value: ParticipationTagFilterTypes.And
      }
    ]
  }

  /**
   * Tag models collection getter.
   *
   * @return {Tag[]}
   */
  public get existingTags (): Tag[] {
    return this.$store.getters['participations/getTagsList']
  }

  /**
   * Created Vue hook.
   */
  public mounted (): void {
    this.querySubject = new Subject()
    this.subscription = this.querySubject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((query: string) => this.onTagsSearch(query))
  }

  public destroyed () {
    this.querySubject.unsubscribe()
  }

  /**
   * Add existing tag
   *
   * @return void
   */
  public addExistingTag (tag: Tag): void {
    if (this.tags) {
      this.tags.push(tag.label)
    }
  }

  public applyFilters (): void {
    EventBus.$emit('apply-tags-filters', {
      tags_type: this.tagFilterType,
      tags: this.tags
    })

    this.close()
  }

  /**
   * Check if tag existing tag is already in use
   *
   * @return boolean
   */
  public checkIfTagAlreadyInExisted (label: string): boolean {
    return this.tags.indexOf(label) !== -1
  }

  /**
   * Close modal.
   */
  public close (): void {
    this._connector.close()
  }

  /**
   * Call to search action
   *
   * @return void
   */
  public async onTagsSearch (query: string) {
    if (query.length) {
      this.loadingTags = true
      await this.$store.dispatch('participations/fetchTagsList', {
        q: query
      })
      this.loadingTags = false
    } else {
      this.$store.dispatch('participations/clearTagsList')
    }
  }

  /**
   * Remove existing tag
   *
   * @return void
   */
  public removeExistingTag (tag: string): void {
    const index = this.tags.indexOf(tag)

    if (index !== -1) {
      this.tags.splice(index, 1)
    }
  }

  /**
   * search property watcher
   */
  @Watch('search', { deep: true })
  public onSearchTagsChange (search: string): void {
    this.querySubject.next(search)
  }
}

export default ParticipationTagModal
