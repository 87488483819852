import { ModalRegistry } from '@contract/modal'
import AddTagsModal from '@module/events/components/add-tags-modal.vue'
import FieldsAddModal from '@module/events/components/fields-add-modal.vue'
import ImagesAddModal from '@module/events/components/images-add-modal.vue'
import ParticipationTagModal from '@module/participations/components/participation-tag-modal.vue'
import SendInvitationsAgainModal from '@module/events/components/send-invitations-again-modal.vue'

export const modalRegistry: ModalRegistry = {
  addTags: AddTagsModal,
  fieldsAdd: FieldsAddModal,
  imagesAdd: ImagesAddModal,
  confirmSendingEmails: SendInvitationsAgainModal,
  multiTagFiltering: ParticipationTagModal
}

export default modalRegistry
