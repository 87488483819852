
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'
import { Subscription } from 'rxjs'

import { ISelectOption } from '@contract/components'
import { IModal, ModalType } from '@contract/modal'
import { IValidation, ValidationType } from '@contract/validation'
import { UiDuplicator } from '@component/duplicator.vue'
import { UiInput } from '@component/form/input.vue'
import { UiSelect } from '@component/form/select.vue'
import { UiStateButton } from '@component/button/state.vue'

import { Field } from '@module/fields/contracts/models'
import { FieldsDetails } from '@module/fields/shared/field-details.vue'

@Component({
  name: 'EventFields',
  components: {
    FieldsDetails,
    UiInput,
    UiSelect,
    UiDuplicator,
    UiStateButton
  }
})
export class EventFields extends Vue {
  @Inject(ModalType)
  protected _connector!: IModal

  @Inject(ValidationType)
  private _validation!: IValidation

  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Fields collection.
   */
  @Prop({ type: Array, required: true })
  public collection!: Field[]

  /**
   * Is form saving.
   */
  @Prop({ type: Boolean, required: true })
  public saving!: boolean;

  /**
   * Form save button text.
   */
  @Prop({ type: String, required: false, default: 'Zapisz zmiany' })
  public saveBtnText!: string;

  /**
   * Is saving in progress?
   */
  public savingInProgress: boolean = this.saving;

  /**
   * Single item for duplicator
   */
  public singleItem = {} as Field

  /**
   * Validation subscription
   */
  private _vSubscription!: Subscription

  /**
   * Validation errors
   */
  public errors: string[] = []

  /**
   * Event fields collection getter.
   *
   * @return {Field}
   */
  get fields (): Field[] {
    if (!Array.isArray(this.collection)) {
      return []
    }

    let collection = this.collection

    return this.collection.map((f: Field) => {
      if (!f.comment) {
        f.comment = {
          pl: '',
          en: ''
        }
      }
      return f
    })
  }

  set fields (value) {
    this.$emit('update:collection', value)
  }

  /**
   * Logical select options.
   *
   * @return {ISelectOption[]}
   */
  public get logicalSelectOptions (): ISelectOption[] {
    return [
      { value: 1, label: 'Tak' },
      { value: 0, label: 'Nie' }
    ]
  }

  created () {
    this._vSubscription = this._validation.onFormErrors(this.formName, (errors: any[]) => {
      this.errors = errors
    })
  }

  destroyed (): void {
    this._vSubscription.unsubscribe()
  }

  public onFieldAdd () {
    this._connector.open('fieldsAdd')
  }

  public onItemsUpdate (items: Field[]) {
    this.$emit('update:items', items)
  }

  /**
   * Submit form handler.
   */
  public onSubmit (): void {
    this.$emit('submit')
  }

  /**
   * Saving prop property watcher.
   *
   * @param {boolean} isSaving
   */
  @Watch('saving', { deep: true })
  public onSavingChange (isSaving: boolean): void {
    this.savingInProgress = isSaving
  }
}

export default EventFields
