
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ISelectOption } from '@contract/components'
import { UiInput } from '@component/form/input.vue'
import { UiSelect } from '@component/form/select.vue'

import { Field } from '../../contracts/models'

@Component({
  name: 'FieldAdditionalSettings',
  components: {
    UiInput,
    UiSelect
  }
})
export class FieldAdditionalSettings extends Vue {
  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string

  /**
   * Field model.
   */
  @Prop({ required: true })
  public model!: Field
  /**
   * Type select options.
   *
   * @return {ISelectOption[]}
   */
  public get typeSelectOptions (): ISelectOption[] {
    return [
      { value: null, label: 'Domyślna' },
      { value: 'datetime', label: 'Czas' },
      { value: 'email', label: 'Email' },
      { value: 'longText', label: 'Długi tekst' },
      { value: 'secret', label: 'Pole bezpieczne' }
    ]
  }
}

export default FieldAdditionalSettings
