
import { Component, Vue } from 'vue-property-decorator'

import { IResponse } from '@contract/http'

import Block from '@component/block.vue'
import { Breadcrumbs } from '@component/breadcrumbs.vue'
import { ViewContent } from '@view/partials/content.vue'

import { Action } from '../contracts/models'
import { ActionCreateForm } from '../components/action-create-form.vue'
import { actionPayloadMapper } from '../adapters/actions'
import { AccessableComponent } from '@module/auth/shared/access-helpers'

@Component({
  name: 'CreateAction',
  components: { ActionCreateForm, Block, Breadcrumbs, ViewContent },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch('actions/resetActionState')
    })
  }
})
export class CreateAction extends AccessableComponent {
  protected scope: string = 'events'

  /**
   * Is form saving.
   *
   * @type {boolean}
   */
  public saving: boolean = false;

  /**
   * Action model getter.
   *
   * @return {Action}
   */
  get model (): Action {
    return this.$store.getters['actions/getAction']
  }

  /**
   * Breadcrumbs data getter.
   *
   * @return {object[]}
   */
  get breadcrumbs (): object[] {
    return [
      { label: 'Dodaj nową akcję', route: 'create' },
      { label: this.model.title }
    ]
  }

  /**
   * Destroyed Vue hook.
   */
  public destroyed (): void {
    this.$store.dispatch('actions/resetActionState')
  }

  /**
   * Submit form handler.
   */
  public async onSubmit (): Promise<void> {
    this.saving = true

    await this.createAction()

    this.saving = false
  }

  /**
   * Create event.
   */
  protected async createAction (): Promise<void> {
    const response: IResponse = await this.$store.dispatch('actions/createAction', {
      params: {
        id: this.model.id
      },
      data: actionPayloadMapper(this.model)
    })

    if (response && !response.hasErrors()) {
      await this.$router.push( // if created successfully, redirect to edit form
        { name: 'actions.edit', params: { id: response.data.data.id } }
      )
    }
  }
}

export default CreateAction
