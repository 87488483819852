import { injectable } from 'inversify'
import { IDocument } from '@service/document'

export interface IWindow {
  addEventListener (name: any, handler: (this: Window, ev: any) => any, options?: any): void
  call: (method: string, params: any[]) => any
  document: IDocument
  isClient: boolean
  isServer: boolean
  removeEventListener (name: any, handler: (this: Window, ev: any) => any, options?: any): void
}

export const WindowType = Symbol.for('IWindow')

/**
 * Provides window object access and functionalities.
 *
 * @author  Kuba Fogel <kuba.fogel@movecloser.pl>
 * @version 1.0.0
 */
@injectable()
export class WindowService implements IWindow {
  /**
   * Service that implements IDocument.
   *
   * @var IDocument
   */
  private readonly _document: IDocument

  /**
   * Determine if window object is available (Client vs SSR).
   *
   * @var boolean
   */
  private readonly _isDefined: boolean = true

  constructor (document: IDocument) {
    this._document = document

    if (!WindowService.isDefined) {
      this._isDefined = false
    }
  }

  /**
   * Add listener to document object (if defined)
   *
   * @return void
   */
  public addEventListener (name: any, handler: (this: Window, ev: any) => any, options?: any): void {
    if (this._isDefined) {
      window.addEventListener(name, handler, options)
    }
  }

  /**
   * Call method in parent object based on name.
   *
   * @param {String} method
   * @param {Array} params
   * @return any
   */
  call (method: string, params: any[]): any {
    if (this._isDefined && Object.prototype.hasOwnProperty.call(window, method)) {
      // @ts-ignore
      return window[method](...params)
    }

    return null
  }

  /**
   * Return IDocument service.
   *
   * @return IDocument
   */
  get document (): IDocument {
    return this._document
  }

  /**
   * Determine if applications runs in web browser.
   *
   * @return boolean
   */
  get isClient (): boolean {
    return this._isDefined
  }

  /**
   * Determine if global window object is defined.
   *
   * @return boolean
   */
  static get isDefined (): boolean {
    return typeof window !== 'undefined'
  }

  /**
   * Determine if applications runs at server.
   *
   * @return boolean
   */
  get isServer (): boolean {
    return !this._isDefined
  }

  /**
   * Removes listener from document object (if defined)
   *
   * @return void
   */
  public removeEventListener (name: any, handler: (this: Window, ev: any) => any, options?: any): void {
    if (this._isDefined) {
      window.removeEventListener(name, handler, options)
    }
  }
}
