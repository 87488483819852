import { AppModule, Module } from '@support/modules'

import { participationModuleStore } from './store'
import { participationsModuleRoutes } from './routes'

@AppModule({
  name: 'participations',
  routes: participationsModuleRoutes,
  state: participationModuleStore
})
export class ParticipationsModule extends Module {}
export default ParticipationsModule
