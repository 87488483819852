import { Adapter, MappingConfig, MappingTypes } from '@support/adapter'
import { Payload } from '@contract/http'
import { Participation } from '@module/participations/contracts/models'

import { fieldAdapter } from '@module/fields/adapters/field'
import { tagAdapter } from '@module/participations/adapters/tag'

class ParticipationAdapter extends Adapter {
  mapping: MappingConfig = {
    id: 'id',
    firstName: 'first_name',
    lastName: 'last_name',
    phone: 'phone',
    email: 'email',
    status: 'status',
    answers: 'answers',
    event: 'event',
    fields: {
      type: MappingTypes.Adapter,
      value: 'fields',
      adapter: fieldAdapter
    },
    tags: {
      type: MappingTypes.Adapter,
      value: 'tags',
      adapter: tagAdapter
    },
    createdAt: 'created_at',
    updatedAt: 'updated_at'
  }
  preserve: boolean = false
}
export const participationAdapter = new ParticipationAdapter()

export const participationPayloadMapper = (model: Participation): Payload => {
  return {
    email: model.email,
    phone: model.phone,
    status: model.status,
    first_name: model.firstName,
    last_name: model.lastName
  }
}

export default {
  participationAdapter: participationAdapter,
  participationMapper: participationPayloadMapper
}
