import { ContainerModule, interfaces } from 'inversify'
import toastr from 'toastr'

import { AuthorizationType, IResources, ResourcesType } from '@contract/resources'
import { IHttp, HttpType } from '@contract/http'
import { IModal, ModalType } from '@contract/modal'
import { IValidation, ValidationType } from '@contract/validation'
import { ToasterType } from '@contract/toaster'

import { axiosFactory } from '@service/http/axios-driver'
import { Datetime } from '@service/datetime'
import { handlers } from '@support/handlers'
import { Http } from '@service/http'
import { IDocument, DocumentService, DocumentType } from '@service/document'
import { ModalConnector } from '@service/modal-connector'
import { modalRegistry } from '@/config/modals'
import { IStreamBus, StreamBus, StreamBusType } from '@service/streambus'
import { IWindow, WindowService, WindowType } from '@service/window'
import { Resources } from '@service/resources'
import routing from '@/routing'
import { Validation } from '@service/validation'

import streamList from './streams'

const services = new ContainerModule((bind: interfaces.Bind) => {
  // Datetime
  bind(Datetime).toSelf()

  // Document
  bind<IDocument>(DocumentType).to(DocumentService).inSingletonScope()

  // Http
  bind<IHttp>(HttpType).toDynamicValue(() => {
    return new Http({ axios: axiosFactory }, 'axios', handlers)
  }).inSingletonScope()

  // ModalConnector
  bind<IModal>(ModalType).toDynamicValue(() => {
    return new ModalConnector(modalRegistry)
  }).inSingletonScope()

  // Resources
  bind<IResources>(ResourcesType).toDynamicValue((context: interfaces.Context) => {
    return new Resources(
      routing,
      context.container.get(HttpType),
      context.container.get(AuthorizationType),
      context.container.get(ValidationType)
    )
  }).inSingletonScope()

  // Streambus
  bind<IStreamBus>(StreamBusType).toDynamicValue(() => {
    return new StreamBus(streamList)
  }).inSingletonScope()

  // Toaster
  bind(ToasterType).toDynamicValue(() => {
    return toastr
  })

  // Validation
  bind<IValidation>(ValidationType).to(Validation).inSingletonScope()

  // Window
  bind<IWindow>(WindowType).toDynamicValue((context: interfaces.Context) => {
    return new WindowService(
      context.container.get(DocumentType)
    )
  }).inSingletonScope()
})

export default services
