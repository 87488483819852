
import { Component, Prop, Vue } from 'vue-property-decorator'

import EventBus from '@service/eventbus'

@Component({
  name: 'ViewContent'
})
export class ViewContent extends Vue {
  @Prop({ type: String, required: false, default: '' })
  public image!: string

  get backgroundImage (): object {
    return this.image.length ? { 'background-image': `url(${this.image})` } : {}
  }

  get showHeader (): boolean {
    return typeof this.$slots.header !== 'undefined'
  }
}

export default ViewContent
