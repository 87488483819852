
import { Component, Vue } from 'vue-property-decorator'

import Block from '@component/block.vue'
import { Breadcrumbs } from '@component/breadcrumbs.vue'
import { IResponse } from '@contract/http'
import { ViewContent } from '@view/partials/content.vue'

import { AccessableComponent } from '@module/auth/shared/access-helpers'

import { rolePayloadMapper } from '@module/users/adapters/role'
import { Role } from '@module/users/contracts/models'
import { RoleForm } from '@module/users/components/role-form.vue'

@Component({
  name: 'CreateRole',
  components: { Block, Breadcrumbs, RoleForm, ViewContent }
})
export class CreateRole extends AccessableComponent {
  protected scope: string = 'users'
  /**
   * Is saving in progress.
   */
  public saving: boolean = false;

  /**
   * Form save button text..
   */
  public saveBtnText: string = 'Dodaj role';

  /**
   * Form name.
   */
  public formName: string = 'createRoles';

  /**
   * Breadcrumbs data getter.
   *
   * @return {object[]}
   */
  get breadcrumbs (): object[] {
    return [
      { label: 'Dodaj nową rolę' }
    ]
  }

  /**
   * Role model getter.
   *
   * @return {Role}
   */
  get model (): Role {
    return this.$store.getters['users/getRoleSingle']
  }

  /**
   * Create form action.
   *
   * @return {Promise<IResponse>}
   */
  public async createRole (): Promise<void> {
    const response = await this.$store.dispatch('users/createRoleSingle', {
      params: {}, data: rolePayloadMapper(this.model)
    })

    if (!response.hasErrors()) {
      this.$router.push({ name: 'users.rolesShow', params: { id: response.data.data.id } })
    }
  }

  /**
   * Destroyed Vue hook.
   */
  public destroyed (): void {
    this.$store.dispatch('users/resetRoleSingleState')
  }

  /**
   * Submit form handler.
   */
  public onSubmitHandler (): void {
    this.saving = true

    this.createRole().finally(() => {
      this.saving = false
    })
  }
}

export default CreateRole
