import { VueConstructor } from 'vue'

export interface IModal {
  component: VueConstructor
  isOpened: boolean
  name: string|null
  payload: ModalPayload
  close (key?: string|null): void
  open (key: string, payload?: ModalPayload, size?: string): void
  openAsync (key: string, promise: Promise<any>, payload?: ModalPayload, size ?: string): void
  subscribe (callback: (open: ModalState) => any): void
}

export interface IModalComponent {
  payload: ModalPayload
}

export interface ModalPayload {
  [key: string]: any
}

export interface ModalRegistry {
  [key: string]: VueConstructor
}

export interface ModalState {
  component: string|null
  opened: boolean
  payload: ModalPayload
  size: string
}

export const ModalType = Symbol.for('IModal')
