
import { Component as VueComponent } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { DuplicatorItem, IDuplicatorItem } from './duplicator/item.vue'
import { DuplicatorDraggable } from './duplicator/item-draggable.vue'
import { DuplicatorWrapper } from './duplicator/wrapper.vue'
import { IToaster, ToasterType } from '@contract/toaster'
import { container } from '@/bootstrap/app'

@Component({
  name: 'UiDuplicator',
  components: { DuplicatorItem, DuplicatorWrapper, DuplicatorDraggable }
})

export class UiDuplicator extends Vue {
  @Prop({ required: false, default: null })
  protected addAction!: Function

  @Prop({ required: false, default: '' })
  protected addLabel!: string

  @Prop({ required: false, default: 'col-md-6' })
  protected columnClass!: string

  @Prop({ required: true })
  protected disabled!: boolean

  @Prop({ required: false, default: '' })
  protected protectedField!: string

  @Prop({ required: false, default: true })
  protected draggable!: boolean

  @Prop({ required: false, default: 'Element' })
  protected itemLabel!: string

  @Prop({ required: false, default: null })
  protected fileLabel!: any

  @Prop({ required: true })
  protected items!: any[]

  @Prop({ required: false, default: 'title' })
  protected labelField!: string

  @Prop({ required: false, default: true })
  protected multiple!: boolean

  @Prop({ required: false, default: false })
  protected readonly!: boolean

  @Prop({ required: true })
  protected single!: any

  @Prop({ required: false, default: false })
  protected toggled!: boolean

  get elements (): IDuplicatorItem[] {
    if (!Array.isArray(this.items)) {
      return []
    }

    return this.items
  }

  set elements (value) {
    this.$emit('update:items', value)
  }

  get itemComponent (): VueComponent {
    // @ts-ignore
    return this.draggable ? DuplicatorDraggable : DuplicatorItem
  }

  created (): void {
    let elements = this.items
    if (!Array.isArray(this.items)) {
      elements = []
    }

    this.$emit('update:items', elements.map(item => {
      return Object.assign(
        { ...this.single, visible: true },
        item
      )
    }))
  }

  addItem (): void {
    if (this.addAction !== null) {
      this.addAction()
    } else {
      this.elements.push(JSON.parse(JSON.stringify(this.single)))
      this.$emit('added')
      this.$emit('update:items', this.elements)
    }
  }

  getLabel (item: IDuplicatorItem) {
    if (
      this.labelField !== null &&
      Object.prototype.hasOwnProperty.call(item, this.labelField) &&
      item[this.labelField] !== null && item[this.labelField].length
    ) {
      return this.replaceHtml(item[this.labelField])
    }
    return this.itemLabel
  }

  removeItem (index: number): void {
    const item = this.items[index]

    if (this.protectedField && item[this.protectedField]) {
      const toaster: IToaster = container.get(ToasterType)
      toaster.error('Nie można usunąć.')
    } else {
      this.items.splice(index, 1)
      this.$emit('removed')
      this.$emit('update:items', this.elements)
    }
  }

  sorted () {
  }

  toggleItem (event: IDuplicatorItem): void {
    this.elements[event.index].visible = event.visible
    this.$emit('update:items', this.elements)
    this.$forceUpdate()
  }

  replaceHtml (html: string): string {
    return html.replace(/(<([^>]+)>)/ig, ' ')
  }
}

export default UiDuplicator
