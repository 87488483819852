import { ResourceResponseMeta } from '@contract/resources'

import { Event } from '@module/events/contracts/models'

export interface Invitation {
  id: number,
  key: string,
  email: string,
  locale: string,
  status: InvitationStatus,
  retryCount: number,
  tags: string[],
  event?: Event,
  participation?: Event,
  createdAt: string,
  updatedAt: string
}

export interface InvitationParsed {
  id: number,
  email: string,
  locale: string,
  eventId: number,
  tags: string
  errors: string[],
  imported: boolean
}

export interface InvitationToImport {
  id: string,
  email: string,
  locale: string,
  eventId: number,
  tags: string[],
  errors: string[],
  imported: boolean
}

export enum InvitationStatus {
  Sent = 'sent',
  Displayed = 'displayed',
  Registered = 'registered',
}

export { ResourceResponseMeta as Meta } from '@contract/resources'

export interface State {
  invitationsList: Invitation[],
  invitationSingle: Invitation,
  invitationsImported: Invitation[],
  meta: ResourceResponseMeta
}
