
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { ISelectOption } from '@contract/components'
import { UiInput } from '@component/form/input.vue'
import { UiSelect } from '@component/form/select.vue'
import { UiStateButton } from '@component/button/state.vue'

import { InvitationsLink } from '@module/invitations-links/contracts/models'

@Component({
  name: 'EventFormInvitationsLink',
  components: {
    UiInput,
    UiSelect,
    UiStateButton
  }
})
export class EventFormInvitationsLink extends Vue {
  /**
   * Can edit forms.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public isEditable!: boolean

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Is form saving.
   */
  @Prop({ type: Boolean, required: true })
  public saving!: boolean;

  /**
   * Form save button text.
   */
  @Prop({ type: String, required: false, default: 'Zapisz zmiany' })
  public saveBtnText!: string;

  /**
   * Is saving in progress?
   */
  public savingInProgress: boolean = this.saving;

  /**
   * Available locales getters.
   *
   * @return {ISelectOption[]}
   */
  public get availableLocales (): ISelectOption[] {
    return [
      {
        value: 'en',
        label: 'EN'
      },
      {
        value: 'pl',
        label: 'PL'
      }
    ]
  }

  /**
   * Logical select options.
   *
   * @return {ISelectOption[]}
   */
  public get logicalSelectOptions (): ISelectOption[] {
    return [
      { value: true, label: 'Tak' },
      { value: false, label: 'Nie' }
    ]
  }

  /**
   * Get event model.
   */
  public get model (): InvitationsLink {
    return this.$store.getters['invitations-links/getInvitationsLink']
  }

  /**
   * Submit form handler.
   */
  public submitHandler (): void {
    this.$emit('submit')
  }

  /**
   * Saving prop property watcher.
   *
   * @param {boolean} isSaving
   */
  @Watch('saving', { deep: true })
  public onSavingChange (isSaving: boolean): void {
    this.savingInProgress = isSaving
  }
}

export default EventFormInvitationsLink
