
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { AuthorizationType } from '@contract/resources'
import { IAuthorization } from '@module/auth/contracts/services'
import { ModelActions } from '@component/models/actions.vue'

import { InvitationParsed } from '../contracts/models'

@Component({
  name: 'ImportInvitationRow',
  components: { ModelActions }
})
export class ImportInvitationRow extends Vue {
  @Inject(AuthorizationType)
  protected _authorization!: IAuthorization

  protected scope: string = 'events'

  public canView (): boolean {
    return this._authorization.hasPermission(this.scope, 1)
  }
  /**
   * Event row index.
   *
   * @type {number}
   */
  @Prop({ required: true })
  public index!: number

  /**
   * Can edit row
   *
   * @type {number}
   */
  @Prop({ required: false })
  public canEdit!: Boolean

  /**
  * Event model.
  *
  * @type {number}
  */
  @Prop({ required: true })
  public model!: InvitationParsed

  get hasErrors (): boolean {
    return Boolean(this.model.errors.length)
  }
}

export default ImportInvitationRow
