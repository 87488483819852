
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { Subject, Subscription } from 'rxjs'

import { FilterFieldType } from '@contract/components'
import { FilterItem } from '@component/models/filter.vue'
import { Query } from '@contract/http'

// import { Role } from '../contracts/models'
import { userRoleLabel } from '../helpers/user-type-label'

@Component({
  name: 'RoleFilters',
  components: { FilterItem }
})
export class RoleFilters extends Vue {
  /**
   * Available filters.
   */
  @Prop({ type: Object, required: true })
  public filters!: Query

  /**
   * Filters form name.
   *
   * @type {string}
   */
  public formName: string = 'filterRoles'

  /**
   * RxJS subscription object.
   *
   * @type {Subscription}
   */
  protected subscription!: Subscription

  /**
   * RxJS subject object.
   *
   * @type {Subject<Query>}
   */
  protected querySubject!: Subject<Query>

  /**
   * Clear filters form.
   */
  public clear (): void {
    this.pushChanges({})
  }

  /**
   * Filters form fields.
   *
   * @return {object[]}
   */
  public get fields (): object[] {
    return [
      {
        type: FilterFieldType.Insert,
        name: 'id',
        default: '',
        placeholder: 'ID',
        size: 'col-12 mb-2 col-md-1 col-md-0'
      },
      {
        type: FilterFieldType.Insert,
        name: 'q',
        default: '',
        placeholder: 'Szukaj po nazwie...',
        size: 'col-12 mb-2 col-md-3 col-md-0'
      }
    ]
  }

  /**
   * Determinate is possible to create filters form.
   *
   * @return {boolean}
   */
  public get hasFilters (): boolean {
    return Object.keys(this.filters)
      .filter((k: string) => (k !== 'page' && k !== 'per_page')).length > 0
  }

  /**
   * Push changes to RxJS subject.
   *
   * @param {Query} changes
   */
  public pushChanges (changes: Query): void {
    for (const [key, value] of Object.entries(changes)) {
      if (value === null || !value.length) {
        delete changes[key]
      }
    }

    changes.page = '1'
    changes.per_page = '12'

    this.querySubject.next(changes)
  }

  /**
   * Filters property watcher.
   *
   * @param value
   */
  @Watch('filters', { deep: true })
  public onFiltersChange (value: Query): void {
    this.querySubject.next(value)
  }

  /**
   * Created Vue hook.
   */
  public created (): void {
    this.querySubject = new Subject()
    this.subscription = this.querySubject.pipe(
      debounceTime(600),
      distinctUntilChanged()
    ).subscribe((query: Query) => this.$emit('filter', query))
  }

  /**
   * Destroyed Vue hook.
   */
  public destroyed (): void {
    this.subscription.unsubscribe()
  }
}

export default RoleFilters
