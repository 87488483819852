
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { AuthorizationType } from '@contract/resources'
import { IAuthorization } from '@module/auth/contracts/services'
import { ISelectOption } from '@contract/components'
import { Tags } from '@component/tags.vue'
import { UiInput } from '@component/form/input.vue'
import { UiSelect } from '@component/form/select.vue'
import { UiStateButton } from '@component/button/state.vue'
import { UiText } from '@component/form/text.vue'

import { Field } from '@module/fields/contracts/models'
import { InvitationsLink } from '../contracts/models'

@Component({
  name: 'InvitationsLinkForm',
  components: {
    Tags,
    UiInput,
    UiSelect,
    UiText,
    UiStateButton
  }
})
export class InvitationsLinkForm extends Vue {
  @Inject(AuthorizationType)
  protected _authorization!: IAuthorization

  protected scope: string = 'events'

  public canView (): boolean {
    return this._authorization.hasPermission(this.scope, 1)
  }

  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * New tags.
   */
  @Prop({ type: Array, required: true })
  public newTags!: string[];

  /**
   * Is form saving.
   */
  @Prop({ type: Boolean, required: true })
  public saving!: boolean;

  /**
   * Form save button text.
   */
  @Prop({ type: String, required: false, default: 'Zapisz zmiany' })
  public saveBtnText!: string;

  /**
   * Is saving in progress?
   */
  public savingInProgress: boolean = this.saving;

  /**
   * Available locales getters.
   *
   * @return {ISelectOption[]}
   */
  public get availableLocales (): ISelectOption[] {
    return [
      {
        value: 'en',
        label: 'EN'
      },
      {
        value: 'pl',
        label: 'PL'
      }
    ]
  }

  /**
   * Logical select options.
   *
   * @return {ISelectOption[]}
   */
  public get logicalSelectOptions (): ISelectOption[] {
    return [
      { value: true, label: 'Tak' },
      { value: false, label: 'Nie' }
    ]
  }

  /**
   * Get event model.
   */
  public get model (): InvitationsLink {
    return this.$store.getters['invitations-links/getInvitationsLink']
  }

  /**
   * Add content to modal
   */
  public addModelContent (): void {
    this.model.content = {
      pl: '',
      en: ''
    }
  }

  /**
   * Delete content from model
   */
  public deleteModelContent (): void {
    this.model.content = null
  }

  /**
   * Send mail
   */
  public sendMail (): void {
    this.$emit('send')
  }

  /**
   * Submit form handler.
   */
  public submitHandler (): void {
    this.$emit('submit')
  }

  /**
   * Update tags handler
   */
  public onUpdateTags (): void {
    this.$emit('updateTags')
  }

  /**
   * Saving prop property watcher.
   *
   * @param {boolean} isSaving
   */
  @Watch('saving', { deep: true })
  public onSavingChange (isSaving: boolean): void {
    this.savingInProgress = isSaving
  }
}

export default InvitationsLinkForm
