
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

import { ISelectOption } from '@contract/components'
import { UiDuplicator } from '@component/duplicator.vue'
import { UiInput } from '@component/form/input.vue'
import { UiSelect } from '@component/form/select.vue'

import { Field, FieldDictionaryItem, FieldType } from './../../contracts/models'

@Component({
  name: 'FieldDictionarySettings',
  components: {
    UiInput,
    UiSelect,
    UiDuplicator
  }
})
export class FieldDictionarySettings extends Vue {
  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string

  /**
   * Logical select options.
   */
  @Prop({ required: true })
  public logicalSelectOptions!: ISelectOption[]

  /**
   * Field model.
   */
  @Prop({ required: true })
  public model!: Field

  public singleItem: FieldDictionaryItem = {
    label: {
      en: '',
      pl: ''
    },
    value: ''
  }

  public get isBooleanFieldType (): boolean {
    return this.model.type === FieldType.Boolean
  }

  public get fieldTypes () : ISelectOption[] {
    return [
      { label: 'Rozwijana lista', value: false },
      { label: 'Pole wyboru', value: true }
    ]
  }
}

export default FieldDictionarySettings
