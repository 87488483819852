
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Subject, Subscription } from 'rxjs'

import { ISelectOption } from '@contract/components'
import { UiInput } from '@component/form/input.vue'
import { UiSelect } from '@component/form/select.vue'
import { UiStateButton } from '@component/button/state.vue'
import { UiText } from '@component/form/text.vue'

import { Invitation, InvitationStatus } from '@module/invitations/contracts/models'
import invitationStatusLabel from '@module/invitations/helpers/invitation-status-label'

@Component({
  name: 'InvitationForm',
  components: {
    UiInput,
    UiSelect,
    UiText,
    UiStateButton
  }
})
export class InvitationForm extends Vue {
  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Is form saving.
   */
  @Prop({ type: Boolean, required: true })
  public saving!: boolean;

  /**
   * Form save button text.
   */
  @Prop({ type: String, required: false, default: 'Zapisz zmiany' })
  public saveBtnText!: string;

  /**
   * Is saving in progress?
   */
  public savingInProgress: boolean = this.saving;

  /**
   * Search for tag label
   */
  public newTag: string = ''

  /**
   * Show form anwsers
   */
  public showForm: boolean = false

  /**
   * RxJS subscription object.
   *
   * @type {Subscription}
   */
  protected subscription!: Subscription

  /**
   * RxJS subject object.
   *
   * @type {Subject<Query>}
   */
  protected querySubject!: Subject<string>

  /**
   * Available types getters.
   *
   * @return {ISelectOption[]}
   */
  public get availableStatuses (): ISelectOption[] {
    return [
      {
        value: InvitationStatus.Sent,
        label: invitationStatusLabel(InvitationStatus.Sent)
      },
      {
        value: InvitationStatus.Displayed,
        label: invitationStatusLabel(InvitationStatus.Displayed)
      },
      {
        value: InvitationStatus.Registered,
        label: invitationStatusLabel(InvitationStatus.Registered)
      }
    ]
  }

  /**
   * Available locales getters.
   *
   * @return {ISelectOption[]}
   */
  public get availableLocales (): ISelectOption[] {
    return [
      {
        value: 'en',
        label: 'EN'
      },
      {
        value: 'pl',
        label: 'PL'
      }
    ]
  }

  /**
   * Get event model.
   */
  public get model (): Invitation {
    return Object.assign(this.$store.getters['invitations/getSingleInvitation'], {})
  }

  /**
   * Add new tag
   *
   * @return void
   */
  public addTag () {
    if (!this.checkIfTagAlreadyExists(this.newTag)) {
      this.model.tags.push(this.newTag)
    }

    this.newTag = ''
  }

  public get inNotRegistered (): boolean {
    return this.model.status !== InvitationStatus.Registered
  }

  /**
   * Check if tag existing tag is already in use
   *
   * @return boolean
   */
  public checkIfTagAlreadyExists (tag: string): boolean {
    const tags = this.model.tags.map((t) => t.toLowerCase())
    const search = tag.toLowerCase()

    return tags.includes(search)
  }

  /**
   * Submit form handler.
   */
  public submitHandler (): void {
    this.$emit('submit')
  }

  /**
   * Remove new tag
   *
   * @return void
   */
  public removeTag (index: number): void {
    this.model.tags.splice(index, 1)
  }

  /**
   * Saving prop property watcher.
   *
   * @param {boolean} isSaving
   */
  @Watch('saving', { deep: true })
  public onSavingChange (isSaving: boolean): void {
    this.savingInProgress = isSaving
  }
}

export default InvitationForm
