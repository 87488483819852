import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'
import { Subscription } from 'rxjs'

import { IValidation, ValidationType } from '@contract/validation'

@Component
export class AbstractInput extends Vue {
  @Prop({ required: false, default: false })
  public disabled!: boolean;

  @Prop({ required: false })
  public form!: string;

  @Prop({ required: false })
  public helper!: string;

  @Prop({ required: false, default: '' })
  public label!: string;

  @Prop({ required: true })
  public name!: string;

  @Prop({ required: false, default: false })
  public readonly!: boolean;

  @Prop({ required: false, default: false })
  public required!: boolean;

  @PropSync('model', { required: false, type: [Array, Object, String, Number, Boolean, Date] })
  public value!: any;

  @Inject(ValidationType)
  private _validation!: IValidation

  private _vSubscription!: Subscription

  public errors: string[] = [];

  get displayLabel () {
    return this.label.length
  }

  get hasErrors () {
    return this.errors.length
  }

  created () {
    this._vSubscription = this._validation.onErrors(this.form, this.name, (errors: string[]) => {
      this.errors = errors
    })
  }

  destroyed () {
    this._vSubscription.unsubscribe()
  }

  @Watch('model')
  onModelChange () {
    this.errors = []
  }
}

export default AbstractInput
