import { ActionContext } from 'vuex'

import { container } from '@/bootstrap/app'
import { IResponse, Payload } from '@contract/http'
import { IResources, ResourcesType } from '@contract/resources'

import { fieldAdapter } from './adapters/field'
import { Field, Meta, State } from './contracts/models'

const basicQuery = { per_page: 12, page: 1 }

function initialState () {
  return {
    label: {
      en: '',
      pl: ''
    },
    placeholder: {
      en: '',
      pl: ''
    },
    default: {
      en: '',
      pl: ''
    },
    name: '',
    basic: false,
    type: null,
    taggable: false,
    requirements: {
      min: null,
      max: null,
      multiple: false,
      isChoiceField: false,
      pattern: null,
      type: null,
      role: null
    },
    dictionary: []
  }
}

export const fieldModuleStore = {
  namespaced: true,
  state: {
    fields: [],
    meta: {
      total: 0
    },
    fieldSingle: initialState()
  },
  actions: {
    async fetchFieldsList ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('fields', 'list',
        {},
        payload
      )

      if (!response.hasErrors()) {
        commit('setFieldsList', response.data.data)
        commit('setMeta', response.data.meta)
      }

      return response
    },
    async fetchSingleField ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('fields', 'show',
        payload.params,
        payload.data
      )

      if (!response.hasErrors()) {
        commit('setSingleField', response.data.data)
      }

      return response
    },
    async updateSingleField ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('fields', 'update',
        payload.params,
        payload.data
      )

      if (!response.hasErrors()) {
        commit('setSingleField', response.data.data)
      }

      return response
    },
    async createSingleField ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('fields', 'create',
        payload.params,
        payload.data
      )

      if (!response.hasErrors()) {
        commit('setSingleField', response.data.data)
      }

      return response
    },
    async resetSingleFieldState ({ commit }: ActionContext<State, any>) {
      commit('setSingleField', initialState())
    },
    async deleteSingleField ({ dispatch }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('fields', 'delete',
        payload.params,
        payload.data
      )

      if (!response.hasErrors()) {
        await dispatch('fetchFieldsList', {
          ...Object.assign(
            basicQuery,
            payload.query)
        })
      }

      return response
    }
  },
  getters: {
    getMeta: (state: State): Meta => {
      return state.meta
    },
    getFields: (state: State): Field[] => {
      return state.fields
    },
    getSingleField: (state: State): Field => {
      return state.fieldSingle
    }
  },
  mutations: {
    setFieldsList (state: State, collection: Field[]) {
      state.fields = fieldAdapter.collection<Field>(collection)
    },
    setMeta: (state: State, meta: Meta) => {
      state.meta = meta
    },
    setSingleField: (state: State, event: Field) => {
      state.fieldSingle = fieldAdapter.model<Field>(event)
    }
  }
}

export default fieldModuleStore
