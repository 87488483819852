import { Event, EventMediaPurpose } from '../contracts/models'
// import { IFile } from '@contract/components'

/**
 * Compose event request API payload.
 * Creates payload from model data.
 *
 * @param {File} file
 * @param {EventMediaPurpose} purpose
 * @param {string} locale
 * @return {FormData}
 */

export const composeEventRequestPayload = (file: File, purpose: EventMediaPurpose, locale: string): FormData => {
  const payload = new FormData()

  payload.append('purpose', purpose)
  payload.append('file', file)
  payload.append('locale', locale)

  return payload
}

export default composeEventRequestPayload
