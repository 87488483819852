<template>
  <div class="d-flex justify-content-center" v-if="pages > 1">
    <nav aria-label="Page navigation">
      <ul class="pagination mb-0">
        <li class="page-item" v-if="!isFirst()">
          <button type="button" class="page-link"
                  @click="setActual(actual - 1)">
            <i class="fas fa-chevron-left"></i>
          </button>
        </li>
        <li class="page-item"
            v-for="p in pages"
            :key="'btn-'+p"
            :class="{ active : isCurrent(p), 'd-none': shouldBeHidden(p) }"
            @click="setActual(p)">
          <button type="button" class="page-link">{{ p }}</button>
        </li>
        <li class="page-item" v-if="!isLast()">
          <button type="button" class="page-link"
                  @click="setActual(actual + 1)">
            <i class="fas fa-chevron-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    actual: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: false,
      default: 10
    },
    total: {
      type: Number,
      required: true
    }
  },
  computed: {
    pages () {
      return Math.ceil(this.total / this.perPage)
    }
  },
  methods: {
    isCurrent (page) {
      return page === this.actual
    },
    shouldBeHidden (page) {
      let right = (this.actual < 5) ? 10 - this.actual : 5
      let left = 10 - right
      return ((page > (this.actual + right)) || (page < (this.actual - left))) && page !== 1 && page !== this.pages
    },
    isFirst () {
      return this.actual === 1
    },
    isLast () {
      return this.actual === this.pages
    },
    setActual (page) {
      this.$emit('update:actual', page)
    }
  }
}
</script>
