
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { AuthorizationType } from '@contract/resources'
import { IAuthorization } from '@module/auth/contracts/services'
import { ModelActions } from '@component/models/actions.vue'

import { InvitationsLink } from '../contracts/models'

@Component({
  name: 'InvitationsLinkRow',
  components: { ModelActions }
})
export class InvitationsLinkRow extends Vue {
  /**
   * Event row index.
   *
   * @type {number}
   */
  @Prop({ required: true })
  public index!: number

  /**
   * Can edit row
   *
   * @type {number}
   */
  @Prop({ required: false })
  public canEdit!: Boolean

  /**
  * Event model.
  *
  * @type {number}
  */
  @Prop({ required: true })
  public model!: InvitationsLink

  /**
   * Delete invitations link action.
   */
  public deleteSingleItem (): void {
    this.$store.dispatch('invitations-links/deleteSingleInvitationsLink', {
      id: this.model.id
    })
  }

  /**
   * Send invitations link by mail
   */
  public send (): void {
    this.$store.dispatch('invitations-links/sendInvitationsLink', {
      id: this.model.id
    })
  }

  /**
   * Toggle activity of invitations link action.
   */
  public toggleActivityOfSingleItem (): void {
    this.$store.dispatch('invitations-links/toggleInvitationsLinkStatus', {
      id: this.model.id,
      params: {
        active: !this.model.active
      }
    })
  }
}

export default InvitationsLinkRow
