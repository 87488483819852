
import { Component, Vue, Prop } from 'vue-property-decorator'

import { Field, FieldType } from '@module/fields/contracts/models'
import { fieldTypeLabel } from '@module/fields/helpers/field-type-label'

@Component({
  name: 'FieldsDetails'
})

export class FieldsDetails extends Vue {
  @Prop({ required: true })
  public field!: Field

  /**
   * Get event status label.
   */
  public fieldTypeLabel (value: FieldType): string {
    return fieldTypeLabel(value)
  }
}
export default FieldsDetails
