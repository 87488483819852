import { IModule } from '@support/modules'

import { AuthModule } from '@module/auth/module'
import { RootModule } from '@module/root/module'
import { EventsModule } from '@module/events/module'
import { FieldsModule } from '@module/fields/module'
import { InvitationsLinksModule } from '@module/invitations-links/module'
import { InvitationsModule } from '@module/invitations/module'
import { ParticipationsModule } from '@module/participations/module'
import { ActionsModule } from '@module/actions/module'
import { UsersModule } from '@module/users/module'

export const registry: IModule[] = [
  new AuthModule(),
  new RootModule(),
  new EventsModule(),
  new FieldsModule(),
  new InvitationsLinksModule(),
  new InvitationsModule(),
  new ParticipationsModule(),
  new ActionsModule(),
  new UsersModule()
]
