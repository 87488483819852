
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import webAppUrl from '@/shared/extentions/web-app-url'
import { AuthorizationType } from '@contract/resources'
import { IAuthorization } from '@module/auth/contracts/services'
import { IToaster, ToasterType } from '@contract/toaster'
import { ModelActions } from '@component/models/actions.vue'

import { Invitation, InvitationStatus } from '../contracts/models'
import { invitationStatusLabel } from '@module/invitations/helpers/invitation-status-label'

@Component({
  name: 'InvitationRow',
  components: { ModelActions }
})
export class InvitationRow extends Vue {
  @Inject(AuthorizationType)
  protected _authorization!: IAuthorization

  @Inject(ToasterType)
  protected _toaster!: IToaster

  protected scope: string = 'events'

  public canView (): boolean {
    return this._authorization.hasPermission(this.scope, 1)
  }
  /**
   * Event row index.
   *
   * @type {number}
   */
  @Prop({ required: true })
  public index!: number

  /**
   * Can edit row
   *
   * @type {number}
   */
  @Prop({ required: false })
  public canEdit!: Boolean

  /**
  * Event model.
  *
  * @type {number}
  */
  @Prop({ required: true })
  public model!: Invitation

  /**
   * Resources per page.
   */
  public static perPage: number = 12

  public copyInvitationLink (): void {
    const invitationLink = webAppUrl(`/i/${this.model.key}/`)
    navigator.clipboard.writeText(invitationLink)
      .then(() => {
        this._toaster.success('Skopiowano link zaproszenia do schowka.')
      })
      .catch(() => {
        this._toaster.error('Wystapił błąd.')
      })
  }

  /**
   * Get invitation status label.
   */
  public invitationStatusLabel (value: InvitationStatus): string {
    return invitationStatusLabel(value)
  }

  /**
   * Delete event action.
   */
  public deleteSingleItem (): void {
    this.$store.dispatch('invitations/deleteSingleInvitation', {
      id: this.model.id
    })
  }

  public resentInvitation (): void {
    this.$store.dispatch('invitations/resentInvitationEmail', {
      params: {
        id: this.model.id
      }
    }).then(() => {
      this.model.retryCount++
    })
  }

  public get canResentInvitation (): boolean {
    return this.model.status !== InvitationStatus.Registered
  }
}

export default InvitationRow
