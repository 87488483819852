
import { Component, Vue } from 'vue-property-decorator'
import { Route } from 'vue-router/types/router'

import { AccessableComponent } from '@module/auth/shared/access-helpers'
import Block from '@component/block.vue'
import { Breadcrumbs } from '@component/breadcrumbs.vue'
import { InvitationsLinkForm } from '../components/invitations-link-form.vue'
import { IResponse } from '@contract/http'
import { ViewContent } from '@view/partials/content.vue'

import { Tag } from '@module/participations/contracts/models'
import { InvitationsLink } from '../contracts/models'
import { invitationsLinkPayloadMapper } from '../adapters/invitationsLink'

@Component({
  name: 'EditInvitationsLink',
  components: { Block, Breadcrumbs, InvitationsLinkForm, ViewContent },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch('invitations-links/fetchSingleInvitationsLink',
        { id: vm.$route.params.id }
      )
    })
  }
})
export class EditInvitationsLink extends AccessableComponent {
  protected scope: string = 'events'
  /**
   * Search for tag label
   */
  public newTags: string[] = []

  /**
   * Is saving in progress.
   */
  public saving: boolean = false;

  /**
   * Form save button text..
   */
  public saveBtnText: string = 'Zapisz link';

  /**
   * Form name.
   */
  public formName: string = 'createFields';

  /**
   * Breadcrumbs data getter.
   *
   * @return {object[]}
   */
  get breadcrumbs (): object[] {
    return [
      { label: `${this.model.slug}` }
    ]
  }

  /**
   * Participation model getter.
   *
   * @return {Participation}
   */
  get model (): InvitationsLink {
    return this.$store.getters['invitations-links/getInvitationsLink']
  }

  /**
   * Destroyed Vue hook.
   */
  public destroyed (): void {
    this.$store.dispatch('invitations-links/resetSingleInvitationsLinkState')
  }

  /**
   * Update field action.
   *
   * @return {Promise<IResponse>}
   */
  public updateField (): Promise<IResponse> {
    return this.$store.dispatch('invitations-links/updateSingleInvitationsLink', {
      params: { id: this.$route.params.id },
      data: invitationsLinkPayloadMapper(this.model)
    })
  }

  /**
   * Update tags action.
   *
   * @return {Promise<IResponse>}
   */
  public updateTags (): Promise<IResponse> {
    return this.$store.dispatch('invitations-links/updateInvitationsLinkTags', {
      params: { id: this.$route.params.id },
      data: {
        ids: this.model.tags.map((tag: Tag) => tag.id),
        labels: this.newTags
      }
    })
  }

  /**
   * Send mail handler
   */
  public onSend (): void {
    this.saving = true

    this.sendMail().finally(() => {
      this.saving = false
    })
  }

  /**
   * Submit form handler.
   */
  public onSubmit (): void {
    this.saving = true

    this.updateField().finally(() => {
      this.saving = false
    })
  }

  /**
   * Submit form handler.
   */
  public onUpdateTags (): void {
    this.saving = true

    this.updateTags().finally(() => {
      this.saving = false
      this.newTags = []
    })
  }

  public sendMail (): Promise<IResponse> {
    return this.$store.dispatch('invitations-links/sendInvitationsLink', {
      id: this.model.id
    })
  }
}

export default EditInvitationsLink
