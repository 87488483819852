import { ActionContext } from 'vuex'

import { container } from '@/bootstrap/app'
import { IResponse, Payload } from '@contract/http'
import { IResources, ResourcesType } from '@contract/resources'

import { userAdapter } from './adapters/user'
import { User, Meta, State, Role } from './contracts/models'
import { roleAdapter } from '@module/users/adapters/role'

export const usersModuleStore = {
  namespaced: true,
  state: {
    users: [],
    roles: [],
    meta: {
      total: 0
    },
    userSingle: {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      roles: null
    },
    roleSingle: {
      name: '',
      permissions: [
        { 'scope': 'events', 'level': null },
        { 'scope': 'files', 'level': null },
        { 'scope': 'participations', 'level': null },
        { 'scope': 'users', 'level': null } ]
    }
  },
  actions: {
    async fetchRolesList ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('roles', 'list',
        {},
        payload
      )

      if (!response.hasErrors()) {
        commit('setRolesList', response.data.data)
        commit('setMeta', response.data.meta)
      }

      return response
    },
    async fetchUsersList ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('users', 'list',
        {},
        payload
      )

      if (!response.hasErrors()) {
        commit('setUsersList', response.data.data)
        commit('setMeta', response.data.meta)
      }

      return response
    },
    async fetchRoleSingle ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('roles', 'show',
        payload.params,
        payload.data
      )

      if (!response.hasErrors()) {
        commit('setRoleSingle', response.data.data)
      }

      return response
    },
    async fetchUserSingle ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('users', 'show',
        payload.params,
        payload.data
      )

      if (!response.hasErrors()) {
        commit('setUserSingle', response.data.data)
      }

      return response
    },
    async updateRoleSingle ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('roles', 'update',
        payload.params,
        payload.data
      )

      if (!response.hasErrors()) {
        commit('setRoleSingle', response.data.data)
      }

      return response
    },
    async updateUserSingle ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('users', 'update',
        payload.params,
        payload.data
      )

      if (!response.hasErrors()) {
        commit('setUserSingle', response.data.data)
      }

      return response
    },
    async createRoleSingle ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('roles', 'create',
        payload.params,
        payload.data
      )

      if (!response.hasErrors()) {
        commit('setRoleSingle', response.data.data)
      }

      return response
    },
    async createUserSingle ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('users', 'create',
        payload.params,
        payload.data
      )

      if (!response.hasErrors()) {
        commit('setUserSingle', response.data.data)
      }

      return response
    },
    async resetRoleSingleState ({ commit }: ActionContext<State, any>) {
      commit('setRoleSingle', {
        name: '',
        permissions: [
          { 'scope': 'events', 'level': null },
          { 'scope': 'files', 'level': null },
          { 'scope': 'participations', 'level': null },
          { 'scope': 'users', 'level': null } ]
      })
    },
    async resetUserSingleState ({ commit }: ActionContext<State, any>) {
      commit('clearUserSingle', {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        roles: null
      })
    },
    async deleteRoleSingle ({ dispatch }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('roles', 'delete',
        payload.params,
        payload.data
      )

      if (!response.hasErrors()) {
        await dispatch('fetchUsersList', { data: payload.query })
      }

      return response
    },
    async deleteUserSingle ({ dispatch }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call('users', 'delete',
        payload.params,
        payload.data
      )

      if (!response.hasErrors()) {
        await dispatch('fetchUsersList', { data: payload.query })
      }

      return response
    }
  },
  getters: {
    getMeta: (state: State): Meta => {
      return state.meta
    },
    getUsers: (state: State): User[] => {
      return state.users
    },
    getRoles: (state: State): Role[] => {
      return state.roles
    },
    getUserSingle: (state: State): User => {
      return state.userSingle
    },
    getRoleSingle: (state: State): Role => {
      return state.roleSingle
    }
  },
  mutations: {
    setMeta: (state: State, meta: Meta) => {
      state.meta = meta
    },
    setUsersList (state: State, collection: User[]) {
      state.users = userAdapter.collection<User>(collection)
    },
    setRolesList (state: State, collection: Role[]) {
      state.roles = roleAdapter.collection<Role>(collection)
    },
    setUserSingle: (state: State, user: User) => {
      state.userSingle = userAdapter.model<User>(user)
    },
    clearUserSingle: (state: State, user: User) => {
      state.userSingle = user
    },
    setRoleSingle: (state: State, role: Role) => {
      state.roleSingle = roleAdapter.model<Role>(role)
    }
  }
}

export default usersModuleStore
