
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Component as VueComponent } from 'vue'

import { UiDate } from '@component/form/date.vue'
import { ISelectOption } from '@contract/components'
import { UiInput } from '@component/form/input.vue'
import { UiSelect } from '@component/form/select.vue'
import { UiStateButton } from '@component/button/state.vue'
import { UiText } from '@component/form/text.vue'

import { Role, User, UserRole } from '../contracts/models'
import { userRoleLabel } from '../helpers/user-type-label'

@Component({
  name: 'UserForm',
  components: {
    UiInput,
    UiSelect,
    UiText,
    UiDate,
    UiStateButton
  }
})
export class UserForm extends Vue {
  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string

  /**
   * Form name.
   */
  @Prop({ type: Object, required: true })
  public model!: User

  /**
   * Is form saving.
   */
  @Prop({ type: Boolean, required: true })
  public saving!: boolean

  /**
   * Form save button text.
   */
  @Prop({ type: String, required: false, default: 'Zapisz zmiany' })
  public saveBtnText!: string

  /**
   * Is saving in progress?
   */
  public savingInProgress: boolean = this.saving

  /**
   * Available types getters.
   *
   * @return {ISelectOption[]}
   */
  public get availableRoles (): ISelectOption[] {
    if (this.roles && this.roles.length) {
      return this.roles.map((role: Role) => {
        return {
          value: role.id,
          label: role.name
        }
      })
    }
    return []
  }

  /**
   * Event model getter.
   *
   * @return {Event}
   */
  get roles (): Role[] {
    return this.$store.getters['users/getRoles']
  }

  public created (): void {
    this.$store.dispatch('users/fetchRolesList')
  }

  /**
   * Submit form handler.
   */
  public submitHandler (): void {
    this.$emit('submit')
  }

  /**
   * Saving prop property watcher.
   *
   * @param {boolean} isSaving
   */
  @Watch('saving', { deep: true })
  public onSavingChange (isSaving: boolean): void {
    this.savingInProgress = isSaving
  }
}

export default UserForm
