
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ModelActions } from '@component/models/actions.vue'

import { Role, UserRole } from '../contracts/models'
import { userRoleLabel } from '../helpers/user-type-label'

@Component({
  name: 'RoleRow',
  components: { ModelActions }
})
export class RoleRow extends Vue {
  /**
   * Event row index.
   *
   * @type {number}
   */
  @Prop({ required: true })
  public index!: number

  /**
   * Can edit row
   *
   * @type {number}
   */
  @Prop({ required: false })
  public canEdit!: Boolean

  /**
  * Event model.
  *
  * @type {number}
  */
  @Prop({ required: true })
  public model!: Role

  /**
   * Delete event action.
   */
  public deleteSingleItem (): void {
    this.$store.dispatch('users/deleteRoleSingle', {
      params: {
        roleId: this.model.id
      },
      query: {
        ...this.$route.query
      }
    })
  }

  /**
   * Get event status label.
   */
  public userRoleLabel (value: UserRole): string {
    return userRoleLabel(value)
  }
}

export default RoleRow
