var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h4',[_vm._v("Szczegóły szablonu")]),_c('hr'),_c('table',{staticClass:"mb-4"},[_c('tr',[_vm._m(0),_c('td',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.field.name))])]),_c('tr',[_vm._m(1),_c('td',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.field.id))])]),_c('tr',[_vm._m(2),_c('td',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.fieldTypeLabel(_vm.field.type)))])]),_c('tr',[_vm._m(3),_c('td',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.field.label.pl))])]),_c('tr',[_vm._m(4),_c('td',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.field.placeholder.pl || 'brak'))])]),_c('tr',[_vm._m(5),_c('td',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.field.basic ? 'Tak' : 'Nie'))])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('strong',{staticClass:"pr-2"},[_vm._v("Nazwa:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('strong',{staticClass:"pr-2"},[_vm._v("Id:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('strong',{staticClass:"pr-2"},[_vm._v("Typ:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('strong',{staticClass:"pr-2"},[_vm._v("Etykieta:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('strong',{staticClass:"pr-2"},[_vm._v("Placeholder:")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('strong',{staticClass:"pr-2"},[_vm._v("Podstawowe:")])])
}]

export { render, staticRenderFns }