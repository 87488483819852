export type AdapterConstructor = () => IAdapter

export interface IAdapter {
  collection <T>(toMap: any[]): T[]
  model <T>(toMap: any): T
}

export type MappingConfig = {
  [key: string]: MappingInstruction|string
}

interface MappingDriver {
  value: MappingFunction|string,
  adapter?: IAdapter,
  require?: boolean,
  default?: any
}

type MappingFunction = (toMap: any) => any

export interface MappingInstruction extends MappingDriver {
  type: MappingTypes
}

export enum MappingTypes {
  Adapter = 'adapter',
  Direct = 'direct',
  Function = 'function'
}

interface Payload {
  [key: string]: any
}

/**
 * Adapter to connect api response with data model required by frontend
 *
 * @author  Kuba Fogel <kuba.fogel@movecloser.pl>
 * @version 1.0.0
 */

export class Adapter {
  mapping: MappingConfig = {}
  preserve: boolean = true

  /**
   * Convert single Model object
   *
   * @param {Object} toMap
   * @return Object
   */
  public model <T> (toMap: any): T {
    return this.map<T>(toMap)
  }

  /**
   * Convert array of Model objects
   *
   * @param {Array} toMap
   * @return Array
   */
  public collection <T> (toMap: any[]): T[] {
    return toMap.map((item: any) => {
      return this.map<T>(item)
    })
  }

  /**
   * Provides mapping based on {type} given in config.
   * Operates on single objects (collections are fired in loop)
   * Is called internally by methods: Model and Collection
   *
   * @param {Array} toMap
   * @return Array
   */
  protected map <T> (item: any): T {
    let mappedItem: Payload = {}
    if (this.preserve) {
      mappedItem = { ...item }
    }
    for (const [key, instruction] of Object.entries(this.mapping)) {
      if (typeof instruction === 'string') {
        mappedItem[key] = Adapter.performDirect(item, { value: instruction })

        // if (this.preserve) {
        //   delete mappedItem[instruction]
        // }
        continue
      }

      if (typeof instruction === 'object' && instruction !== null) {
        switch (instruction.type) {
          case MappingTypes.Adapter:
            mappedItem[key] = Adapter.performAdapter(item, instruction)
            continue
          case MappingTypes.Direct:
            mappedItem[key] = Adapter.performDirect(item, instruction)
            if (this.preserve) { delete mappedItem[instruction.value as string] }
            continue
          case MappingTypes.Function:
            mappedItem[key] = Adapter.performFunction(item, instruction)
            continue
        }
      }

      throw new Error('Invalid mapping instruction type given.')
    }

    return mappedItem as T
  }

  /**
   * ADAPTER strategy
   * mapping using nested adapter
   *
   */
  private static performAdapter (item: any, instruction: MappingDriver): any {
    if (!instruction.hasOwnProperty('adapter')) {
      throw new Error('Invalid instruction. Missing adapter instance.')
    }

    const adapter: IAdapter = instruction.adapter as IAdapter
    const fromKey = instruction.value as string
    const isThrowing = instruction.hasOwnProperty('require') ? instruction.require : false

    if (!Object.prototype.hasOwnProperty.call(item, fromKey)) {
      if (isThrowing) {
        throw new Error(
          `Missing key [${fromKey}] in given object.`
        )
      }
    } else {
      if (Array.isArray(item[fromKey])) {
        //
        return adapter.collection(item[fromKey])
      } else if (typeof item[fromKey] === 'object' && item[fromKey] !== null) {
        //
        return adapter.model(item[fromKey])
      }
    }

    return instruction.hasOwnProperty('default') ? instruction.default : null
  }

  /**
   * DIRECT strategy
   * mapping fields from one object to another
   *
   */
  private static performDirect (item: any, instruction: MappingDriver): any {
    const fromKey = instruction.value as string
    const isThrowing = instruction.hasOwnProperty('require') ? instruction.require : false

    if (!Object.prototype.hasOwnProperty.call(item, fromKey)) {
      if (isThrowing) {
        throw new Error(
          `Missing key [${fromKey}] in given object.`
        )
      }

      return instruction.hasOwnProperty('default') ? instruction.default : null
    }

    return item[fromKey]
  }

  /**
   * FUNCTION strategy
   * mapping using custom mapping function
   *
   */
  private static performFunction (item: any, instruction: MappingDriver): any {
    if (typeof instruction.value !== 'function') {
      throw new Error('Invalid instruction. Value is not a function.')
    }

    const callbackFunction: MappingFunction = instruction.value as MappingFunction
    return callbackFunction(item)
  }
}

export default Adapter
