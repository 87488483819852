
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Inject } from '@plugin/inversify'

import { AuthorizationType } from '@contract/resources'
import EventBus from '@service/eventbus'
import { IAuthorization } from '@module/auth/contracts/services'
import { SidebarItem } from '@contract/sidebar'

@Component({
  name: 'NavItem'
})
export default class NavItem extends Vue {
  @Prop({ type: Object, required: true })
  public item!: SidebarItem

  @Inject(AuthorizationType)
  private _authorization!: IAuthorization
  private open: boolean = false

  get canDisplay (): boolean {
    return this.item.permission.length === 0 || this.validateScopes(this.item.permission)
  }

  get current (): boolean {
    if (this.route === null) {
      return false
    }

    return this.$route.path.includes(this.route)
  }

  get filteredChildren (): SidebarItem[] {
    if (this.item.children) {
      return this.item.children.filter(child => {
        return child.permission.length === 0 || this.validateScopes(child.permission)
      })
    }

    return []
  }

  get hasChildren (): boolean {
    if (!this.item.hasOwnProperty('children') || typeof this.item.children === 'undefined') {
      return false
    }

    return this.item.children.length > 0
  }

  get hasIcon (): boolean {
    return this.item.hasOwnProperty('icon')
  }

  get isOpen (): boolean {
    if (this.current) {
      return true
    }

    return this.open
  }

  set isOpen (value: boolean) {
    if (!this.current) {
      this.open = value
    }
  }

  get route (): string|null {
    return this.item.hasOwnProperty('route') ? this.item.route : null
  }

  @Watch('current')
  onCurrentChange (): void {
    this.isOpen = false
  }

  mounted () {
    EventBus.$on('sidebard-item-click', (label: string) => {
      if (label !== this.item.label) {
        this.isOpen = false
      }
    })
  }

  close () {
    this.isOpen = false
  }

  toggleClose () {
    this.$emit('close')
    EventBus.$emit('interface:sidebar.toggle')
  }

  toggleOpen () {
    this.isOpen = !this.isOpen
  }

  validateScopes (scopes: string[]) {
    if (scopes.length) {
      for (const scope of scopes) {
        if (this._authorization.hasPermission(scope, 1)) {
          return true
        }
      }
    }

    return false
  }
}
