import { Adapter, MappingConfig } from '@support/adapter'
import { Payload } from '@contract/http'
import { Field } from '../contracts/models'

class FieldAdapter extends Adapter {
  mapping: MappingConfig = {
    basic: 'is_basic',
    autocomplete: 'autocomplete',
    dictionary: 'dictionary',
    default: 'default',
    comment: 'comment',
    taggable: 'taggable',
    key: 'key',
    id: 'id',
    name: 'name',
    label: 'label',
    placeholder: 'placeholder',
    requirements: 'requirements',
    required: 'required',
    type: 'type',
    createdAt: 'created_at',
    updatedAt: 'updated_at'
  }
  preserve: boolean = false
}
export const fieldAdapter = new FieldAdapter()

export const fieldPayloadMapper = (model: Field): Payload => {
  return {
    is_basic: model.basic,
    autocomplete: model.autocomplete,
    taggable: model.taggable,
    dictionary: model.dictionary || [],
    default: model.default,
    label: model.label,
    name: model.name,
    placeholder: model.placeholder,
    requirements: model.requirements || {},
    type: model.type
  }
}

export const eventsFieldsPayloadMapper = (field: Field, order: number): Payload => {
  return {
    field: field.id,
    key: field.key,
    default: field.default,
    comment: field.comment,
    required: field.hasOwnProperty('required') ? field.required : 0,
    order: order
  }
}

export default {
  fieldAdapter: fieldAdapter,
  fieldMapper: fieldPayloadMapper,
  eventFieldsMapper: eventsFieldsPayloadMapper
}
