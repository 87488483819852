import { InvitationsLinksList } from '@module/invitations-links/views/list.vue'
import { EditInvitationsLink } from '@module/invitations-links/views/edit.vue'

export const invitationsLinksModuleRoutes = [
  {
    path: 'invitations-links',
    name: 'list',
    component: InvitationsLinksList,
    meta: { requiresAuth: true, permission: 'events', level: 1 }
  },
  {
    path: 'invitations-links/:id',
    name: 'edit',
    component: EditInvitationsLink,
    meta: { requiresAuth: true, permission: 'events', level: 1 }
  }
]

export default invitationsLinksModuleRoutes
