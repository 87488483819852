
import { Component, Prop } from 'vue-property-decorator'

import { ISelectOption } from '@contract/components'
import { AbstractInput } from '../abstract/input'

export { ISelectOption } from '@contract/components'

@Component({
  name: 'UiSelect'
})
export class UiSelect extends AbstractInput {
  @Prop({ required: false, default: '' })
  public classInput!: string

  @Prop({ required: false, default: false })
  public multiple!: boolean

  @Prop({ required: true })
  public options!: ISelectOption[]

  @Prop({ required: false, default: '' })
  public placeholder!: string

  getChoosenOption (): string|number {
    for (const option of this.options) {
      if (option.value === this.value) {
        return option.label
      }
    }

    return ''
  }
}

export default UiSelect
