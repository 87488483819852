
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ISelectOption } from '@contract/components'
import { UiInput } from '@component/form/input.vue'
import { UiSelect } from '@component/form/select.vue'

import { Field } from './../../contracts/models'

@Component({
  name: 'FieldTypeSettings',
  components: {
    UiInput,
    UiSelect
  }
})
export class FieldTypeSettings extends Vue {
  /**
   * Field available types.
   */
  @Prop({ required: true })
  public availableTypesSelectOptions!: ISelectOption[]

  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string

  /**
   * Field available types.
   */
  @Prop({ required: true })
  public logicalSelectOptions!: ISelectOption[]

  /**
   * Field model.
   */
  @Prop({ required: true })
  public model!: Field
}

export default FieldTypeSettings
