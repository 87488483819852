import { ActionContext } from 'vuex'

import { container } from '@/bootstrap/app'
import { IResponse, Payload } from '@contract/http'
import { IResources, ResourcesType } from '@contract/resources'

import { Action, Meta, State } from './contracts/models'
import { actionsAdapter } from './adapters/actions'

export const actionsModuleStore = {
  namespaced: true,
  state: {
    action: {
      handleFields: {},
      triggerFields: {}
    },
    actions: [],
    meta: {
      total: 0
    }
  },
  actions: {
    async createAction ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'actions',
        'create',
        { eventId: payload.params.id },
        payload.data
      )

      return response
    },
    async deleteAction ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'actions',
        'delete',
        { actionId: payload.id },
        {}
      )

      if (!response.hasErrors()) {
        commit('removeAction', payload.id)
      }

      return response
    },
    async fetchAction ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'actions',
        'show',
        { actionId: payload.id },
        {}
      )

      if (!response.hasErrors()) {
        commit('setAction', response.data.data)
      }

      return response
    },
    async fetchActionsList ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'actions',
        'list',
        {},
        payload
      )

      if (response.isSuccessful()) {
        commit('setActionsList', response.data.data)
        commit('setMeta', response.data.meta)
      }

      return response
    },
    async resetActionState ({ commit }: ActionContext<State, any>) {
      commit('setAction', {
        handleFields: {},
        triggerFields: {}
      })
    },
    async updateAction ({ commit }: ActionContext<State, any>, payload: Payload = {}) {
      const resources: IResources = container.get(ResourcesType)
      const response: IResponse = await resources.call(
        'actions',
        'update',
        { actionId: payload.params.id },
        payload.data
      )

      if (!response.hasErrors()) {
        commit('setAction', response.data.data)
      }

      return response
    }
  },
  getters: {
    getMeta: (state: State): Meta => {
      return state.meta
    },
    getAction: (state: State): Action => {
      return state.action
    },
    getActionsList: (state: State): Action[] => {
      return state.actions
    }
  },
  mutations: {
    removeAction: (state: State, id: number) => {
      const flatActionsList = state.actions.map((action: Action) => action.id)
      const index = flatActionsList.indexOf(id)
      if (index !== -1) {
        state.actions.splice(index, 1)
      }
    },
    setAction: (state: State, action: Action) => {
      state.action = actionsAdapter.model<Action>(action)
    },
    setActionsList (state: State, collection: Action[]) {
      state.actions = actionsAdapter.collection<Action>(collection)
    },
    setMeta: (state: State, meta: Meta) => {
      state.meta = meta
    }
  }
}

export default actionsModuleStore
