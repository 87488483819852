<template lang="html">
  <td class="table-actions text-center">
    <Dropdown class="dropdown-sm" buttonClass="btn-link" :name="`model-${index}`" :alignRight="true">
      <template #title><i class="fa fa-fw fa-ellipsis-v"></i></template>
      <slot></slot>
    </Dropdown>
  </td>
</template>

<script>
import Dropdown from '@component/dropdown'

export const ModelActions = {
  name: 'ModelActions',
  components: { Dropdown },
  props: {
    index: {
      type: Number,
      required: true
    }
  }
}

export default ModelActions
</script>
