import { InvitationStatus } from '@module/invitations/contracts/models'

export const invitationStatusLabel = (status: InvitationStatus): string => {
  switch (status) {
    case InvitationStatus.Displayed: return 'Wyświetlone'
    case InvitationStatus.Registered: return 'Zarejestrowany'
    case InvitationStatus.Sent: return 'Wysłane'
  }
}

export default invitationStatusLabel
