import { EventStatus } from '@module/events/contracts/models'

export const eventStatusLabel = (status: EventStatus): string => {
  switch (status) {
    case EventStatus.Archive: return 'Archiwum'
    case EventStatus.Draft: return 'Szkic'
    case EventStatus.Public: return 'Publiczny'
  }
}

export default eventStatusLabel
