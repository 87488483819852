import { Datetime } from '@service/datetime'
import { Event } from '../contracts/models'

import { Adapter, MappingConfig, MappingTypes } from '@support/adapter'
import { participationAdapter } from '@module/participations/adapters/participation'
import { fieldAdapter } from '@module/fields/adapters/field'
import { fileAdapter } from '@module/media/adapters/file'
import { Payload } from '@contract/http'

class EventAdapter extends Adapter {
  /**
   * Adapter mapping types.
   *
   * @type {MappingConfig}
   */
  mapping: MappingConfig = {
    id: 'id',
    title: 'title',
    slug: 'slug',
    startsAt: {
      type: MappingTypes.Function,
      value: (event: any) => {
        const datetime = new Datetime()
        return datetime.parseToDefaultFormat(event.starts_at)
      }
    },
    endsAt: {
      type: MappingTypes.Function,
      value: (event: any) => {
        const datetime = new Datetime()
        return datetime.parseToDefaultFormat(event.ends_at)
      }
    },
    registrationEndsAt: {
      type: MappingTypes.Function,
      value: (event: any) => {
        const datetime = new Datetime()
        return event.registration_ends_at ? datetime.parseToDefaultFormat(event.registration_ends_at) : null
      }
    },
    enableSharing: 'enable_sharing',
    requiresTicket: 'requires_ticket',
    address: 'address',
    content: 'content',
    contact: 'contact',
    links: {
      type: MappingTypes.Function,
      value: (event: any) => {
        return event.links || {}
      }
    },
    status: 'status',
    forCompanies: 'for_companies',
    isPhoneRequired: 'is_phone_required',
    isVirtual: 'is_virtual',
    private: 'is_private',
    priority: 'priority',
    fields: {
      type: MappingTypes.Adapter,
      value: 'fields',
      adapter: fieldAdapter
    },
    cover: 'cover',
    emailCover: 'email_cover',
    files: {
      type: MappingTypes.Adapter,
      value: 'files',
      adapter: fileAdapter
    },
    maxParticipants: 'max_participants',
    participations: {
      type: MappingTypes.Adapter,
      value: 'participations',
      adapter: participationAdapter
    },
    participantsCount: 'participants_count',
    partners: {
      type: MappingTypes.Function,
      value: (event: any) => {
        return event.partners || []
      }
    },
    additionalInfo: {
      type: MappingTypes.Function,
      value: (event: any) => {
        return event.additional_info || { pl: [], en: [] }
      }
    },
    acceptParticipations: 'accept_participants',
    createdAt: 'created_at',
    updatedAt: 'updated_at'
  }
  preserve: boolean = false
}

export const eventPayloadMapper = (model: Event): Payload => {
  return {
    title: model.title,
    slug: model.slug,
    status: model.status,
    content: model.content,
    starts_at: model.startsAt,
    ends_at: model.endsAt,
    registration_ends_at: model.registrationEndsAt,
    enable_sharing: model.enableSharing,
    requires_ticket: model.requiresTicket,
    address: model.address,
    max_participants: model.maxParticipants,
    for_companies: model.forCompanies,
    is_phone_required: model.isPhoneRequired,
    is_virtual: model.isVirtual,
    private: model.private,
    accept_participations: model.acceptParticipations,
    priority: model.priority,
    contact: model.contact,
    partners: model.partners,
    additional_info: model.additionalInfo,
    links: model.links,
    cover: model.cover,
    email_cover: model.emailCover
  }
}

export const eventAdapter = new EventAdapter()
