
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ModelActions } from '@component/models/actions.vue'

import { Action, ActionHandle, ActionTrigger } from '../contracts/models'
import { actionHandleLabel } from '../helpers/action-handle-label'
import { actionTriggerLabel } from '../helpers/action-trigger-label'

@Component({
  name: 'ActionRow',
  components: { ModelActions }
})
export class ActionRow extends Vue {
  /**
   * Action row index.
   *
   * @type {number}
   */
  @Prop({ required: true })
  public index!: number

  /**
   * Is editable row
   *
   * @type {number}
   */
  @Prop({ required: false, default: false })
  public canEdit!: Boolean

  /**
  * Action model.
  *
  * @type {number}
  */
  @Prop({ required: true })
  public model!: Action

  /**
   * Get action handle label.
   */
  public actionHandleLabel (value: ActionHandle): string {
    return actionHandleLabel(value)
  }

  /**
   * Get action trigger label.
   */
  public actionTriggerLabel (value: ActionTrigger): string {
    return actionTriggerLabel(value)
  }

  /**
   * Delete action.
   */
  public deleteSingleItem (): void {
    this.$store.dispatch('actions/deleteAction', {
      id: this.model.id
    })
  }
}

export default ActionRow
