import { ResourceResponseMeta } from '@contract/resources'
import { Event } from '@module/events/contracts/models'
import { Field } from '@module/fields/contracts/models'

export interface State {
  meta: ResourceResponseMeta
  participationsList: Participation[]
  participationSingle: Participation,
  tags: Tag[]
}

export interface Participation extends Taggable {
  answers: Answer[]
  createdAt: string
  email: string
  event: Event|null
  id: number
  fields: Field[]
  firstName: string
  lastName: string
  phone: string
  status: ParticipationStatus
  updatedAt: string
}
export interface Answer {
  field: number,
  answer: string
}

export interface ParticipationAnswer {
  answer: string|number|boolean,
  question?: any
}

export enum ParticipationStatus {
  'Confirmed' = 'confirmed',
  'Pending' = 'pending',
  'Visited' = 'visited'
}

export enum ParticipationTagFilterTypes {
  'And' = 'and',
  'Or' = 'or'
}

export interface ParticipationsFilterPayload {
  type: ParticipationTagFilterTypes,
  tags: string[]
}

export interface Tag {
  createdAt: string
  id: number
  label: string
  updatedAt: string
}

export interface Taggable {
  tags: Tag[]
}

export { ResourceResponseMeta as Meta } from '@contract/resources'
