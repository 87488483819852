
import { Component, Vue } from 'vue-property-decorator'
import { Route } from 'vue-router/types/router'

import { AccessableComponent } from '@module/auth/shared/access-helpers'
import Block from '@component/block.vue'
import { Breadcrumbs } from '@component/breadcrumbs.vue'
import { ActionUpdateForm } from '../components/action-update-form.vue'
import { IResponse } from '@contract/http'
import { ViewContent } from '@view/partials/content.vue'

import { Action } from '../contracts/models'
import { actionPayloadMapper } from '../adapters/actions'

@Component({
  name: 'EditAction',
  components: { ActionUpdateForm, Block, Breadcrumbs, ViewContent },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch('actions/fetchAction',
        { id: vm.$route.params.id }
      )
    })
  }
})
export class EditAction extends AccessableComponent {
  protected scope: string = 'events'

  /**
   * Is saving in progress.
   */
  public saving: boolean = false;

  /**
   * Form name.
   */
  public formName: string = 'editAction';

  /**
   * Breadcrumbs data getter.
   *
   * @return {object[]}
   */
  get breadcrumbs (): object[] {
    return [
      { label: `${this.model.title}` }
    ]
  }

  /**
   * Action model getter.
   *
   * @return {Action}
   */
  get model (): Action {
    return this.$store.getters['actions/getAction']
  }

  /**
   * Destroyed Vue hook.
   */
  public destroyed (): void {
    this.$store.dispatch('actions/resetActionState')
  }

  /**
   * Submit form handler.
   */
  public onSubmit (): void {
    this.saving = true

    this.updateAction().finally(() => {
      this.saving = false
    })
  }

  /**
   * Update action.
   *
   * @return {Promise<IResponse>}
   */
  public updateAction (): Promise<IResponse> {
    return this.$store.dispatch('actions/updateAction', {
      params: { id: this.$route.params.id },
      data: actionPayloadMapper(this.model)
    })
  }
}

export default EditAction
