
import { Component, Watch, Vue } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { Breadcrumbs } from '@component/breadcrumbs.vue'
import { ModelsList } from '@component/models/list.vue'
import { IResponse, Query } from '@contract/http'
import { ViewContent } from '@view/partials/content.vue'

import { AccessableComponent } from '@module/auth/shared/access-helpers'

import { Meta, User } from '../../contracts/models'
import { UserRow } from '../../components/user-row.vue'
import { UserFilters } from '../../components/user-filters.vue'

@Component({
  name: 'UsersList',
  components: { ViewContent, Breadcrumbs, ModelsList, UserFilters },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch('users/fetchUsersList',
        { per_page: UsersList.perPage, page: 1, ...vm.$route.query })
    })
  }
})
export class UsersList extends AccessableComponent {
  protected scope: string = 'users'
  /**
   * Is list loading.
   *
   * @type {boolean}
   */
  public loading: boolean = false

  /**
   * Resources per page.
   */
  public static perPage: number = 12

  /**
   * List row component.
   *
   * @type {VueConstructor}
   */
  public rowComponent: VueConstructor = UserRow

  /**
   * Get breadcrumbs details.
   *
   * @return {object[]}
   */
  public get breadcrumbs (): object[] {
    return [
      { label: `Lista (${this.usersTotal})` }
    ]
  }

  /**
   * Users models collection getter.
   *
   * @return {User[]}
   */
  public get users (): User[] {
    return this.$store.getters['users/getUsers']
  }

  /**
   * Get users total count.
   *
   * @return {number}
   */
  public get usersTotal (): number {
    if (!this.meta || !this.meta.hasOwnProperty('total') || typeof this.meta.total === 'undefined') {
      return 0
    }

    return this.meta.total
  }

  /**
   * Users collection meta data.
   *
   * @return {Meta}
   */
  public get meta (): Meta {
    return this.$store.getters['users/getMeta']
  }

  /**
   * Get current page.
   *
   * @return {number}
   */
  public get page (): number {
    if (this.$route.query.hasOwnProperty('page') && this.$route.query.page != null) {
      return parseInt(this.$route.query.page as string, 10)
    }

    return this.meta.current_page || 1
  }

  /**
   * Load users collection.
   *
   * @param {object} params
   */
  public loadCollection (params: object): Promise<IResponse> {
    return this.$store.dispatch('users/fetchUsersList', {
      page: 1,
      per_page: UsersList.perPage,
      sort: 'id',
      ...params
    })
  }

  /**
   * Set page and change it.
   *
   * @param {number} page
   */
  public setPage (page: number): void {
    if (this.page !== page) {
      this.$router.push({ name: 'users.list', query: { ...this.queryParams, page: `${page}` } })
    }
  }

  /**
   * Set query and re-load collection.
   *
   * @param {Query} query
   */
  public setQuery (query: Query): void {
    if (this.queryParams !== query) {
      this.$router.push({ name: 'users.list', query: query })
    }
  }

  /**
   * Get query params.
   *
   * @return {Query}
   */
  public get queryParams (): Query {
    return this.$route.query
  }

  /**
   * Watch page change and re-load collection.
   *
   * @param {Query} query
   */
  @Watch('queryParams', { deep: true })
  public onPageChange (query: Query): void {
    this.loading = true

    this.loadCollection(query).then((response: IResponse) => {
      if (response) {
        this.loading = false
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        this.$router.back()
      }
    })
  }
}

export default UsersList
