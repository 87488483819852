<template lang="html">
  <nav class="section-title" aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link :to="rootRoute" v-if="rootRoute">
          <i :class="`fa fa-fw fa-lg fa-${icon}`"></i> {{ rootLabel }}
        </router-link>
        <span v-else>
          <i :class="`fa fa-fw fa-lg fa-${icon}`"></i> {{ rootLabel }}
        </span>
      </li>

      <template v-for="(item, index) in items">
        <li class="breadcrumb-item" :class="{ active: index === last }" :key="`bread-${index}`">
          <router-link :to="item.route"
            v-if="isRoute(item)">
            {{ item.label }}
          </router-link>
          <span v-else>
            {{ item.label }}
          </span>
        </li>
      </template>
    </ol>
  </nav>
</template>

<script>
export const Breadcrumbs = {
  name: 'Breadcrumbs',
  props: {
    icon: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    rootLabel: {
      type: String,
      required: true
    },
    rootRoute: {
      type: Object,
      required: false,
      default () {
        return null
      }
    }
  },
  computed: {
    last () {
      return this.items.length - 1
    }
  },
  methods: {
    isRoute (item) {
      return Object.prototype.hasOwnProperty.call(item, 'route')
    }
  }
}

export default Breadcrumbs
</script>
