import { ContainerModule, interfaces } from 'inversify'
import { Route } from 'vue-router/types/router'

import { container } from '@/bootstrap/app'
import { AppModule, Module, ProviderType } from '@support/modules'
import { AuthorizationType } from '@contract/resources'
import { AuthRepository, IAuthorization } from './contracts/services'
import { Authorization } from './services/authorization'
import { MeRepository, MeRepositoryType } from './repositories/me'

import ForgetPage from './views/forget-page.vue'
import LoginPage from './views/login-page.vue'
import ResetPage from './views/reset-page.vue'

import moduleState from './store'

export const routes = [
  {
    path: '/forgot',
    name: 'forgot',
    component: ForgetPage,
    meta: { requiresAuth: false },
    beforeEnter (to: Route, from: Route, next: Function): void {
      const auth: IAuthorization = container.get(AuthorizationType)
      auth.check() ? next({ name: 'app' }) : next()
    }
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetPage,
    meta: { requiresAuth: false },
    beforeEnter (to: Route, from: Route, next: Function): void {
      const auth: IAuthorization = container.get(AuthorizationType)
      auth.check() ? next({ name: 'app' }) : next()
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { requiresAuth: false },
    beforeEnter (to: Route, from: Route, next: Function): void {
      const auth: IAuthorization = container.get(AuthorizationType)
      auth.check() ? next({ name: 'app' }) : next()
    }
  }
]

@AppModule({
  name: 'auth',
  providers: {
    module: new ContainerModule((bind: interfaces.Bind) => {
      bind<AuthRepository>(MeRepositoryType).to(MeRepository).inSingletonScope()

      bind<IAuthorization>(AuthorizationType).toDynamicValue((context: interfaces.Context) => {
        return new Authorization(
          { tokenName: 'hypemetoken' },
          context.container.get(MeRepositoryType)
        )
      }).inSingletonScope()
    }),
    type: ProviderType.Sync
  },
  routes: [],
  state: moduleState
})
export class AuthModule extends Module {}
