
import { Component, Vue } from 'vue-property-decorator'

import FormInput from '@component/form/input.vue'

@Component<any>({
  name: 'ResetPage',
  components: { FormInput }
})
export class ResetPage extends Vue {
  public form = {
    email: '',
    password: '',
    password_confirmation: '',
    token: ''
  }
  public formName: string = 'resetPassword'
  public sending: boolean = false

  mounted () {
    if (!this.$route.query.token) {
      this.$router.push('/')
    }

    this.form.token = this.$route.query.token as string

    if (this.$route.query.email) {
      this.form.email = this.$route.query.email as string
    }
  }

  handleForm (): void {
    this.sending = true

    this.$store.dispatch('auth/resetPassword', this.form).then(status => {
      if (status) {
        this.$router.push('/')
      }

      this.sending = false
    })
  }
}
export default ResetPage
