/**
 * Get web application URl with path.
 *
 * @param {string} path
 */
export default function (path: string): string {
  let webPath = path

  webPath = webPath.trim()
  if (webPath.charAt(0) === '/') { // if slash on path start, remove it
    webPath = webPath.slice(1)
  }

  return `${process.env.VUE_APP_WEB_BASE}${webPath}`
}
