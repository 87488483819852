import 'reflect-metadata'
import Vue from 'vue'
import Inversify from '@plugin/inversify'

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import { router, store, container } from '@/bootstrap/app'
import Root from '@view/root.vue'

Vue.use(Inversify)
Vue.config.productionTip = false

if (
  process.env.VUE_APP_ENV !== 'local' &&
  process.env.VUE_APP_SENTRY &&
  process.env.VUE_APP_SENTRY.length > 0
) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY,
    environment: process.env.VUE_APP_ENV,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
        logErrors: true
      })
    ]
  })
}

new Vue({
  router,
  store,
  container,
  render: h => h(Root)
}).$mount('#app')
