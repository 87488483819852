
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ISelectOption } from '@contract/components'
import { UiStateButton } from '@component/button/state.vue'

import { Field, FieldType } from '../contracts/models'
import { fieldTypeLabel } from '../helpers/field-type-label'
import { FieldAdditionalSettings } from './form/field-additional-settings.vue'
import { FieldBasicSettings } from './form/field-basic-settings.vue'
import { FieldDictionarySettings } from './form/field-dictionary-settings.vue'
import { FieldTypeSettings } from './form/field-type-settings.vue'

  @Component({
    name: 'FieldForm',
    components: {
      FieldAdditionalSettings,
      FieldBasicSettings,
      FieldDictionarySettings,
      FieldTypeSettings,
      UiStateButton
    }
  })
export class FieldForm extends Vue {
  /**
   * Can edit props.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canEdit!: boolean

  /**
   * Form name.
   */
  @Prop({ type: String, required: true })
  public formName!: string;

  /**
   * Is form saving.
   */
  @Prop({ type: Boolean, required: true })
  public saving!: boolean;

  /**
   * Form save button text.
   */
  @Prop({ type: String, required: false, default: 'Zapisz zmiany' })
  public saveBtnText!: string;

  /**
   * Is saving in progress?
   */
  public savingInProgress: boolean = this.saving;

  /**
   * Available types getters.
   *
   * @return {ISelectOption[]}
   */
  public get availableTypesSelectOptions (): ISelectOption[] {
    return [
      { value: FieldType.Boolean, label: fieldTypeLabel(FieldType.Boolean) },
      { value: FieldType.Number, label: fieldTypeLabel(FieldType.Number) },
      { value: FieldType.String, label: fieldTypeLabel(FieldType.String) }
    ]
  }

  /**
   * Can display field basic settings.
   *
   * @return {boolean}
   */
  public get isChosenFieldType (): boolean {
    if ('type' in this.model && typeof this.model.type === 'string') {
      return Boolean(this.model.type.length)
    }

    return false
  }

  public get isBooleanFieldType (): boolean {
    return this.model.type === FieldType.Boolean
  }

  public get logicalSelectOptions (): ISelectOption[] {
    return [
      { value: true, label: 'Tak' },
      { value: false, label: 'Nie' }
    ]
  }

  public get canSave () : boolean {
    return ![
      Boolean(this.model.type),
      Boolean(this.model.name),
      Boolean(this.model.label.pl),
      Boolean(this.model.label.en)
    ].includes(false)
  }

  /**
   * Get event model.
   */
  public get model (): Field {
    return this.$store.getters['fields/getSingleField']
  }

  /**
   * Submit form handler.
   */
  public submitHandler (): void {
    this.$emit('submit')
  }

  /**
   * Saving prop property watcher.
   *
   * @param {boolean} isSaving
   */
  @Watch('saving', { deep: true })
  public onSavingChange (isSaving: boolean): void {
    this.savingInProgress = isSaving
  }
}

export default FieldForm
