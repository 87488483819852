
import { Component, Prop, Vue } from 'vue-property-decorator'

import Block from '@component/block.vue'
import NavTabs from '@component/nav/nav-tabs.vue'

@Component({
  name: 'BlockTabs',
  components: { Block, NavTabs }
})
export class BlockTabs extends Vue {
  @Prop({ required: false, type: String, default: null })
  public contentClass!: boolean

  @Prop({ type: String, required: false, default: 'block-header-tabs' })
  public headerClass!: string

  @Prop({ type: String, required: true })
  public id!: string

  @Prop({ type: Boolean, required: false, default: false })
  public isDisabled!: boolean

  @Prop({ type: Array, required: true })
  public items!: any[]

  @Prop({ type: Boolean, required: false, default: false })
  public showStrategy!: boolean

  @Prop({ type: String, required: false })
  public wrapperClass!: string

  public active: number = 0

  public get disabled () {
    return this.isDisabled
  }

  public onActiveChange (value: number) {
    this.active = value
  }
}

export default BlockTabs

// protected changeActive(newActive: number) {
//   const item = this.items[newActive]
//
//   if (Object.prototype.hasOwnProperty.call(item, 'guard') && !item.guard()) {
//     EventBus.$emit(`tabs:${this.id}.blocked`, newActive)
//   } else {
//     this.active = newActive
//   }
// }

